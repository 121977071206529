<template>
  <v-container fluid class="ma-0 px-4">
    <info-masivos-component
      iconAvatar="mdi-tag-multiple"
      titleCard="Creación Masiva de Productos"
      @onDownloadExcelBtn="exportarProducto"
      @onUploadExcelBtn="cargarExcel"
      :loading="loading"
      :responseApi="responseApi"
    />

    <!-- FIN DE CARDS INFOMASIVOSCOMPONENT-->

    <v-row v-if="errors.length > 0">
      <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
        <div v-if="errors.length > 0" class="">
          <strong>{{ err }}</strong>
        </div>
      </v-col>
    </v-row>

    <div v-if="tabla_visible == true">
      <base-material-card
        icon="mdi-file-alert-outline"
        title="Errores"
        color="info"
      >
        <v-container>
          <v-data-table
            no-data-text="No existen registros en tu cuenta"
            :headers="headers"
            :items="errors"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ item.fila }}</td>
                  <td>{{ item.columna }}</td>
                  <td>{{ item.error }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-container>
      </base-material-card>
    </div>

    <div id="grid"></div>
  </v-container>
</template>

<script>
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
export default {
  name: "relacionar-masivamente",
  title: "Admin creación masiva producto - Onestock",
  data: () => ({
    loading: true,
    excel: null,
    user: null,
    reverse: false,
    tabla_visible: false,
    cards: [],
    responseApi: {},
    errors: [
      {
        errores: [],
        filas: [],
        columnas: [],
      },
    ],
    headers: [
      {
        text: "Fila",
        class: "text-center",
      },
      {
        text: "Columna",
        class: "text-center",
      },
      {
        text: "Error",
        class: "text-center",
      },
    ],
  }),
  methods: {
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportMasivaProduct(this.$store.state.token)
        .then((res) => {
          window.open(res.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 3000,
          });
          EventBus.$emit("cargando", false);
        });
    },

    cargarExcel(excel) {
      this.$data.excel = excel;
      EventBus.$emit("cargando", true);
      this.$data.errors = [];
      let formData = new FormData();
      formData.append("archivo", this.$data.excel);
      API.crearMasivaProduct(formData, this.$store.state.token)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.excel = "";
          EventBus.$emit("cargando", false);

          const { mensaje, estado, respuesta } = res;
          this.responseApi.mensaje = mensaje;
          this.responseApi.ok = estado == 200;
          if (respuesta) {
            const allErrors = [];
            for (const item in respuesta) {
              const errores = respuesta[item].errores;
              errores && allErrors.push(...errores);
            }
            this.responseApi.errores = allErrors;
          }

          this.$data.excel = "";
          this.responseApi = res;
        })

        .catch((err) => {
          // let error = err.response.data;
          // var texto_error = "El archivo contiene errores!";

          // if (error) {
          //     for (const element in error.datos) {
          //     this.$data.errors.push({
          //         error: error.datos[element].errores["0"],
          //         fila: error.datos[element].fila,
          //         columna: error.datos[element].columna,
          //     });
          //     }
          //     Swal.fire({
          //     icon: "error",
          //     title: texto_error,
          //     showConfirmButton: false,
          //     timer: 2000,
          //     });

          //     if (error.mensaje.extension == undefined) {
          //     this.$data.tabla_visible = true;
          //     EventBus.$emit("cargando", false);
          //     } else {
          //     this.$data.tabla_visible = false;
          //     EventBus.$emit("cargando", false);
          //     }
          // }

          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    "info-masivos-component": InfoMasivosComponent,
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
