<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card class="mx-4 mt-12">
      <!-- title + icon encabezado -->
      <head-card-component
        :iconAvatar="`mdi-truck-check`"
        :titleCard="`Editar Proveedor`"
      />
      <!-- TABS -->
      <v-tabs color="primary" right hide-slider>
        <v-tab>Editar Proveedor</v-tab>
        <v-tab>Productos</v-tab>
        <v-tab class="mr-16">Clientes</v-tab>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 1 TABS -->
            <edit-proveedor-form-component :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 2 TABS -->
            <editar-productos-proveedores :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 3 TABS -->
            <editar-clientes-proveedores :id="id" />
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <btn-volver class="my-12"/>
  </v-container>
</template>

<script>
import EditarProductosProveedores from "@/views/dashboard/pages/admin/subUser/EditarProductosProveedores";
import EditarClientesProveedores from "@/views/dashboard/pages/admin/subUser/EditarClientesProveedores";
import EditProveedorFormComponent from "./subUser/EditProveedorFormComponent.vue";
import HeadCardComponent from "@/components/HeadCardComponent.vue";
import BtnVolver from '@/components/BtnVolver.vue';
// import { EventBus } from "@/main.js";

export default {
  components: {
    EditProveedorFormComponent,
    EditarClientesProveedores,
    EditarProductosProveedores,
    HeadCardComponent,
    BtnVolver
  },
  name: "component-name",
  props: ["id"],

  data: function () {
    return {
      
    };
  },
  // computed: {},
  //methods: {}
  // watch: {},
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // created(){
  //   EventBus.$emit("cargando", true);
  // }
  // -- End Lifecycle Methods
};
</script>

<style lang="scss">
.list-style {
  list-style: none;
}
</style>
