<template>
  <v-container fluis class="ma-0 px-0">
    <info-masivos-component
      iconAvatar="mdi-truck-check"
      titleCard="Creación Masiva de Proveedores"
      @onDownloadExcelBtn="exportarProveedores"
      @onUploadExcelBtn="cargarExcel"
      :loading="loading"
      :responseApi="responseApi"
    />
    <!-- FIN DE CARDS INFOMASIVOSCOMPONENTS-->

    <div id="grid"></div>
  </v-container>
</template>

<script>
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";

import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
export default {
  name: "relacionar-masivamente",
  title: "Admin crear proveedor masivo",
  data: () => ({
    excel: null,
    user: null,
    reverse: false,
    cards: [],
    loading: true,
    responseApi: {},
  }),
  methods: {
    exportarProveedores() {
      EventBus.$emit("cargando", true);
      API.exportarformatoMasivoProveedores(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cargarExcel(excel) {
      this.$data.excel = excel;
      let formData = new FormData();
      formData.append("archivo", this.$data.excel);
      API.crearMasivaProveedores(formData, this.$store.state.token)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.excel = "";
          this.responseApi = res.data;
        })
        .catch((err) => {
          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    "info-masivos-component": InfoMasivosComponent,
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
