
import store from '@/store'



export const Uri = {
  string(s) {
    var r = s.replace(new RegExp(/%2F/g), "/");
    return r;
  }
}

export const Normalizer = {
  string(s ){
    var r=s.toLowerCase();
    r = r.replace(new RegExp(/\s/)," ");
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/æ/g),"ae");
    r = r.replace(new RegExp(/ç/g),"c");
    r = r.replace(new RegExp(/[èéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñ/g),"n");
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/œ/g),"oe");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/\W/g),"");
    return r;
  }
}

export const isRutValid = (rut_complete) => {
  let clean_rut = rut_complete.replace(/[^k0-9]/gi, "").split("");

  let rut = clean_rut.slice(0, -1).join("");
  let dv = clean_rut
    .slice(clean_rut.length - 1, clean_rut.length)
    .toString();

  let i = 2;
  let sum = 0;

  // console.log("rut")
  // console.log(rut)
  // console.log("dv")
  // console.log(dv)

  rut
    .split("")
    .reverse()
    .forEach((el) => {
      if (i == 8) {
        i = 2;
      }
      sum += parseInt(el) * i;
      ++i;
    });

  let dvr = 11 - (sum % 11);

  if (dvr == 11) dvr = 0;
  if (dvr == 10) dvr = "K";
  return dvr.toString().toUpperCase() == dv.toUpperCase();
}

export const rutParser = (rut) => {
  // rut = rut.replace(/[^k0-9]/gim, "").split("");
  // return rut.length > 1
  // ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
  //     rut[rut.length - 1]
  //   }`
  // : rut.join("");

  var actual = rut.replace(/^0+/, "");
  if (actual != '' && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, "");
      var actualLimpio = sinPuntos.replace(/-/g, "");
      var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      var rutPuntos = "";
      var i = 0;
      var j = 1;
      for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 == 0 && j <= inicio.length - 1) {
              rutPuntos = "." + rutPuntos;
          }
          j++;
      }
      var dv = actualLimpio.substring(actualLimpio.length - 1);
      rut = rutPuntos + "-" + dv;
  }

  return rut;
};

export const DniTrim = {
  string(s) {
    var r = s.replace(new RegExp(/\./g), "");
    return r;
  }
}
export const isUserLogin = (cb, reject) => {
  if (store.state.user != null) {
    cb()
  } else {
    if (reject !== undefined) reject()
  }
}

export const numeroALetras = (function() {

  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  // ...
  // https://independent.typepad.com/.a/6a00d8341c595453ef01bb08f0cf72970d-500wi
  function Unidades(num){

    switch(num)
    {
      case 1: return 'UN'
      case 2: return 'DOS'
      case 3: return 'TRES'
      case 4: return 'CUATRO'
      case 5: return 'CINCO'
      case 6: return 'SEIS'
      case 7: return 'SIETE'
      case 8: return 'OCHO'
      case 9: return 'NUEVE'
    }

    return '';
  }
  
  function Decenas(num){

    let decena = Math.floor(num/10)
    let unidad = num - (decena * 10)

    switch(decena)
    {
      case 1:
        switch(unidad)
        {
          case 0: return 'DIEZ'
          case 1: return 'ONCE'
          case 2: return 'DOCE'
          case 3: return 'TRECE'
          case 4: return 'CATORCE'
          case 5: return 'QUINCE'
          default: return 'DIECI' + Unidades(unidad)
        }
      case 2:
        switch(unidad)
        {
          case 0: return 'VEINTE'
          default: return 'VEINTI' + Unidades(unidad)
        }
      case 3: return DecenasY('TREINTA', unidad);
      case 4: return DecenasY('CUARENTA', unidad);
      case 5: return DecenasY('CINCUENTA', unidad);
      case 6: return DecenasY('SESENTA', unidad);
      case 7: return DecenasY('SETENTA', unidad);
      case 8: return DecenasY('OCHENTA', unidad);
      case 9: return DecenasY('NOVENTA', unidad);
      case 0: return Unidades(unidad);
    }
  }
  
  function DecenasY(strSin, numUnidades) {
    return (numUnidades > 0) ? `${strSin} Y ${Unidades(numUnidades)}` : strSin
  }
  
  function Centenas(num) {
    let centenas = Math.floor(num / 100)
    let decenas = num - (centenas * 100)

    switch(centenas)
    {
      case 1:
        if (decenas > 0)
            return 'CIENTO ' + Decenas(decenas)
        return 'CIEN'
      case 2: return 'DOSCIENTOS ' + Decenas(decenas)
      case 3: return 'TRESCIENTOS ' + Decenas(decenas)
      case 4: return 'CUATROCIENTOS ' + Decenas(decenas)
      case 5: return 'QUINIENTOS ' + Decenas(decenas)
      case 6: return 'SEISCIENTOS ' + Decenas(decenas)
      case 7: return 'SETECIENTOS ' + Decenas(decenas)
      case 8: return 'OCHOCIENTOS ' + Decenas(decenas)
      case 9: return 'NOVECIENTOS ' + Decenas(decenas)
    }

    return Decenas(decenas);
  }

  function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = ''

    if (cientos > 0)
      letras = (cientos == 1 ? strSingular : `${Centenas(cientos)} ${strPlural}`)

    if (resto > 0)
      letras += ''

    return letras
  }
  function Miles(num) {
    let divisor = 1000
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL')
    let strCentenas = Centenas(resto)

    if(strMiles == '')
      return strCentenas
    return `${strMiles} ${strCentenas}`
  }

  function millon(num, singular) {
    var letraMillon = '';
    if (singular == true)
      letraMillon = 'UN MILLON';
    else
      letraMillon = 'MILLONES'
    if (num % 1000000 == 0)
      letraMillon = letraMillon + ' DE'
    return letraMillon;
  }

  function Millones(num) {
    let divisor = 1000000
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = Seccion(num, divisor, millon(num, true), millon(num, false))
    let strMiles = Miles(resto)

    if(strMillones == '')
        return strMiles

    return `${strMillones} ${strMiles}`
  }
  return function NumeroALetras(num, currency) {
      currency = currency || {}
      let data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: '',
        letrasMonedaPlural: currency.plural || 'PESOS CHILENOS',//'PESOS', 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: currency.singular || 'PESO CHILENO', //'PESO', 'Dólar', 'Bolivar', 'etc'
        letrasMonedaCentavoPlural: currency.centPlural || 'CENTIMOS CHILENOS',
        letrasMonedaCentavoSingular: currency.centSingular || 'CENTIMO CHILENO'
      }

      if (data.centavos > 0) {
        data.letrasCentavos = `CON ${Millones(data.centavos)} ${data.centavos == 1 ? data.letrasMonedaCentavoSingular : data.letrasMonedaCentavoPlural}`
      }

      if(data.enteros == 0) {
        return `CERO ${data.letrasMonedaPlural} ${data.letrasCentavos}`
      }

      if (data.enteros == 1) {
        return `${Millones(data.enteros)} ${data.letrasMonedaSingular} ${data.letrasCentavos}`
      } else {
        return `${Millones(data.enteros)} ${data.letrasMonedaPlural} ${data.letrasCentavos}`
      }
  };
})();

/*
const armarPeda = (amigos, lugar) => amigos.filter(amigo => llamar(amigo, lugar).respuesta == SI).map(amigo => amigo || comprar(amigo.SeToma * 2)).length > 0
*/