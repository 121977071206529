<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-row justify="center">
              <v-col md="8" lg="8" sm="12" cols="12" class="text-center">
                <base-material-card
                  icon="mdi-factory"
                  title="Detalles Marca"
                  class="px-5 py-3"
                  color="primary"
                >
                  <v-container>
                    <v-row>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>ID:</td>
                              <td>{{ marca_info.id }}</td>
                            </tr>
                            <tr>
                              <td>Nombre:</td>
                              <td>{{ marca_info.nombre }}</td>
                            </tr>
                            <tr>
                              <td>Nombre corto:</td>
                              <td>{{ marca_info.nombre_corto }}</td>
                            </tr>
                            <tr>
                              <td>Fecha creación:</td>
                              <td>{{ marca_info.created }}</td>
                            </tr>
                            <tr>
                              <td>Fecha modificación:</td>
                              <td>{{ marca_info.modified }}</td>
                            </tr>
                            <tr>
                              <td>Activo:</td>
                              <td>
                                {{ marca_info.activo == 1 ? "Si" : "No" }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
              <v-col md="8" lg="8" sm="12" cols="12" class="text-center">
                <div class="d-flex">
                  <btn-volver />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
var moment = require("moment");
import { EventBus } from "@/main.js";

export default {
  name: "admin-ver-marca",
  title: "Admin ver marca - Onestock",
  props: ["id"],
  data: () => ({
    marca_info: null,
  }),
  async beforeMount() {
    EventBus.$emit("cargando", true);
    await API.verMarcasAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        EventBus.$emit("cargando", false);
        this.$data.marca_info = res.data.respuesta;
        this.$data.marca_info.created = moment(
          res.data.respuesta.created
        ).format("DD-MM-YYYY");
        this.$data.marca_info.modified = moment(
          res.data.respuesta.modified
        ).format("DD-MM-YYYY");
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {},
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.text {
  word-break: break-word;
}
td {
  text-align: left;
}
.centerheight {
  text-align: center !important;
}
</style>
