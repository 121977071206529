<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col lg="12" class="d-flex">
              <div class="pl-3">
                <div>
                  <h4>
                    <span class="primary--text">Estado: </span>
                    {{ proveedor_info.activo == 1 ? "Activo" : "Inactivo" }}
                  </h4>
                </div>
                <div>
                  <h4>
                    <span class="primary--text">
                      {{ proveedor_info.productos }}
                    </span>
                    Productos relacionados
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
                <v-container>
                  <v-row>
                    <v-col md="6" lg="6" sm="12" cols="12" class="text-center">
                      <h4>Información</h4>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>ID:</td>
                              <td>{{ proveedor_info.id }}</td>
                            </tr>
                            <tr>
                              <td>Nombre:</td>
                              <td>{{ proveedor_info.nombre }}</td>
                            </tr>
                            <tr>
                              <td>Nombre corto:</td>
                              <td>{{ proveedor_info.nombre_corto }}</td>
                            </tr>
                            <tr>
                              <td>Fecha creación:</td>
                              <td>{{ proveedor_info.created }}</td>
                            </tr>
                            <tr>
                              <td>Fecha modificación:</td>
                              <td>{{ proveedor_info.modified }}</td>
                            </tr>
                            <tr>
                              <td>E-mail:</td>
                              <td>{{ proveedor_info.email }}</td>
                            </tr>
                            <tr>
                              <td>Disponibilidad:</td>
                              <td>{{ proveedor_info.disponibilidad }}</td>
                            </tr>
                            <tr>
                              <td>Dirección comercial:</td>
                              <td>
                                {{ proveedor_info.direccion_comercial }}
                              </td>
                            </tr>
                            <tr>
                              <td>Activo:</td>
                              <td>
                                {{ proveedor_info.activo == 1 ? "Si" : "No" }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col md="6" lg="6" sm="12" cols="12" class="text-center">
                      <h4>Actualización automática</h4>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>Ftp:</td>
                              <td>
                                {{
                                  proveedor_info.ftp_host != null
                                    ? proveedor_info.ftp_host
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Ftp password:</td>
                              <td>
                                {{
                                  proveedor_info.ftp_password != null
                                    ? proveedor_info.ftp_password
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Ftp puerto:</td>
                              <td>
                                {{
                                  proveedor_info.ftp_puerto != null
                                    ? proveedor_info.ftp_puerto
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Ftp usuario:</td>
                              <td>
                                {{
                                  proveedor_info.ftp_usuario != null
                                    ? proveedor_info.ftp_usuario
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <div class="my-16">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
var moment = require("moment");

export default {
  name: "VerProveedorFormComponent",
  title: "Admin ver proveedor - Onestock",
  props: ["id"],
  data: () => ({
    proveedor_info: null,
  }),
  async beforeMount() {
    await API.verProveedorAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        console.log("verProveedorAdmin", res);
        this.$data.proveedor_info = res.data.data;
        this.$data.proveedor_info.created = moment(res.data.created).format(
          "DD-MM-YYYY"
        );
        this.$data.proveedor_info.modified = moment(res.data.modified).format(
          "DD-MM-YYYY"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.text {
  word-break: break-word;
}
td {
  text-align: left;
}
.centerheight {
  text-align: center !important;
}
</style>
