<template>
    <v-container>
        <v-row>
            <v-col lg="12">
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                            md="12"
                            lg="12"
                            sm="12"
                            cols="12">
                        <v-btn color="info" rounded
                        :disabled="bloqueo_boton==true"
                        @click="exportarProducto()">Exportar Productos
                        </v-btn>
                         <v-btn color="secondary" rounded 
                        :disabled="bloqueo_boton==true"
                        @click="editarStockMasivamente()">Editar stock masivamente
                        </v-btn>
                        </v-col>
                     </v-row>
                    <v-card class="px-3 pb-0">
                        <v-card-title class="pl-0 pt-2">
                            <v-icon>mdi-magnify</v-icon>
                            Filtro Productos
                        </v-card-title>
                    <v-row>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                        <v-text-field
                            hide-details
                            dense
                            label="Sku"
                            v-model="sku"
                            placeholder="Sku"
                            clearable
                            outlined
                        ></v-text-field>
                        </v-col>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                        <v-autocomplete
                            hide-details
                            dense
                            outlined
                            label="Marca"
                            v-model="marca"
                            :items="filter.marca"
                            color="primary"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Marca"
                            clearable
                        ></v-autocomplete>
                        </v-col>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                        <v-text-field
                            hide-details
                            dense
                            outlined
                            label="Nombre"
                            v-model="nombre"
                            color="primary"
                            placeholder="Nombre"
                            clearable
                        ></v-text-field>
                        </v-col>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                        <v-text-field
                            label="Upc"
                            hide-details
                            dense
                            outlined
                            v-model="upc"
                            color="primary"
                            placeholder="Upc"
                            clearable
                            maxlength="12"
                            :rules="rules.numeros"
                        ></v-text-field>
                        </v-col>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                        <v-text-field
                        label="Ean"
                        hide-details
                            dense
                            outlined
                            v-model="ean"
                            color="primary"
                            placeholder="Ean"
                            clearable
                            maxlength="13"
                            :rules="rules.numeros"
                        ></v-text-field>
                        </v-col>
                        <v-col
                            md="2"
                            lg="2"
                            sm="6"
                            cols="12"
                            class="text-center">
                            <v-container class="pa-0 ma-0" fluid>
                                <v-layout class="pa-0 ma-0">
                                    <v-col cols="12" md="10" lg="10" sm="10" class="pa-0 ma-0">
                                        <v-btn block rounded color="secondary" @click="filtrarDatos2">
                                            Filtrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="2" lg="2" sm="2" class="pa-0 ma-0">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                icon
                                                rounded
                                                color="info"
                                                 v-bind="attrs"
                                                v-on="on"
                                                @click="restablecer"
                                                >
                                                <v-icon>mdi-cached</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Restablecer Filtros</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-layout>
                            </v-container>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-container>
                <v-container fluid class="pa-1"> 
                    <v-row>
                        <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                        
                           <base-material-card
                            icon="mdi-align-vertical-bottom"
                            title="Productos"
                            class="py-3"
                            color="primary"
                            :registros="total"
                             acciones="true"
                            :enlaces="enlaces"
                            >
                            <v-data-table
                                dense
                                no-data-text="No existe registros en tu cuenta"
                                :headers="headers"
                                :items="datos"
                                :options.sync="options"
                                :server-items-length="totalDesserts"
                                :loading="loading"
                                class="elevation-1"
                                :footer-props="{
                                    itemsPerPageOptions: [10, 25, 50], // -> Add this example
                                    showFirstLastPage: true,
                                    disablePagination:loading,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    prevIcon: 'mdi-minus',
                                    nextIcon: 'mdi-plus',
                                    'items-per-page-text':'Registros por página'
                                }"
                                >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.name">
                                        <td>{{ item.producto_id }}</td>
                                        <td>{{ item.nombre }}</td>
                                        <td>{{ item.id_marca != null ? item.nombre_marca : '-'}}</td>
                                        <td>{{ item.stock != null ? item.stock : '-'}}</td>
                                        <td>{{ item.sku != null ? item.sku : '-'}}</td>
                                        <td>{{ item.upc != null ? item.upc : '-'}}</td>
                                        <td>{{ item.ean != null ? item.ean : '-'}}</td>
                                        <td>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                    color="secondary"
                                                    medium
                                                    block
                                                    @click="verProducto(item.producto_id,user_id)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    > mdi-eye-arrow-right
                                                    </v-icon>
                                                </template>
                                                <span>Ver Producto</span>
                                            </v-tooltip>
                                               <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                    color="info"
                                                    @click="editarProducto(item.producto_id,user_id)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >mdi-lead-pencil</v-icon>
                                                </template>
                                                <span>Editar Stock</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                                </v-data-table>
                                </base-material-card>
                                <v-row v-if="errors.length > 0">
                                    <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2"> 
                                    <div v-if="errors.length > 0" class="">
                                        <v-alert 
                                            dense
                                            style="padding:8px "
                                            text
                                            type="error"
                                            dismissible
                                                v-for="(err ,i) in errors" :key="i" 
                                            >
                                            <strong>{{err}}</strong>
                                        </v-alert>
                                    </div>
                                    </v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogRegistrar" persistent max-width="600">
            <v-card class=" pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialogRegistrar = false">
                    x
                </v-btn>
                <v-card-title class="py-5">
                </v-card-title>
                <base-material-card
                    icon="mdi-align-vertical-bottom"
                    title="Agregar nuevo proveedor"
                    class="px-5 py-3"
                    color="primary"
                    >
                <v-container>
                    <v-row>
                        <v-col
                            md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            >
                            <v-form
                            ref= "valid"
                                v-model="valid"
                                lazy-validation
                                autocomplete="false"
                            >
                                <v-text-field
                                v-model="prod.sku"
                                label="Sku"
                                :rules='rules.notEmpty'
                                required
                                clearable
                                ></v-text-field>
                                <v-text-field
                                v-model="prod.stock"
                                label="Stock"
                                :rules='rules.notEmpty'
                                required
                                clearable
                                ></v-text-field>
                                <v-btn
                                color="secondary"
                                rounded
                                class="text-center"
                                :disabled="!valid"
                                @click="registrarProveedor()"
                                >
                                    Registrar Proveedor
                                </v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </base-material-card>
        </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'

  export default {
    name: 'SubEditarStockProveedores',
    props: ['id'],
    data: () => ({
      valid: '',
      enlaces: [
        {
          text: 'Editar Stock Masivamente',
          to: {
            name: 'edicion-masiva-productos-stock',
          },
        },
      ],
        datosClientes:[],
        totalDesserts2: 0,
        desserts2: [],
         bloqueo_boton: false,
        loading2: false,
        options2: {},
         totalDesserts3: 0,
        desserts3: [],
        errors2:[],
        errors:[],
        loading3: false,
        options3: {},
        user_id:"",
        buscar_id:null,
        buscar_nom:null,
        buscar_cod_int:null,
        buscarc_id:null,
        buscarc_nom:null,
        buscarc_rut:null,
        buscarc_email:null,
        verProducts:false,
        dialogRegistrar:false,
        prod:{
            id:null,
            sku:null,
            stock:0
        },
        total:0,
        totalDesserts: 0,
        desserts: [],
        options: {},
        loading:false,
        headers: [
            {
                text: 'ID',
                align: 'Center',
                sortable: true,
                value:'id'
            },
            { 
                text: 'Nombre', 
                 align: 'Center',
                value: 'nombre', 
            },
            { 
                text: 'Marca', 
                 align: 'Center',
                value: 'marca', 
                sortable: false,
            },
            { 
                text: 'Stock', 
                 align: 'Center',
                value: 'stock', 
                sortable: false,
            },
            { 
                text: 'Sku', 
                align: 'Center',
                value: 'sku', 
                sortable: false,
            },
            { 
                text: 'Upc', 
                 align: 'Center',
                value: 'upc', 
                sortable: false,
            },
            { 
                text: 'Ean', 
                 align: 'Center',
                value: 'ean',
                sortable: false,
            },
            {
                text:'Acciones',
                 align: 'Center',
                value:'',
                 sortable: false,
            },
           
        
        ],
      
        datos:[],
        datos2:[],
        filter:{
            mi_id:[],
            marca:[],
            nombre:[],
            ean:[],
            upc:[],
            sku:[]
        },
      mi_id:'',
      bloqueo_boton: 'false',
        marca:'',
        nombre:'',
        ean:'',
        upc:'',
        sku:'',
        rules:{
            numeros: [
                (v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números",
            ],
        }

    }),
  async beforeMount(){
    EventBus.$emit('cargando', true)
        await API.listarMarcas(this.$store.state.token)
        .then((res)=>{
            this.$data.filter.marca=res.data.data
             EventBus.$emit('cargando', false)
        })
        .catch((err)=>{
            console.log(err)
             EventBus.$emit('cargando', false)
        })
    },
    methods:{
         exportarProducto () {
  this.$data.bloqueo_boton = true
        EventBus.$emit('cargando', true)
        API.exportarProdProvee(this.$store.state.user.id, this.$store.state.token)
          .then((res) => {
            window.open(res.respuesta)
              this.$data.bloqueo_boton = false
            EventBus.$emit('cargando', false)
          })
            .catch((err)=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Error, intente de nuevo',
                    showConfirmButton: false,
                    timer: 1500
                    })
                    this.$data.bloqueo_boton = false
                EventBus.$emit('cargando',false)
            })
        },

        restablecer(){
            this.$data.sku = ''
            this.$data.marca = ''
            this.$data.nombre = ''
            this.$data.ean = ''
            this.$data.upc = ''
            this.filtrarDatos2()
        },
        verProducto(id,id_proveedor){
           this.$router.push(
                            {
                            path: '/proveedor/ver-producto/'+id+'/'+id_proveedor, 
                            });
        },
             editarStockMasivamente(){
                  this.$router.push('/proveedor/edicion-masiva-producto-stock')
        },
         editarProducto(id,id_proveedor){
           this.$router.push(
                            {
                            path: '/proveedor/editar-producto/'+id+'/'+id_proveedor, 
                            });
        },
        filtrarDatos(){
           this.user_id=this.$store.state.user.id
             this.$data.errors = [];
            this.$data.loading=true
            EventBus.$emit('cargando',true)

             let nombre
            this.$data.nombre != undefined
            ? nombre = this.$data.nombre
            : nombre = ''

            let marca_id 
            this.$data.marca != undefined
            ?  marca_id = this.$data.marca 
            : marca_id = ''
            
            let ean
            this.$data.ean != undefined
            ? ean = this.$data.ean
            : ean = ''
        
            let upc
            this.$data.upc != undefined
            ? upc = this.$data.upc
            : upc = ''

            let sku
            this.$data.sku != undefined
            ? sku = this.$data.sku
            : sku = ''
             
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
          
                
               let order = "id"
            
            this.$data.order != undefined
            ? order = this.$data.order
            : order = "id"

            let direction = 'asc'
            sortDesc[0] != true ? direction = 'asc' : direction = "desc"
           
                API.listarProductosProveedores(this.$store.state.token,this.$store.state.user.id,direction,order,this.$data.nombre != undefined ? this.$data.nombre : '',sku,marca_id,ean,upc,page,itemsPerPage)
                     .then((res)=>{
                        EventBus.$emit('cargando',false)
                        this.$data.loading=false
                        
                        this.$data.datos = res.data.respuesta.data
                        console.log(this.$data.datos)
                        this.$data.datos.forEach((el,i)=>{
                           
                            var marca = this.$data.filter.marca.find(marca => marca.id == el.marca_id )
                            this.$data.datos[i].marca = marca
                      
                        })
                     
                        
                        this.$data.total = res.data.respuesta.total   
                        let items = this.$data.datos
                        const total = items.length
                        this.$data.totalDesserts = res.data.respuesta.last_page * itemsPerPage
                        this.$data.filter.mi_id=[];

                        res.data.respuesta.data.forEach((el,i)=>{
                            el.mi_id != null ? this.$data.filter.mi_id.push(el.mi_id) : ''
                        })
                        if(this.$data.datos.length <= 0){
                         this.$data.errors.push('Producto no encontrado')
                        }
                    })
                    .catch((err)=>{
                        EventBus.$emit('cargando',false)
                        this.$data.loading=false
                    })
        },
        filtrarDatos2(){
            this.$data.loading=true
            EventBus.$emit('cargando',true)

             let nombre
            this.$data.nombre != undefined
            ? nombre = this.$data.nombre
            : nombre = ''

            let marca_id 
            this.$data.marca != undefined
            ?  marca_id = this.$data.marca 
            : marca_id = ''
            
            let ean
            this.$data.ean != undefined
            ? ean = this.$data.ean
            : ean = ''
        
            let upc
            this.$data.upc != undefined
            ? upc = this.$data.upc
            : upc = ''

            let sku
            this.$data.sku != undefined
            ? sku = this.$data.sku
            : sku = ''
             
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
                
               let order = "id"
            
            this.$data.order != undefined
            ? order = this.$data.order
            : order = "id"

            let direction = 'asc'
            sortDesc[0] != true ? direction = 'asc' : direction = "desc"
            this.options.page = 1
          
                API.listarProductosProveedores(this.$store.state.token,this.$store.state.user.id,direction,order,this.$data.nombre != undefined ? this.$data.nombre : '',sku,marca_id,ean,upc,page,itemsPerPage)
                    .then((res)=>{
                        EventBus.$emit('cargando',false)
                        this.$data.loading=false
                       
                        this.$data.datos = res.data.respuesta.data

                        this.$data.datos.forEach((el,i)=>{
                            var marca = this.$data.filter.marca.find(marca => marca.id == el.marca_id )
                            this.$data.datos[i].marca = marca
                        })
                     
                        
                        this.$data.total = res.data.respuesta.total   
                        let items = this.$data.datos
                        const total = items.length
                        this.$data.totalDesserts = res.data.respuesta.last_page * itemsPerPage
                        this.$data.filter.mi_id=[];

                        
 
                       if(this.$data.datos.length <= 0){
                         this.$data.errors.push('Producto no encontrado')
                        }
                    })
                    .catch((err)=>{
                        EventBus.$emit('cargando',false)
                        this.$data.loading=false
                      
                    })
        }
    },
    watch:{
        options: {
            handler () {
            this.filtrarDatos()
            },
            deep: true,
        },
        options2: {
            handler () {
            this.filtrarDatos()
            },
            deep: true,
        },
        options3: {
            handler () {
            this.filtrarDatos()
            },
            deep: true,
        },
        verProducts(v){
            v == false ? this.$data.datosClientes = [] : '';
        },
        sku(v){
            if(v == null){
                this.$data.sku = ''
                this.filtrarDatos2();
            }
        },
        marca(v){
            if(v == null){
                this.$data.marca = ''
                this.filtrarDatos2();
            }
        },
        nombre(v){
          if(v == null){
              this.$data.nombre = ''
               this.filtrarDatos2();
          }
        },
        ean(v){
          if(v == null){
              this.$data.ean = ''
               this.filtrarDatos2();
          }
        },
        upc(v){
          if(v == null){
              this.$data.upc = ''
               this.filtrarDatos2();
          }
        },
    }
    
}
</script>
<style lang="scss" >

</style>