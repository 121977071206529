<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-currency-usd"
                title="Cobros"
                class="px-5 py-3"
                color="primary"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="no_row_data"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.cliente_id }}</td>
                        <td>{{ item.created }}</td>
                        <td>{{ item.fecha_inicio_periodo }}</td>
                        <td>
                          {{
                            item.fecha_pagado != null ? item.fecha_pagado : "-"
                          }}
                        </td>
                        <td>{{ item.fecha_termino_periodo }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.pagado == 1 ? "Sí" : "No" }}</td>
                        <td>{{ item.total_cobro | currency }}</td>
                        <td>{{ item.total_peticiones }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verCobros(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom v-if="item.pagado == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                @click="finalizarCobro(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-currency-usd-off</v-icon
                              >
                            </template>
                            <span>Finalizar cobro</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red darken-2 ma-1"
                                @click="reversarCobro(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-cancel</v-icon
                              >
                            </template>
                            <span>Restablecer cobro</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <btn-volver />
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnVolver from "@/components/BtnVolver.vue";
var moment = require("moment");

export default {
  name: "CobrosEdit",
  props: ["id"],
  data: () => ({
    datos: [],
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    headers: [
      {
        text: "Cte ID",
        align: "center",
        sortable: true,
        value: "cliente_id",
        width: "3%",
      },
      {
        text: "Fech creac",
        align: "center",
        value: "created",
        sortable: false,
        width: "10%",
      },
      {
        text: "Inic prdo",
        align: "center",
        value: "fecha_inicio_periodo",
        sortable: false,
        width: "10%",
      },
      {
        text: "Fech pgdo",
        align: "center",
        value: "fecha_pagado",
        sortable: false,
        width: "10%",
      },
      {
        text: "Fin prdo",
        align: "center",
        value: "fecha_termino_periodo",
        sortable: false,
        width: "10%",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
        width: "18%",
      },
      {
        text: "Pgdo",
        align: "center",
        value: "pagado",
        sortable: false,
        width: "11%",
      },
      {
        text: "Cbrs",
        align: "center",
        value: "total_cobro",
        sortable: false,
        width: "15%",
      },
      {
        text: "Petic.",
        align: "center",
        value: "total_peticiones",
        sortable: false,
        width: "11%",
      },
      {
        text: "Ver",
        align: "center",
        value: "acciones",
        sortable: false,
        width: "1%",
      },
      {
        text: "Fin Cbr",
        align: "center",
        value: "acciones",
        sortable: false,
        width: "1%",
      },
    ],
    filter: {
      desde: [],
      hasta: [],
      pagado: [],
    },
    desde: "",
    hasta: "",
    pagado: "",
  }),
  async beforeMount() {
    await this.filtrarDatos();
  },
  methods: {
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let desde;
      this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

      let hasta;
      this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

      let pagado;
      this.$data.pagado != undefined
        ? (pagado = this.$data.pagado)
        : (pagado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      API.listarCobrosAdminEditar(
        this.$store.state.token,
        this.$props.id,
        desde,
        hasta,
        pagado,
        order,
        direction,
        page,
        itemsPerPage
      ).then((res) => {
        this.$data.loading = false;
        EventBus.$emit("cargando", false);
        this.$data.datos = res.respuesta.data;
        this.$data.datos.forEach((el) => {
          el.created = moment(el.created).format("DD-MM-YYYY");
          el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format(
            "DD-MM-YYYY"
          );
          el.fecha_termino_periodo = moment(el.fecha_termino_periodo).format(
            "DD-MM-YYYY"
          );
          el.fecha_pagado != null
            ? (el.fecha_pagado = moment(el.fecha_pagado).format("DD-MM-YYYY"))
            : null;
        });
        let items = this.$data.datos;
        const total = items.length;
        this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
      });
    },
    verCobros(id) {
      this.$router.push("/admin/ver-cobros/" + id);
    },
    finalizarCobro(id) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Agregue un comentario para finalizar",
        input: "textarea",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Finalizar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (observacion) => {
          API.finalizarCobrosAdmin(this.$store.state.token, id, observacion)
            .then((res) => {
              this.filtrarDatos();
            })
            .catch((err) => {});
        },
      });
    },
    reversarCobro(id) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Indique el motivo de la reversa",
        input: "textarea",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Revertir",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (motivo) => {
          API.reversarCobrosAdmin(this.$store.state.token, id, motivo)
            .then((res) => {
              this.filtrarDatos();
            })
            .catch((err) => {});
        },
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
  },
  components:{
    BtnVolver
  },
};
</script>
<style lang="scss"></style>
