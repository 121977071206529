<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Proveedores
            </v-card-title>
            <v-row justify-center align-center>
              <v-col sm="12" cols="12" class="text-center">
                <v-text-field
                  dense
                  hide-details
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                  outlined
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <v-text-field
                  label="Ingrese un ID"
                  dense
                  hide-details
                  v-model="ids"
                  color="primary"
                  placeholder="Ingrese un ID"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>

              <v-col sm="6" cols="12" class="text-center">
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-truck-check"
                title="Proveedores"
                class="py-3"
                color="primary"
                :registros="total"
                :showBtn="{ add: true }"
                @onAddBtn="activarRelacionar"
              >
                <v-data-table
                  calculate-widths
                  dense
                  no-data-text="No existe registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10],
                    showFirstLastPage: true,
                    disablePagination: loading,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.direccion_comercial }}</td>
                        <td>
                          {{
                            item.disponibilidad != null
                              ? item.disponibilidad
                              : "-"
                          }}
                        </td>
                        <td>
                          <v-tooltip bottom v-if="item.clientes.length == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="verProveedores(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-link</v-icon
                              >
                            </template>
                            <span>Relacionar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red"
                                @click="quitarRelacionCliente(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-autorenew-off</v-icon
                              >
                            </template>
                            <span>Quitar Relación</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title class="mb-3">
          <h3>Proveedores</h3>
        </v-card-title>
        <v-select
          v-model="provee"
          :items="prov"
          label="Proveedores"
          outline
          item-text="nombre"
          item-value="id"
          outlined
          dense
        ></v-select>

        <v-btn color="secondary" rounded @click="relacionarProveedorCliente()">
          Relacionar Proveedores
        </v-btn>
      </v-card>
    </v-dialog>
    <btn-volver />
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

export default {
  name: "SubProveedores",
  props: ["id"],
  data: () => ({
    dialog: false,
    total: 0,
    loading: false,
    totalDesserts: 0,
    desserts: [],
    options: {},
    provee: null,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },

      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: true,
      },
      {
        text: "Dirección",
        align: "center",
        value: "direccion_comercial",
        sortable: false,
      },
      {
        text: "Tipo de disponibilidad",
        align: "center",
        value: "disponibilidad",
        sortable: false,
      },
      {
        text: "Acciones Relac.",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],

    datos: [],
    ids: "",
    prov: [],
    nombre: "",
    tipo: "",
    filter: {
      id: [],
      nombre: [],
      tipo: [],
    },
    rules: {
      numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
    },
  }),
  beforeMount() {
    this.listarProveedores();
    this.filtrarDatos();
  },
  methods: {
    listarProveedores() {
      API.listarProveedoresAdmin(this.$store.state.token)
        .then((res) => {
          this.$data.prov = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    activarRelacionar() {
      this.$data.dialog = true;
    },
    relacionarProveedorCliente() {
      EventBus.$emit("cargando", true);
      API.relacionarProveedor(
        this.$data.provee,
        this.$store.state.token,
        this.$props.id
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Datos actualizados",
          showConfirmButton: false,
          timer: 1500,
        });
        EventBus.$emit("cargando", false);
        this.filtrarDatos();
        this.$emit("recargaProducto", true);
        setTimeout(() => {
          this.$emit("recargaProducto", false);
        }, 1000);
        this.$data.dialog = false;
      });
    },
    verProveedores(id_proveedor) {
      API.relacionarProveedor(
        id_proveedor,
        this.$store.state.token,
        this.$props.id
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          this.filtrarDatos();
          this.$emit("recargaProducto", true);
          setTimeout(() => {
            this.$emit("recargaProducto", false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    quitarRelacionCliente(id_cliente) {
      console.log(this.$store.state.token, id_cliente, this.$props.id);
      API.quitarRelacionClientes(
        this.$store.state.token,
        this.$props.id,
        id_cliente
      ).then(() => {
        Swal.fire({
          icon: "success",
          title: "Datos actualizados",
          showConfirmButton: false,
          timer: 1500,
        });
        this.filtrarDatos();
        this.$emit("recargaProducto", true);
        setTimeout(() => {
          this.$emit("recargaProducto", false);
        }, 1000);
      });
    },
    quitarProveedores(id_proveedor) {
      API.quitarRelacionProveedores(
        id_proveedor,
        this.$store.state.token,
        this.$props.id
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    restablecer() {
      this.$data.ids = "";
      this.$data.nombre = "";
      this.filtrarDatos2();
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      let id_prod;
      this.$data.ids != undefined ? (id_prod = this.$data.ids) : (id_prod = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }

      API.listarProveedores(
        this.$props.id,
        this.$store.state.token,
        direction,
        order,
        id_prod,
        nombre,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.datos = res.data.data;
          this.$data.total = res.data.total;
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      let id_prod;
      this.$data.ids != undefined ? (id_prod = this.$data.ids) : (id_prod = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;

      API.listarProveedores(
        this.$props.id,
        this.$store.state.token,
        direction,
        order,
        id_prod,
        nombre,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.datos = res.data.data;
          this.$data.total = res.data.total;
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ids(v) {
      if (v == null) {
        this.$data.ids = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss">
.btnclose {
  position: absolute !important;
  right: 1rem;
  border-radius: 25px;
  padding: 11px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px !important;
  width: 36px;
}
</style>
