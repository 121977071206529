var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"md":"12","lg":"12","sm":"12","cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-currency-usd","title":"Cobros","color":"primary"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"calculate-widths":"","no-data-text":"no_row_data","headers":_vm.headers,"items":_vm.datos,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"footer-props":{
                  itemsPerPageOptions: [5, 10], 
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.cliente_id))]),_c('td',[_vm._v(_vm._s(item.created))]),_c('td',[_vm._v(_vm._s(item.fecha_inicio_periodo))]),_c('td',[_vm._v(" "+_vm._s(item.fecha_pagado != null ? item.fecha_pagado : "-")+" ")]),_c('td',[_vm._v(_vm._s(item.fecha_termino_periodo))]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_vm._v(_vm._s(item.pagado == 1 ? "Sí" : "No"))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.total_cobro)))]),_c('td',[_vm._v(_vm._s(item.total_peticiones))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","medium":"","block":""},on:{"click":function($event){return _vm.verCobros(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-arrow-right ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1),_c('td',[(item.pagado == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.finalizarCobro(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd-off")])]}}],null,true)},[_c('span',[_vm._v("Finalizar cobro")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-2 ma-1"},on:{"click":function($event){return _vm.reversarCobro(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v("Restablecer cobro")])])],1)])}),0)]}}])})],1)],1)],1)],1)],1)],1),_c('btn-volver')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }