<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col sm="12" cols="12" class="mt-5">
              <base-material-card
                icon="mdi-file-powerpoint-outline"
                title="Plan"
                class="px-5 py-3"
                color="primary"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Nombre</td>
                        <td>
                          {{ datos.respuesta.nombre }}
                        </td>
                      </tr>
                      <tr>
                        <td>Descripción</td>
                        <td>
                          {{ datos.respuesta.descripcion }}
                        </td>
                      </tr>
                      <tr>
                        <td>Ilimitado</td>
                        <td>
                          {{ datos.respuesta.ilimitado == 1 ? "Si" : "No" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Limite diario</td>
                        <td>
                          {{ datos.respuesta.limite_diario }}
                        </td>
                      </tr>
                      <tr>
                        <td>Precio petición</td>
                        <td>
                          {{ datos.respuesta.precio_peticion | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td>Precio sobreconsumo</td>
                        <td>
                          {{ datos.respuesta.precio_sobre_consumo | currency }}
                        </td>
                      </tr>

                      <tr>
                        <td>Activo</td>
                        <td>
                          {{ datos.respuesta.activo == 0 ? "Si" : "No" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-account-group"
                title="Clientes"
                class="px-5 py-3"
                color="primary"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="No existen registros en tu cuenta"
                  :items-per-page="3"
                  :headers="headers"
                  :items="datos.respuesta.clientes"
                  class="text-center"
                  :footer-props="{
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.rut }}</td>
                        <td>{{ item.plan_id }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.created }}</td>
                        <td>{{ item.modified }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <btn-volver />
  </v-container>
</template>

<script>
import API from "@/api";
import { EventBus } from "@/main.js";
import BtnVolver from "@/components/BtnVolver.vue";
var moment = require("moment");

export default {
  name: "ver-plan",
  title: "Admin ver plan - Onestock",
  props: ["id"],
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Plan Id",
        align: "center",
        value: "plan_id",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "center",
        value: "email",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "fecha_pagado",
        sortable: false,
      },
      {
        text: "Fecha modificación",
        align: "center",
        value: "modified",
        sortable: false,
      },
    ],
    datos: [],
  }),
  async beforeMount() {
    EventBus.$emit("cargando", true);
    await API.verPlanAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        EventBus.$emit("cargando", false);
        this.$data.datos = res;
        this.$data.datos.respuesta.clientes.forEach((el) => {
          el.created = moment(el.created).format("DD-MM-YYYY");
          el.modified = moment(el.modified).format("DD-MM-YYYY");
        });
      })
      .catch((err) => {
        EventBus.$emit("cargando", false);
        console.log(err);
      });
  },
  methods: {},
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss"></style>
