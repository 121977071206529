<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col cols="12">
        <v-container class="pa-1" fluid>
          <v-card class="mx-0 mt-6">
            <v-row>
              <v-col cols="12" class="text-center">
                <base-material-card
                  icon="mdi-tag-multiple"
                  title="Información del Producto"
                  color="primary"
                >
                  <v-container>
                    <v-row v-if="errors.length > 0">
                      <v-col cols="12">
                        <div v-if="errors.length > 0" class="">
                          <v-alert
                            dense
                            style="padding: 8px"
                            text
                            type="error"
                            dismissible
                            v-for="(err, i) in errors"
                            :key="i"
                          >
                            <strong>{{ err }}</strong>
                          </v-alert>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-form ref="form" v-model="valid" autocomplete="false">
                          <v-text-field
                            v-model.trim="producto_info.nombre"
                            label="Nombre"
                            :rules="rules.notEmpty"
                            required
                            clearable
                          ></v-text-field>
                          <v-autocomplete
                            v-model.trim="producto_info.marca_id"
                            :items="marcaSort"
                            color="primary"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Marca"
                            clearable
                            :rules="rules.notEmpty"
                          ></v-autocomplete>
                          <v-text-field
                            v-model="producto_info.codigo_interno"
                            label="Código interno"
                            :rules="rules.notEmpty"
                            required
                            clearable
                          ></v-text-field>
                          <v-text-field
                            v-model="producto_info.upc"
                            label="Upc: Código Universal de Produto"
                            :rules="rules.upc"
                            required
                            clearable
                            counter
                          ></v-text-field>
                          <v-text-field
                            v-model="producto_info.ean"
                            label="Ean: Número de Artículo Europeo"
                            :rules="rules.ean"
                            required
                            clearable
                            counter
                          ></v-text-field>
                          <v-switch
                            v-model="activos"
                            class="ma-2"
                            :label="activ"
                          ></v-switch>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" class="mb-3"> <btn-volver /> </v-col>
              <v-col cols="4">
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-btn
                    :color="hover ? 'secondaryDark' : 'secondary'"
                    rounded
                    class="text-center"
                    :elevation="hover ? 0 : 6"
                    :disabled="!valid"
                    @click="actualizarProducto()"
                  >
                    Guardar
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "admin-editar-producto",
  title: "Admin editar producto - Onestock",
  props: ["id"],
  data: () => ({
    dialog: false,
    dialog2: false,
    activos: false,
    valid: null,
    valid2: null,
    producto_info: null,
    user: null,
    marca_id: null,
    marca: null,
    imagen: null,
    errors: [],
    prod: {
      id_proveedor: null,
      sku: null,
      stock: 0,
    },
    cliente: {
      id_producto: null,
      id_interno: null,
    },
    id_cliente: null,
    listar_clientes: [],
    cards: [],
    listar_proveedores: null,
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Dirección",
        value: "direccion_comercial",
      },
      {
        text: "Disponibilidad",
        value: "disponible",
      },
      {
        text: "Stock",
        value: "stock",
      },
      {
        text: "Acciones",
        value: "accion",
      },
    ],
    headers_cliente: [
      {
        text: "Id",
        value: "id",
      },
      {
        text: "Id Interno",
        value: "id_interno",
      },
      {
        text: "Rut",
        value: "rut",
      },
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Acciones",
        value: "",
      },
    ],
    rules: {
      notEmpty: [
        (v) => {
          return (
            typeof v == "number" || !!v?.trim() || "Este campo es requerido"
          );
        },
      ],
      ean: [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length == 13 || "Tienen que ser 13 caracteres",
      ],
      upc: [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length == 12 || "Tienen que ser 12 caracteres",
      ],
    },
  }),
  beforeMount() {
    API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.verProducto();
    API.listarProveedoresAdmin(this.$store.state.token)
      .then((res) => {
        this.$data.listar_proveedores = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    API.listarClientes(this.$store.state.token)
      .then((res) => {
        this.$data.listar_clientes = res.data.respuesta.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    editarCliente(id) {
      this.$router.push("/admin/editar-cliente/" + id);
    },
    back() {
      this.$router.go(-1);
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        this.$data.imagen = file[0];
        reader.onload = (e) => {
          this.$data.producto_info.imagen = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    verProducto() {
      EventBus.$emit("cargando", true);
      API.verProductoAdmin(this.$props.id, this.$store.state.token)
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.producto_info = res.data.respuesta;
          this.$data.activos = res.data.respuesta.activo == 1 ? true : false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agregar_proveedor() {
      this.$data.dialog = true;
    },
    registrarProveedor() {
      API.relacionarNuevoProveedor(
        this.$store.state.token,
        this.$props.id,
        this.$data.prod
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.dialog = false;
          this.verProducto();
        })
        .catch((err) => {
          console.log(err.response.data);
          const mensajes = err.response.data.respuesta;
          for (const key in mensajes) {
            this.$data.errors.push(...mensajes[key]);
          }
        });
    },
    quitarRelacion(id_proveedor) {
      API.quitarRelacionProveedor(
        this.$store.state.token,
        this.$props.id,
        id_proveedor
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.verProducto();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agregar_cliente() {
      this.$data.dialog2 = true;
    },
    registrarProveedorCliente(id_cliente) {
      API.relacionarProveedor(
        this.$props.id,
        this.$store.state.token,
        id_cliente
      ).then(() => {
        this.filtrarDatos();
      });
    },
    actualizarProducto() {
      this.$data.errors = [];
      let payload = {
        nombre: this.$data.producto_info.nombre,
        marca_id: this.$data.producto_info.marca_id,
        codigo_interno: this.$data.producto_info.codigo_interno,
        upc:
          this.$data.producto_info.upc != null
            ? this.$data.producto_info.upc
            : "",
        ean:
          this.$data.producto_info.ean != null
            ? this.$data.producto_info.ean
            : "",
        imagen: this.$data.imagen != null ? this.$data.imagen : "",
        activo: this.$data.activos == true ? 1 : 0,
      };
      API.editarProducto(this.$store.state.token, payload, this.$props.id)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.verProducto();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log(err.response.data);
          const mensajes = err.response.data.respuesta;
          for (const key in mensajes) {
            this.$data.errors.push(...mensajes[key]);
          }
        });
    },
  },
  computed: {
    activ() {
      return this.$data.activos == true ? "Activo" : "Inactivo";
    },
    marcaSort() {
      return this.marca?.sort((a, b) => a.nombre.localeCompare(b.nombre));
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.text {
  word-break: break-word;
}
td {
  text-align: left;
}
.centerheight {
  text-align: center !important;
}
</style>
