<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1">
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro
            </v-card-title>
            <v-row>
              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                  label="Nombre"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="email"
                  color="primary"
                  dense
                  placeholder="Email"
                  label="Email"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-text-field
                  v-model="ids"
                  color="primary"
                  placeholder="ID"
                  clearable
                  label="ID"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-text-field
                  v-model="formatedRut"
                  color="primary"
                  placeholder="Rut"
                  label="Rut"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-container class="pa-0 ma-0" fluid>
                  <div class="d-flex">
                    <!-- BOTONES FILTRAR COMPONENTE -->
                    <btns-filtro-component
                      @filterData="filtrarDatos2"
                      @resetFilter="restablecer"
                    >
                    </btns-filtro-component>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center mt-5">
              <base-material-card
                icon="mdi-account-group"
                title="Clientes"
                class="py-3"
                color="primary"
                :showBtn="{ export: true, add: true }"
                @onExportBtn="exportarCliente"
                @onAddBtn="agregarCliente"
              >
                <v-data-table
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.rut | rutFilter }}</td>
                        <td>{{ item.created | moment("DD-MM-YYYY") }}</td>
                        <td>{{ item.email }}</td>

                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verCliente(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red"
                                @click="quitarRelacionCliente(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-autorenew-off</v-icon
                              >
                            </template>
                            <span>Quitar Relación</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
              <v-row v-if="errors.length > 0">
                <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                  <div v-if="errors.length > 0" class="">
                    <v-alert
                      dense
                      style="padding: 8px"
                      text
                      type="error"
                      dismissible
                      v-for="(err, i) in errors"
                      :key="i"
                    >
                      <strong>{{ err }}</strong>
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="verclients" persistent max-width="800">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="verclients = false"> x </v-btn>
        <v-card-title>
          <h3>Buscar Clientes</h3>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="ID"
                v-model="buscarc_id"
                placeholder="ID"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="E-mail"
                v-model="buscarc_email"
                placeholder="E-mail"
                clearable
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="Rut"
                v-model="buscarc_rut"
                placeholder="Rut"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="Nombre"
                v-model="buscarc_nom"
                placeholder="Nombre"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" lg="4" sm="6" cols="12" class="text-center">
              <v-btn
                dense
                block
                rounded
                color="secondary"
                @click="buscarcCliente"
                >Buscar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="datosCClientes.length > 0">
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <v-data-table
                calculate-widths
                dense
                no-data-text="No existen registros en tu cuenta"
                :headers="headersbuscarCClientes"
                :items="datosCClientes"
                :options.sync="options3"
                :server-items-length="totalDesserts3"
                :loading="loading3"
                class="elevation-1"
                :footer-props="{
                  itemsPerPageOptions: [5, 10],
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <td>{{ item.id }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.rut | rutFilter }}</td>
                      <td>
                        {{
                          item.activo != null
                            ? item.activo == 1
                              ? "Si"
                              : "No"
                            : "-"
                        }}
                      </td>
                      <td>{{ item.created | moment("DD-MM-YYYY") }}</td>
                      <td>{{ item.email }}</td>

                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="info"
                              @click="registrarProveedorCliente(item.id)"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-link</v-icon
                            >
                          </template>
                          <span>Relacionar</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-row v-if="errors2.length > 0">
                <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                  <div v-if="errors2.length > 0" class="">
                    <v-alert
                      dense
                      style="padding: 8px"
                      text
                      type="error"
                      dismissible
                      v-for="(err, i) in errors2"
                      :key="i"
                    >
                      <strong>{{ err }}</strong>
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");
import { ValidationProvider, extend } from "vee-validate";
import { rutValidator } from "vue-dni";

export default {
  name: "SubEditarClientesProveedores",
  props: ["id"],
  data: () => ({
    datos: [],
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    errors2: [],
    errors: [],
    rut: "",
    nombre: null,
    email: null,
    ids: null,
    loading: true,
    options: {},
    totalDesserts3: 0,
    desserts3: [],
    loading3: false,
    options3: {},
    buscarc_id: null,
    buscarc_nom: null,
    buscarc_rut: null,
    buscarc_email: null,
    verclients: false,
    datosCClientes: [],
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "center",
        value: "email",
        sortable: false,
      },
      {
        text: "Ver",
        align: "center",
        value: "accion",
        sortable: false,
      },
      {
        text: "Quitar Rel",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],
    headersbuscarCClientes: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Activo",
        align: "center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "center",
        value: "email",
        sortable: false,
      },
      {
        text: "Relacionar",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    upc: "",
    order: undefined,
  }),
  async beforeMount() {
    await this.filtrarDatos();
  },
  methods: {
    restablecer() {
      this.$data.ids = "";
      this.$data.rut = "";
      this.$data.nombre = "";
      this.$data.email = "";

      this.filtrarDatos2();
    },
    quitarRelacionCliente(id_cliente) {
      API.quitarRelacionClientes(
        this.$store.state.token,
        id_cliente,
        this.$props.id
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          this.filtrarDatos();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    agregarCliente() {
      this.$data.verclients = true;
    },
    registrarProveedorCliente(id_cliente) {
      API.relacionarProveedor(
        this.$props.id,
        this.$store.state.token,
        id_cliente
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Registrado",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.dialog = false;
          this.$data.dialogRegistrar = false;
          this.$data.verclients = false;

          this.filtrarDatos();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    buscarcCliente() {
      this.$data.loading = true;
      var rut;
      if (this.$data.buscarc_rut != undefined) {
        var r = this.$data.buscarc_rut.split(".").join("");
        var r2 = r.split("-").join("");
        rut = r2;
      } else {
        rut = "";
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options3;
      API.buscarcClientesProveedor(
        this.$store.state.token,
        this.$data.buscarc_id != undefined ? this.$data.buscarc_id : "",
        this.$data.buscarc_email != undefined ? this.$data.buscarc_email : "",
        rut,
        this.$data.buscarc_nom != undefined ? this.$data.buscarc_nom : "",
        page
      )
        .then((res) => {
          this.$data.errors2 = [];
          this.$data.loading = false;
          this.$data.datosCClientes = res.data.respuesta.data;
          this.$data.totalDesserts3 = res.last_page * itemsPerPage;
          if (this.$data.datosCClientes.length <= 0) {
            this.$data.errors2.push("Cliente no encontrado");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    exportarCliente() {
      EventBus.$emit("cargando", true);
      API.exportarProdClientes(this.$props.id, this.$store.state.token)
        .then((res) => {
          window.open(res.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    editarCliente(id) {
      this.$router.push("/admin/editar-cliente/" + id);
    },
    verCliente(id) {
      this.$router.push({
        path: "/admin/ver-cliente/" + id,
      });
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              API.productosClientes(
                this.$store.state.user.id,
                this.$store.state.token,
                "",
                "",
                "",
                "",
                ""
              )
                .then((res) => {
                  this.$data.datos = res.data.productos;
                })
                .catch((err) => {
                  console.log(err);
                });
              Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let ids;
      this.$data.ids != undefined ? (ids = this.$data.ids) : (ids = "");

      var rut;
      if (this.$data.rut != undefined) {
        var r = this.$data.rut.split(".").join("");
        var r2 = r.split("-").join("");
        rut = r2;
      } else {
        rut = "";
      }

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let order = "id";
      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      API.listarClientesProveedores(
        this.$store.state.token,
        this.$props.id,
        ids,
        email,
        rut,
        nombre,
        page
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cliente no encontrado");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    filtrarDatos2() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let ids;
      this.$data.ids != undefined ? (ids = this.$data.ids) : (ids = "");

      var rut;
      if (this.$data.rut != undefined) {
        var r = this.$data.rut.split(".").join("");
        var r2 = r.split("-").join("");
        rut = r2;
      } else {
        rut = "";
      }

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let order = "id";
      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      this.options.page = 1;
      API.listarClientesProveedores(
        this.$store.state.token,
        this.$props.id,
        ids,
        email,
        rut,
        nombre,
        page
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cliente no encontrado");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    exportarClientes() {
      EventBus.$emit("cargando", true);
      API.exportarClientes(this.$store.state.token)
        .then((res) => {
          console.log(res);
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
    formatRut(rut) {
      rut = rut.replace(/[^k0-9]/gim, "").split("");
      return rut.length > 1
        ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
            rut[rut.length - 1]
          }`
        : rut.join("");
    },
  },
  computed: {
    formatedRut: {
      get() {
        return this.formatRut(this.rut);
      },
      set(modifiedValue) {
        this.rut = modifiedValue;
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    verclients(v) {
      v == false ? (this.$data.datosCClientes = []) : "";
    },
    ids(v) {
      if (v == null) {
        this.$data.ids = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    rut(v) {
      if (v == null) {
        this.$data.rut = "";
        this.filtrarDatos2();
      }
    },
    email(v) {
      if (v == null) {
        this.$data.email = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
  },
};
</script>
<style lang="scss"></style>
