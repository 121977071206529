<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col md="8" offset-lg="2" offset-md="2" offset-sm="0" lg="8" sm="12">
        <base-material-card
          icon="mdi-account-group"
          title="Crear Nuevo Cliente"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row v-if="errors.length > 0">
              <v-col cols="12">
                <div v-if="errors.length > 0" class="">
                  <v-alert
                    dense
                    style="padding: 8px"
                    text
                    type="error"
                    dismissible
                    v-for="(err, i) in errors"
                    :key="i"
                  >
                    <strong>{{ err }}</strong>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12">
                <v-form ref="valid" v-model="valid" autocomplete="off">
                  <v-autocomplete
                    type="text"
                    :rules="rules.notEmpty"
                    v-model.trim="cliente.plan_id"
                    :items="filter.plan"
                    color="primary"
                    item-text="nombre"
                    item-value="id"
                    autocomplete="off"
                    placeholder="Seleccione un plan"
                    clearable
                    outlined
                    label="Planes"
                  ></v-autocomplete>
                  <v-text-field
                    v-model.trim="cliente.email"
                    label="E-mail"
                    :rules="rules.emailRules"
                    required
                    placeholder="E-mail"
                    clearable
                    outlined
                  ></v-text-field>
                  <v-text-field
                    autocomplete="new-password"
                    v-model.trim="cliente.password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    label="Contraseña"
                    :rules="rules.passwordRules"
                    required
                    placeholder="Contraseña"
                    outlined
                  >
                  </v-text-field>
                  <v-text-field
                    :rules="rules.notEmpty"
                    v-model.trim="cliente.nombre"
                    label="Nombre"
                    clearable
                    outlined
                    placeholder="Nombre"
                  ></v-text-field>

                  <v-text-field
                    :rules="rules.rut"
                    type="text"
                    label="Rut"
                    maxlength="12"
                    outlined
                    placeholder="Rut"
                    v-model="formatedRut"
                  ></v-text-field>

                  <v-switch
                    v-model="activo"
                    class="ma-2"
                    :label="activ"
                  ></v-switch>

                  <div class="d-flex">
                    <btn-volver />

                    <v-spacer />

                    <v-hover v-slot:default="{ hover }" open-delay="200">
                      <v-btn
                        :color="hover ? 'secondaryDark' : 'secondary'"
                        rounded
                        class="text-center"
                        :elevation="hover ? 0 : 6"
                        :disabled="!valid"
                        @click="registrarCliente()"
                        >Guardar
                      </v-btn>
                    </v-hover>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import { isRutValid } from "@/utils";

export default {
  name: "crear-cliente",
  title: "Admin crear cliente - Onestock",
  data: () => ({
    showPassword: false,
    password: "",
    errors: [],
    valid: false,
    activo: true,
    cliente: {
      plan_id: "",
      email: "",
      password: "",
      nombre: "",
      rut: "",
      avatar: "",
      activo: 1,
    },
    rut: "",
    avatar: null,
    filter: {
      plan: null,
    },
    rules: {
      notEmpty: [(v) => !!v || "Este campo es requerido"],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Ingrese un e-mail válido"],
      passwordRules: [(v) => !!v || "La contraseña es requerida"],
      rut: [
        (v) => !!v || `El Rut es requerido`,
        (v) => isRutValid(v) || `Rut inválido`,
      ],
    },
  }),
  created() {
    this.cargarValid();
    console.log(this.valid);
  },
  async beforeMount() {
    this.cargarValid();
    await API.listarPlanesAdmin2(this.$store.state.token)
      .then((res) => {
        this.$data.filter.plan = res.data.respuesta.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    cargarValid() {
      this.valid = false;
      console.log(this.valid);
    },
    formatRut(rut) {
      rut = rut.replace(/[^k0-9]/gim, "").split("");
      return rut.length > 1
        ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
            rut[rut.length - 1]
          }`
        : rut.join("");
    },
    goback() {
      this.$router.go(-1);
    },
    registrarCliente() {
      if (this.$refs.valid.validate()) {
        EventBus.$emit("cargando", true);
        this.$data.errors = [];

        if (this.$data.rut != undefined) {
          var r = this.$data.rut.split(".").join("");
          var r2 = r.split("-").join("");
          this.$data.cliente.rut = r2;
        }

        if (this.$refs.valid.validate()) {
          API.crearClienteAdmin(this.$store.state.token, this.$data.cliente)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Acción exitosa",
                showConfirmButton: false,
                timer: 1500,
              });
              EventBus.$emit("cargando", false);
              setTimeout(() => {
                this.$router.push("/admin/clientes");
              }, 2000);
              //console.log(res)
            })
            .catch((err) => {
              EventBus.$emit("cargando", false);
              const mensajes = err.response.data.respuesta;
              for (const key in mensajes) {
                this.$data.errors.push(...mensajes[key]);
              }
              Swal.fire({
                icon: "error",
                title: err.response.data.mensaje,
                showConfirmButton: false,
                timer: 2500,
              });
            });
        }
      }
    },
  },
  computed: {
    activ() {
      return this.$data.activo == true ? "Activo" : "Inactivo";
    },
    formatedRut: {
      get() {
        return this.formatRut(this.rut);
      },
      set(modifiedValue) {
        this.rut = modifiedValue;
        console.log("modifiedValue", modifiedValue);
      },
    },
  },
  watch: {
    activo(v) {
      v == true
        ? (this.$data.cliente.activo = 1)
        : (this.$data.cliente.activo = 0);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style scoped>
.list-style {
  list-style: none;
}
.ocultar-icono >>> .v-input__prepend-outer {
  display: none;
}
</style>
