<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0 py-4">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro
            </v-card-title>
            <v-row>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model="pago"
                  color="primary"
                  hide-details
                  placeholder="N° de Pago"
                  clearable
                  outlined
                  dense
                  label="N° de Pago"
                ></v-text-field>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="nombre"
                  color="primary"
                  hide-details
                  placeholder="Cliente"
                  clearable
                  outlined
                  dense
                  item-text="nombre"
                  item-value="id"
                  label="Cliente"
                  :items="clientes"
                ></v-autocomplete>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="desde"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  label="Desde"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      v-model="desde"
                      label="Desde"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="desde"
                    type="month"
                    no-title
                    scrollable
                    locale="cl-es"
                    color="primary"
                  >
                    <v-btn text color="primary" @click="menu = false"
                      >Cancelar</v-btn
                    >
                    <v-btn text color="secondary" @click="$refs.menu.save(desde)"
                      >Aceptar</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="hasta"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  label="Hasta"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      v-model="hasta"
                      label="Hasta"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="hasta"
                    type="month"
                    no-title
                    scrollable
                    locale="cl-es"
                    color="primary"
                    label="Hasta"
                  >
                    <v-btn text color="primary" @click="menu2 = false"
                      >Cancelar</v-btn
                    >
                    <v-btn text color="secondary" @click="$refs.menu2.save(hasta)"
                      >Aceptar</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="pagado"
                  :items="estadoac"
                  color="primary"
                  item-text="text"
                  item-value="id"
                  placeholder="Estado"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Estado"
                ></v-autocomplete>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
                <!-- FIN BOTONES FILTRAR COMPONENTE -->
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- INICIO TABLA -->
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-currency-usd"
                title="Cobros"
                class="py-3"
                color="primary"
              >
                <v-data-table
                  no-data-text="no_row_data"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.cliente.nombre }}</td>
                        <td>{{ item.total_peticiones }}</td>
                        <td>{{ item.total_cobro | currency }}</td>
                        <td>
                          {{ item.fecha_inicio_periodo | moment("DD-MM-YYYY") }}
                        </td>
                        <td>
                          {{
                            item.fecha_termino_periodo | moment("DD-MM-YYYY")
                          }}
                        </td>
                        <td>{{ item.pagado == 1 ? "Si" : "No" }}</td>
                        <td>{{ item.created | moment("DD-MM-YYYY") }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verCobros(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom v-if="item.pagado == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="finalizarCobro(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-currency-usd-off</v-icon
                              >
                            </template>
                            <span>Finalizar Cobro</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red darken-2 ma-1"
                                @click="reversarCobro(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-cancel</v-icon
                              >
                            </template>
                            <span>Restablecer Cobro</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
              <div v-if="errors.length > 0" class="pa-3">
                <ul class="list-style p-0 text-center">
                  <li v-for="(err, i) in errors" :key="i" class="red--text">
                    {{ err }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <div class="my-10 text-left">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

var moment = require("moment");

export default {
  name: "cobros",
  title: "Admin cobros - Onestock",
  data: () => ({
    datos: [],
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    clientes: [],
    menu: false,
    menu2: false,
    errors: [],
    headers: [
      {
        text: "N° de pago",
        align: "Center",
        sortable: true,
        value: "cliente_id",
      },
      {
        text: "Cliente",
        align: "Center",
        value: "created",
        sortable: false,
      },
      {
        text: "Total peticiones",
        align: "Center",
        value: "fecha_inicio_periodo",
        sortable: false,
      },
      {
        text: "Total cobros",
        align: "Center",
        value: "fecha_inicio_periodo",
        sortable: false,
      },
      {
        text: "Inicio periodo",
        align: "Center",
        value: "fecha_pagado",
        sortable: false,
      },
      {
        text: "Fin periodo",
        align: "Center",
        value: "fecha_termino_periodo",
        sortable: false,
      },
      {
        text: "Estado",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "Center",
        value: "observaciones",
        sortable: false,
      },
      {
        text: "Ver",
        align: "Center",
        value: "acciones",
        sortable: false,
      },
      {
        text: "Finalizar cobro",
        align: "Center",
        value: "acciones",
        sortable: false,
      },
    ],
    filter: {
      desde: [],
      hasta: [],
      pagado: [],
    },
    desde: "",
    hasta: "",
    pagado: "",
    nombre: "",
    pago: "",
    estadoac: [
      {
        id: 0,
        text: "No",
      },
      {
        id: 1,
        text: "Sí",
      },
    ],
  }),
  beforeMount() {
    this.listarclientes();
    this.filtrarDatos();
  },
  methods: {
    restablecer() {
      this.$data.desde = "";
      this.$data.hasta = "";
      this.$data.nombre = "";
      this.$data.pagado = "";
      this.$data.pago = "";
      this.filtrarDatos2();
    },
    filtrarDatos() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      let pago;
      this.$data.pago != undefined ? (pago = this.$data.pago) : (pago = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let desde;
      this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

      let hasta;
      this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

      let pagado;
      this.$data.pagado != undefined
        ? (pagado = this.$data.pagado)
        : (pagado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }

      API.listarCobrosAdmin(
        this.$store.state.token,
        pago,
        nombre,
        desde,
        hasta,
        pagado,
        order,
        direction,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
          this.$data.datos = res.respuesta.data;
          this.$data.total = res.respuesta.total;
          this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cobros no encontrados");
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error. intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      let pago;
      this.$data.pago != undefined ? (pago = this.$data.pago) : (pago = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let desde;
      this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

      let hasta;
      this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

      let pagado;
      this.$data.pagado != undefined
        ? (pagado = this.$data.pagado)
        : (pagado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;
      API.listarCobrosAdmin(
        this.$store.state.token,
        pago,
        nombre,
        desde,
        hasta,
        pagado,
        order,
        direction,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
          this.$data.datos = res.respuesta.data;
          this.$data.total = res.respuesta.total;
          this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cobros no encontrados");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    verCobros(id) {
      this.$router.push("/admin/ver-cobros/" + id);
    },
    finalizarCobro(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          text: "title-class",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Desea continuar?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, continuar!",
          cancelButtonText: "No!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            Swal.fire({
              customClass: {
                confirmButton:
                  "v-btn v-btn--contained v-size--default v-application primary bgsucces",
                cancelButton:
                  "v-btn  theme--light v-size--default v-application primary bgcancel",
                title: "title-class",
                input: "input-class",
              },
              title: "Agregue un comentario para finalizar",
              input: "textarea",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "finalizar",
              showLoaderOnConfirm: true,
              reverseButtons: true,
              preConfirm: (observacion) => {
                API.finalizarCobrosAdmin(
                  this.$store.state.token,
                  id,
                  observacion
                )
                  .then((res) => {
                    Swal.fire({
                      icon: "success",
                      title: `${res.mensaje}`,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.filtrarDatos();
                  })
                  .catch((err) => {
                    Swal.fire({
                      icon: "error",
                      title: "Error, intente de nuevo",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
            });
          }
        });
    },
    reversarCobro(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          text: "title-class",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Desea continuar",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, continuar!",
          cancelButtonText: "No!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            Swal.fire({
              customClass: {
                confirmButton:
                  "v-btn v-btn--contained v-size--default v-application primary bgsucces",
                cancelButton:
                  "v-btn  theme--light v-size--default v-application primary bgcancel",
                title: "title-class",
                input: "input-class",
              },
              title: "Indique el motivo para restablecer",
              input: "textarea",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Restablecer",
              showLoaderOnConfirm: true,
              reverseButtons: true,
              preConfirm: (motivo) => {
                API.reversarCobrosAdmin(this.$store.state.token, id, motivo)
                  .then((res) => {
                    Swal.fire({
                      icon: "success",
                      title: `${res.mensaje}`,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.filtrarDatos();
                  })
                  .catch((err) => {
                    Swal.fire({
                      icon: "error",
                      title: "Error, intente de nuevo",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
              },
            });
          }
        });
    },
    listarclientes() {
      API.listarClientesAdminCobros(this.$store.state.token)
        .then((res) => {
          this.$data.clientes = res.respuesta.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos();
      }
    },
    desde(v) {
      if (v == null) {
        this.$data.desde = "";
        this.filtrarDatos();
      }
    },
    hasta(v) {
      if (v == null) {
        this.$data.hasta = "";
        this.filtrarDatos();
      }
    },
    pago(v) {
      if (v == null) {
        this.$data.pago = "";
        this.filtrarDatos();
      }
    },
    pagado(v) {
      if (v == null) {
        this.$data.pagado = "";
        this.filtrarDatos();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss">
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > th,
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  text-align: center;
}
</style>
