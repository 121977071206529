<template>
  <v-container fluid class="ma-0 px-4">
    <info-masivos-component
      iconAvatar="mdi-truck-check"
      titleCard="Actualización Stock Masivo Proveedores"
      :loading="loading"
      @onDownloadExcelBtn="exportarCSV"
      @onUploadExcelBtn="cargarExcel"
      :responseApi="responseApi"
    />
  </v-container>
</template>

<script>
import API from "@/api";
import { EventBus } from "@/main.js";
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";

export default {
  name: "actualizarStockMasivo-component",
  title: "Actualizar stock masiva proveedores - Onestock",

  // props: {},
  data: function () {
    return {
      responseApi: {},
      loading: true,
    };
  },
  // computed: {},
  methods: {
    exportarCSV() {
      EventBus.$emit("cargando", true);
      API.exportarformatoCsv(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
    async cargarExcel(excel) {
      let formData = new FormData();
      formData.append("archivo", excel);

      await API.actualizarformatoCsv(this.$store.state.token, formData)
        .then((res) => {
          this.$data.responseApi = res;
        })
        .catch((err) => {
          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  // watch: {},
  components: {
    "info-masivos-component": InfoMasivosComponent,
  },
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped></style>
