<template>
    <v-container>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1">
            <v-card class="px-3 pb-0">
                <v-card-title class="pl-0 pt-2">
                <v-icon>mdi-magnify</v-icon>
                Filtro Clientes
                </v-card-title>
                <v-row>
                <v-col sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model.trim="nombre"
                    color="primary"
                    placeholder="Nombre"
                    clearable
                    label="Nombre"
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model.trim="email"
                    color="primary"
                    dense
                    placeholder="E-mail"
                    label="E-mail"
                    clearable
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col sm="3" cols="12" class="text-center">
                    <v-text-field
                    v-model.trim="ids"
                    color="primary"
                    placeholder="ID"
                    clearable
                    label="ID"
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col sm="3" cols="12" class="text-center">
                    <v-text-field
                    v-model.trim="formatedRut"
                    color="primary"
                    placeholder="Rut"
                    label="Rut"
                    clearable
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col sm="6" cols="12" class="text-center">
                    <!-- BOTONES FILTRAR COMPONENTE -->
                    <btns-filtro-component
                    @filterData="filtrarDatos2"
                    @resetFilter="restablecer"
                    ></btns-filtro-component>
                    <!-- FIN BOTONES FILTRAR COMPONENTE -->
                </v-col>
                </v-row>
            </v-card>
            </v-container>
            <v-container fluid class="pa-1">
            <v-row>
                <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
                <base-material-card
                    icon="mdi-account-group"
                    title="Clientes"
                    color="primary"
                    :showBtn="{ export: true }"
                    @onExportBtn="exportarCliente"
                >
                    <v-data-table
                    calculate-widths
                    no-data-text="No existe registros en tu cuenta"
                    :headers="headers"
                    :items="datos"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [5, 10], // -> Add this example
                        showFirstLastPage: true,
                        disablePagination: loading,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text': 'Registros por página',
                    }"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td>{{ item.id }}</td>
                            <!-- <td><img :src="item.avatar" width="30px" /></td> -->
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.rut | rutFilter }}</td>
                            <td>
                            {{
                                item.activo != null
                                ? item.activo == 1
                                    ? "Si"
                                    : "No"
                                : "-"
                            }}
                            </td>
                            <td>{{ item.created | moment("DD-MM-YYYY") }}</td>
                            <td>{{ item.email }}</td>

                            <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="secondary"
                                    medium
                                    block
                                    @click="verCliente(item.id)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-eye-arrow-right
                                </v-icon>
                                </template>
                                <span>Ver</span>
                            </v-tooltip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </base-material-card>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";

    import API from "@/api";
    import Swal from "sweetalert2";
    import { EventBus } from "@/main.js";
    var moment = require("moment");

    export default {
    name: "SubClientesProveedores",
    props: ["id"],
    data: () => ({
        datos: [],
        dialog: false,
        rut: "",
        nombre: null,
        email: null,
        ids: null,
        no_row_data: "No hay datos",
        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        rut: "",
        headers: [
        {
            text: "ID",
            align: "center",
            sortable: true,
            value: "id",
        },
        // {
        //     text: "Avatar",
        //     align: "center",
        //     value: "avatar",
        //     sortable: false,
        // },
        {
            text: "Nombre",
            align: "center",
            value: "nombre",
            sortable: false,
        },
        {
            text: "Rut",
            align: "center",
            value: "rut",
            sortable: false,
        },
        {
            text: "Activo",
            align: "center",
            value: "activo",
            sortable: false,
        },
        {
            text: "Fecha creación",
            align: "center",
            value: "created",
            sortable: false,
        },
        {
            text: "E-mail",
            align: "center",
            value: "email",
            sortable: false,
        },
        {
            text: "Ver",
            align: "center",
            value: "accion",
            sortable: false,
        },
        ],
        filter: {
        mi_id: [],
        marca: [],
        nombre: [],
        ean: [],
        upc: [],
        estado: [
            {
            id: 1,
            estado: "Si",
            valor: 1,
            },
            {
            id: 2,
            estado: "No",
            valor: 0,
            },
        ],
        },
        mi_id: "",
        marca: "",
        nombre: "",
        ean: "",
        upc: "",
        order: undefined,
    }),
    async beforeMount() {
        await this.filtrarDatos();
    },
    methods: {
        restablecer() {
        this.$data.ids = "";
        this.$data.rut = "";
        this.$data.nombre = "";
        this.$data.email = "";

        this.filtrarDatos2();
        },
        exportarCliente() {
        EventBus.$emit("cargando", true);
        API.exportarProdClientes(this.$props.id, this.$store.state.token)
            .then((res) => {
            window.open(res.respuesta);
            EventBus.$emit("cargando", false);
            })
            .catch((err) => {
            EventBus.$emit("cargando", false);
            console.log(err);
            });
        },
        editarCliente(id) {
        console.log(id);
        this.$router.push("/admin/editar-cliente/" + id);
        },
        verCliente(id) {
        this.$router.push("/admin/ver-cliente/" + id);
        },
        relacionarProducto(id_prod) {
        Swal.fire({
            customClass: {
            confirmButton:
                "v-btn v-btn--contained v-size--default v-application primary bgsucces",
            cancelButton:
                "v-btn  theme--light v-size--default v-application primary bgcancel",
            title: "title-class",
            input: "input-class",
            },
            title: "Ingrese su ID",
            input: "text",
            inputAttributes: {
            autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Registrar",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: (login) => {
            API.relacionarProducto(id_prod, login, this.$store.state.token)
                .then((res) => {
                API.productosClientes(
                    this.$store.state.user.id,
                    this.$store.state.token,
                    "",
                    "",
                    "",
                    "",
                    ""
                )
                    .then((res) => {
                    this.$data.datos = res.data.productos;
                    })
                    .catch((err) => {
                    console.log(err);
                    });
                Swal.fire({
                    icon: "success",
                    title: "Datos Relacionados",
                    showConfirmButton: false,
                    timer: 1500,
                });
                })
                .catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Error al relacionar, intente más tarde",
                    showConfirmButton: false,
                    timer: 1500,
                });
                });
            },
        });
        //   }
        //  })
        },
        quitarRelacionProducto(id_prod) {
        API.quitarRelacionProducto(
            id_prod,
            this.$store.state.user.id,
            this.$store.state.token
        )
            .then((res) => {
            Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
            });

            this.filtrarDatos();
            })
            .catch((err) => {
            console.log(err);
            });
        },
        filtrarDatos() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let ids;
        this.$data.ids != undefined ? (ids = this.$data.ids) : (ids = "");

        var rut;
        if (this.$data.rut != undefined) {
            var r = this.$data.rut.split(".").join("");
            var r2 = r.split("-").join("");
            rut = r2;
        } else {
            rut = "";
        }

        let email;
        this.$data.email != undefined ? (email = this.$data.email) : (email = "");

        let nombre;
        this.$data.nombre != undefined
            ? (nombre = this.$data.nombre)
            : (nombre = "");

        let order = "id";
        this.$data.order != undefined
            ? (order = this.$data.order)
            : (order = "id");

        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
        API.listarClientesProveedores(
            this.$store.state.token,
            this.$props.id,
            ids,
            email,
            rut,
            nombre
        )
            .then((res) => {
            console.log("listarClientesProveedores", res);
            this.$data.loading = false;
            this.$data.datos = res.data.data;
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;
            EventBus.$emit("cargando", false);
            })
            .catch((err) => {
            console.log(err);
            this.$data.loading = false;
            EventBus.$emit("cargando", false);
            });
        },
        filtrarDatos2() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let ids;
        this.$data.ids != undefined ? (ids = this.$data.ids) : (ids = "");

        var rut;
        if (this.$data.rut != undefined) {
            var r = this.$data.rut.split(".").join("");
            var r2 = r.split("-").join("");
            rut = r2;
        } else {
            rut = "";
        }

        let email;
        this.$data.email != undefined ? (email = this.$data.email) : (email = "");

        let nombre;
        this.$data.nombre != undefined
            ? (nombre = this.$data.nombre)
            : (nombre = "");

        let order = "id";
        this.$data.order != undefined
            ? (order = this.$data.order)
            : (order = "id");

        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
        this.options.page = 1;
        API.listarClientesProveedores(
            this.$store.state.token,
            this.$props.id,
            ids,
            email,
            rut,
            nombre,
            page
        )
            .then((res) => {
            this.$data.loading = false;
            this.$data.datos = res.data.data;
            console.log("datos", this.$data.datos);
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;
            EventBus.$emit("cargando", false);
            })
            .catch((err) => {
            console.log(err);
            this.$data.loading = false;
            EventBus.$emit("cargando", false);
            });
        },
        formatRut(rut) {
        rut = rut.replace(/[^k0-9]/gim, "").split("");
        return rut.length > 1
            ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
                rut[rut.length - 1]
            }`
            : rut.join("");
        },
    },
    computed: {
        formatedRut: {
        get() {
            return this.formatRut(this.rut);
        },
        set(modifiedValue) {
            this.rut = modifiedValue;
        },
        },
    },
    watch: {
        options: {
        handler() {
            this.filtrarDatos();
        },
        deep: true,
        },
        ids(v) {
        if (v == null) {
            this.$data.ids = "";
            this.filtrarDatos2();
        }
        },
        nombre(v) {
        if (v == null) {
            this.$data.nombre = "";
            this.filtrarDatos2();
        }
        },
        rut(v) {
        if (v == null) {
            this.$data.rut = "";
            this.filtrarDatos2();
        }
        },
        email(v) {
        if (v == null) {
            this.$data.email = "";
            this.filtrarDatos2();
        }
        },
    },
    components: {
        BtnsFiltroComponent,
    },
};
</script>
<style lang="scss"></style>
