<template>
  <v-container>
    <v-row>
    <v-col
        lg="12"
        cols="12"
        >
                 <v-container class="pa-1" fluid>
                     <v-card class="px-3 pb-0">
                    <v-row>
                        <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                            <base-material-card
                                icon="mdi-align-vertical-bottom"
                                title="Información del Producto"
                                color="primary"
                                >
                                <v-container>
                                    <v-row>
                                        <v-col
                                            md="12"
                                            lg="12"
                                            sm="12"
                                            cols="12">
                                            <v-form  ref="form"
                                                v-model="valid"
                                                lazy-validation
                                                autocomplete="false"
                                            >
                                                <v-text-field
                                                v-model="producto_info.nombre"
                                                label="Nombre"
                                                :rules='rules.notEmpty'
                                                required
                                                clearable
                                                disabled
                                                ></v-text-field>
                                                <v-autocomplete
                                                    v-model="producto_info.marca_id"
                                                    :items="marca"
                                                    color="primary"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    placeholder="Marca"
                                                    clearable
                                                    disabled
                                                ></v-autocomplete>
                                                <v-text-field
                                                v-model="producto_info.upc"
                                                label="Upc"
                                                :rules='rules.upc'
                                                required
                                                clearable
                                                maxlength="12"
                                                counter
                                                disabled
                                                ></v-text-field>
                                                <v-text-field
                                                v-model="producto_info.ean"
                                                label="Ean"
                                                :rules='rules.ean'
                                                required
                                                clearable
                                                maxlength="13"
                                                counter
                                                disabled
                                                ></v-text-field>
                                                <v-text-field
                                                v-model="producto_info.codigo_interno"
                                                label="Codigo interno"
                                                :rules='rules.notEmpty'
                                                required
                                                clearable
                                                disabled
                                                ></v-text-field>
                                                   <v-text-field
                                v-model="prod.stock"
                                label="Stock"
                                :rules='rules.notEmpty'
                                required
                                clearable
                                ></v-text-field>
                                                <v-file-input 
                                                v-show="false"
                                                show-size 
                                                accept="*" 
                                                v-model="imagen" 
                                                label="Cargar imagen (opcional)"
                                                :rules='rules.notEmpty'
                                                disabled
                                                ></v-file-input>
                                                <v-switch v-model="activos" class="ma-2" :label="activ" disabled></v-switch>
                                                <v-row v-if="errors.length > 0">
                                                <v-col cols="12"> 
                                                <div v-if="errors.length > 0" class="">
                                                    <v-alert 
                                                        dense
                                                        style="padding:8px "
                                                        text
                                                        type="error"
                                                        dismissible
                                                            v-for="(err ,i) in errors" :key="i" 
                                                        >
                                                        <strong>{{err}}</strong>
                                                    </v-alert>
                                                </div>
                                                </v-col>
                                            </v-row>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </base-material-card>
                        </v-col>
                        <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="text-center elevation-0 d-flex justify-center align-center"
                            style="cursor:pointer"
                            >
                            <div class="py-3 px-2 ">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-bind="attrs"
                                        v-on="on"
                                        :src="producto_info.imagen"
                                        width="100%"
                                        class=""
                                        @click="selectImage" />
                                        <input
                                        v-show="false"
                                        ref="fileInput"
                                        type="file"
                                        @input="pickFile"
                                        disabled>
                                </template>
                                <span>Editar Imagen</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="mb-3">
                            <v-btn 
                            v-if="bloqueo_boton==true"
                                color="primary"
                                class="text-center"
                                :disabled="true"
                                @click="actualizarStock()"
                                @keyup.enter="actualizarStock">
                                    Modificar Stock
                                </v-btn>
                                 <v-btn
                                 v-else
                                color="primary"
                                class="text-center"
                                :disabled="!valid"
                                @click="actualizarStock()"
                                @keyup.enter="actualizarStock">
                                    Modificar Stock
                                </v-btn>
                        </v-col>
                    </v-row>
                     </v-card>
                 </v-container>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600">
            <v-card class=" pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialog = false">
                    x
                </v-btn>
                <v-card-title class="py-5">
                </v-card-title>
                <base-material-card
                    icon="mdi-align-vertical-bottom"
                    title="Agregar nuevo proveedor"
                    class="px-5 py-3"
                    color="primary"
                    >
            </base-material-card>
        </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" persistent max-width="600">
            <v-card class=" pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialog2 = false">
                    x
                </v-btn>
                <v-card-title class="py-5">
                     
                </v-card-title>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'

export default {
    name: 'proveedor-editar-producto',
    title: 'Proveedor editar producto - Onestock',
    props: ['id', 'id_proveedor'],
     data: () => ({
        dialog:false,
        dialog2:false,
        activos:false,
        valid:null,
        valid2:null,
        producto_info:null,
        user:null,
        bloqueo_boton: false,
        marca_id:null,
        marca:null,
        imagen:null,
        errors:[],
        prod:{
            id_proveedor:null,
            sku:null,
            stock: 0,
        },
        cliente:{
            id_producto:null,
            id_interno:null
        },
        id_cliente:null,
        listar_clientes:[],
        cards:[],
        listar_proveedores:null,
      rules:{
            notEmpty:[
                v => !!v || 'El campo es requerido',
            ],
            ean: [
                v => !!v || 'Contraseña es requerida',
                v => v.length == 13 || 'Tienen que ser 13 Caracteres'
            ],
            upc: [
                v => !!v || 'Contraseña es requerida',
                v => v.length == 12 || 'Tienen que ser 12 Caracteres'
            ]
        }
    }),
    beforeMount(){
        
        this.verProducto()
    },
    methods:{
        editarCliente(id){
            this.$router.push('/admin/editar-cliente/'+id)
        },
        back(){
            this.$router.go(-1)
        },
        selectImage () {
            this.$refs.fileInput.click()
        },
        pickFile () {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
            let reader = new FileReader
            this.$data.imagen = file[0]
            reader.onload = e => {
                this.$data.producto_info.imagen = e.target.result
            }
            reader.readAsDataURL(file[0])
            this.$emit('input', file[0])
            }
        },
      verProducto () {
        EventBus.$emit('cargando', true)
        API.verProductoProveedor(this.$props.id, this.$store.state.token, this.$props.id_proveedor)
          .then((res) => {
            var idProveedor = this.$props.id
            EventBus.$emit('cargando', false)
            this.$data.producto_info = res.data.respuesta
            var stockProducto = 0
            var skuProducto = 0
            var idProducto = 0
            this.$data.producto_info.proveedores.forEach(function (element) {
              if (element.pivot.proveedor_id == idProveedor) {
                stockProducto = element.pivot.stock
                skuProducto = element.pivot.sku
                idProducto = element.pivot.producto_id
              }
            })
            this.$data.prod.id = idProducto
            this.$data.prod.stock = stockProducto
            this.$data.prod.sku = skuProducto
            this.$data.prod.id_proveedor = proveedor_id
            this.$data.activos = res.data.respuesta.activo == 1 ? true : false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      actualizarStock () {
        this.$data.bloqueo_boton = true
        this.$data.errors = []
        API.editarStockProveedor(this.$data.prod, this.$store.state.token, this.$props.id)
          .then(() => {
            this.$data.bloqueo_boton = false
            Swal.fire({
              icon: 'success',
              title: 'Datos actualizados',
              showConfirmButton: false,
              timer: 1500,
            })
            this.verProducto()
          })
          .catch((err) => {
            this.$data.bloqueo_boton = false
            Swal.fire({
              icon: 'error',
              title: 'Revise el formulario',
              showConfirmButton: false,
              timer: 1500,
            })
            let error = err.response.data
            Object.keys(error.mensaje).forEach(el => {
              if (el == 'marca_id') {
                this.$data.errors.push('Debe una Seleccionar una Marca')
                this.$data.producto_info.marca_id = null;
              }
              if (el == 'codigo_interno') {
                this.$data.producto_info.codigo_interno = null
              }
              if (el == 'ean') {
                this.$data.producto_info.ean = null
              }
              if (el == 'upc') {
                this.$data.producto_info.upc = null
              }
            })
          })
      },
    },
    computed:{
        activ(){
            return this.$data.activos == true ? 'Activo' : 'Inactivo'
        },
        stock_productos(){
            return this.$data.producto_info.proveedores
                .map((el)=>{
                    
                    return el.pivot.stock 
                })
                .reduce((acc,val)=>{
                    return acc + val
                })
        }
    },
    
}
</script>
<style lang="scss" >
.text{
    word-break: break-word;
}
td{
    text-align: left;
}
.centerheight{
  text-align: center !important
}
</style>