<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Planes
            </v-card-title>
            <v-row>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model="id"
                  color="primary"
                  placeholder="id"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="ID"
                ></v-text-field>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="ilimitado"
                  :items="filter"
                  color="primary"
                  item-text="text"
                  item-value="id"
                  placeholder="Ilimitado"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Ilimitado"
                ></v-autocomplete>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="ac"
                  :items="filter"
                  color="primary"
                  item-text="text"
                  item-value="id"
                  placeholder="Activo"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Activo"
                ></v-autocomplete>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
                <!-- FIN BOTONES FILTRAR COMPONENTE -->
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- INICIO TABLA -->
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-file-powerpoint-outline"
                title="Planes"
                class="py-3"
                color="primary"
                :registros="total"
                acciones="true"
                :enlaces="enlaces"
              >
                <v-data-table
                  no-data-text="no_row_data"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.limite_diario }}</td>
                        <td>{{ item.ilimitado == 1 ? "Si" : "No" }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>{{ item.precio_peticion | currency }}</td>
                        <td>{{ item.precio_sobre_consumo | currency }}</td>
                        <td>{{ item.activo == 1 ? "Si" : "No" }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verPlan(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver plan</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="editarPlan(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-lead-pencil</v-icon
                              >
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
              <div class="my-10 text-left">
                <btn-volver />
              </div>
              <div v-if="errors.length > 0" class="pa-3">
                <ul class="list-style p-0 text-center">
                  <li v-for="(err, i) in errors" :key="i" class="red--text">
                    {{ err }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de Cliente</h3>
        </v-card-title>
        <v-data-table
          dense
          no-data-text="No existen registros en tu cuenta"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
          :headers="headers2"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.rut }}</td>
                <td>{{ item.created }}</td>
                <td>{{ item.email }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

var moment = require("moment");

export default {
  name: "cobros",
  title: "Admin planes - Onestock",
  data: () => ({
    enlaces: [
      {
        text: "Crear nuevo",
        to: {
          name: "crear-plan",
        },
      },
    ],
    total: 0,
    datos: [],
    datos2: [],
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    errors: [],
    options: {},
    headers: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Límite diario",
        align: "Center",
        value: "limite_diario",
        sortable: false,
      },
      {
        text: "Ilimitado",
        align: "Center",
        value: "ilimitado",
        sortable: false,
      },
      {
        text: "Descripción",
        align: "Center",
        value: "descripcion",
        sortable: false,
      },
      {
        text: "Precio Petición",
        align: "Center",
        value: "precio_peticion",
        sortable: false,
      },
      {
        text: "Precio sobreconsumo",
        align: "Center",
        value: "precio_sobre_consumo",
        sortable: false,
      },
      {
        text: "Activo",
        align: "Center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Ver",
        align: "Center",
        value: "acciones",
        sortable: false,
      },
      {
        text: "Editar",
        align: "Center",
        value: "acciones",
        sortable: false,
      },
    ],
    filter: [
      {
        id: 0,
        text: "No",
      },
      {
        id: 1,
        text: "Sí",
      },
    ],
    id: null,
    nombre: null,
    ilimitado: null,
    ac: null,
    headers2: [
      {
        text: "ID",
        align: "Center",
        sortable: false,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "Center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Fecha",
        align: "Center",
        value: "created",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "Center",
        value: "email",
        sortable: false,
      },
    ],
  }),
  async beforeMount() {
    await this.filtrarDatos2();
  },
  methods: {
    restablecer() {
      this.$data.desde = "";
      this.$data.hasta = "";
      this.$data.nombre = "";
      this.$data.pagado = "";
      this.$data.pago = "";
      this.filtrarDatos2();
    },
    filtrarDatos() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let ilimitado;
      this.$data.ilimitado != undefined
        ? (ilimitado = this.$data.ilimitado)
        : (ilimitado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      let activo = "";
      if (this.$data.ac != null) {
        activo = this.$data.ac;
      }
      API.listarPlanesAdmin(
        this.$store.state.token,
        id,
        nombre,
        ilimitado,
        activo,
        order,
        direction,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
          this.$data.datos = res.respuesta.data;
          this.$data.total = res.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cliente no encontrado");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let ilimitado;
      this.$data.ilimitado != undefined
        ? (ilimitado = this.$data.ilimitado)
        : (ilimitado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      let activo = "";
      if (this.$data.ac != null) {
        activo = this.$data.ac;
      }
      this.options.page = 1;
      API.listarPlanesAdmin(
        this.$store.state.token,
        id,
        nombre,
        ilimitado,
        activo,
        order,
        direction,
        page,
        itemsPerPage
      )
        .then((res) => {
          console.log("listarPlanesAdmin", res);
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
          this.$data.datos = res.respuesta.data;
          this.$data.total = res.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Cliente no encontrado");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    verClientesPlan(plan) {
      this.$data.dialog = true;
      this.$data.datos2 = plan;
      this.$data.datos2.forEach((el) => {
        el.created = moment(el.created).format("DD-MM-YYYY");
      });
    },
    verPlan(id) {
      this.$router.push("/admin/ver-plan/" + id);
    },
    editarPlan(id) {
      this.$router.push("/admin/editar-plan/" + id);
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    id(v) {
      if (v == null) {
        this.$data.id = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ilimitado(v) {
      if (v == null) {
        this.$data.ilimitado = "";
        this.filtrarDatos2();
      }
    },
    ac(v) {
      if (v == null) {
        this.$data.ac = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss"></style>
