<template>
    <div class="d-flex align-center mb-16">
        <v-avatar
        class="mt-n6 ml-3 pa-8 text-center"
        height="90"
        color="primary"
        tile
        >
        <v-icon size="32" dark x-large v-text="iconAvatar" />
        </v-avatar>
        <h3 class="ml-10 my-6 greyDark--text" v-text="titleCard" />
    </div>
</template>

<script>
    export default {
    name: "HeadCardComponent",
    props: {
        iconAvatar:{
            type:String,
            default:"mdi-lightbulb"
        },
        titleCard:{
            type:String,
            default:"Creación/Edición Masiva"
        },
    },
    data: function () {
        return {};
    },
  // computed: {},
  //methods: {}
  // watch: {},
  // components: {},
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped></style>
