<template>
  <v-container fluid class="ma-0 px-0">
    <info-masivos-component
      iconAvatar="mdi-truck-check"
      titleCard="Actualización Stock Masivo Proveedores"
      :loading="loading"
      @onDownloadExcelBtn="onDownloadExcelBtn"
      @onUploadExcelBtn="onUploadExcelBtn"
      :responseApi="responseApi"
    />
  </v-container>
</template>

<script>
import API from "@/api";
import { EventBus } from "@/main.js";
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";

export default {
  name: "proveedores-productos-relacion-masiva",
  title: "Relación masiva proveedores productos - Onestock",

  props: ["id"],
  data: function () {
    return {
      responseApi: {},
      loading: true,
    };
  },
  // computed: {},
  methods: {
    onDownloadExcelBtn() {
      EventBus.$emit("cargando", true);
      API.obtenerArchivoFormatoProveedorProductoExcel(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
    async onUploadExcelBtn(excel){
        let formData = new FormData();
      formData.append("archivo", excel);

      await API.relacionProveedoresMasivaProductos(formData, this.$store.state.token, this.id)
        .then((res) => {
          this.$data.responseApi = res.data;
        })
        .catch((err) => {
          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  // watch: {},
  components: {
    InfoMasivosComponent
  },
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped></style>
