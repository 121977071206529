<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Productos
            </v-card-title>
            <v-row>
              <v-col sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="marca"
                  :items="marcaSort"
                  color="primary"
                  item-text="nombre"
                  item-value="id"
                  placeholder="Marca"
                  label="Marca"
                  clearable
                  outlined
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  outlined
                  label="Nombre"
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col sm="2" cols="12" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  label="ID Interno"
                  v-model="mi_id"
                  placeholder="ID Interno"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
              <v-col sm="2" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Upc"
                      hide-details
                      dense
                      outlined
                      v-model="upc"
                      color="primary"
                      placeholder="Upc"
                      clearable
                      maxlength="12"
                      :rules="rules.numeros"
                    ></v-text-field>
                  </template>
                  <span>Código Universal de Producto</span>
                </v-tooltip>
              </v-col>
              <v-col sm="2" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Ean"
                      hide-details
                      dense
                      outlined
                      v-model="ean"
                      color="primary"
                      placeholder="Ean"
                      clearable
                      maxlength="13"
                      :rules="rules.numeros"
                    ></v-text-field>
                  </template>
                  <span>Número de Artículo Europeo</span>
                </v-tooltip>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container fluid class="pa-1">
          <v-row>
            <v-col sm="12" cols="12" class="text-center py-0">
              <base-material-card
                icon="mdi-tag-multiple"
                title="Productos"
                class="py-3"
                color="primary"
                :registros="total"
                :acciones="false"
                relacionar="relacionar-masivamente"
                :enlaces="enlaces"
              >
                <v-data-table
                  calculate-widths
                  dense
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    disablePagination: loading,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.producto_info.id }}</td>
                        <td>
                          {{
                            item.producto_info.mi_id != null
                              ? item.producto_info.mi_id
                              : "-"
                          }}
                        </td>
                        <td>{{ item.producto_info.nombre }}</td>
                        <td>{{ item.producto_info.marca }}</td>
                        <td>
                          {{
                            item.producto_info.upc != null
                              ? item.producto_info.upc
                              : "-"
                          }}
                        </td>
                        <td>
                          {{
                            item.producto_info.ean != null
                              ? item.producto_info.ean
                              : "-"
                          }}
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondaryDark"
                                @click="
                                  verProveedores(item.detalle_proveedores)
                                "
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right-outline
                              </v-icon>
                            </template>
                            <span>Ver Proveedores</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verProducto(item.producto_info.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver Producto</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip
                            bottom
                            v-if="item.producto_info.mi_id == null"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="
                                  relacionarProducto(item.producto_info.id)
                                "
                                v-bind="attrs"
                                v-on="on"
                                >mdi-link</v-icon
                              >
                            </template>
                            <span>Relacionar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red"
                                @click="
                                  quitarRelacionProducto(item.producto_info.id)
                                "
                                v-bind="attrs"
                                v-on="on"
                                >mdi-autorenew-off</v-icon
                              >
                            </template>
                            <span>Quitar Relación</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
            <v-col cols="12" v-if="errors.length > 0" class="pb-2">
              <v-alert
                dense
                style="padding: 8px"
                text
                type="error"
                dismissible
                v-for="(err, i) in errors"
                :key="i"
              >
                <strong>{{ err }}</strong>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>
        <v-data-table
          dense
          no-data-text="No existe registros en tu cuenta"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
          :headers="headers2"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.direccion_comercial }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog2 = false"> x </v-btn>
        <v-card-title class="mb-2">
          <h3>Productos</h3>
        </v-card-title>
        <v-select
          v-model="produc"
          :items="prod"
          label="Productos"
          outline
          item-text="nombre"
          item-value="id"
          return-object
          outlined
          dense
        ></v-select>
        <v-btn color="primary" @click="relacionarProductoCliente()">
          Relacionar productos
        </v-btn>
      </v-card>
    </v-dialog>
    <btn-volver />
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

export default {
  name: "subProductos",
  props: ["id", "recargar", "accion"],
  data: () => ({
    enlaces: [
      {
        text: "Relacionar Masivamente",
        to: {
          name: "relacionar-masivamente-producto",
        },
      },
    ],
    dialog: false,
    dialog2: false,
    prod: [],
    produc: null,
    total: 0,
    totalDesserts: 0,
    desserts: [],
    options: {},
    loading: false,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Mi ID",
        align: "center",
        value: "mi_id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        width: "20%",
      },
      {
        text: "Marca",
        align: "center",
        value: "marca",
        width: "15%",
      },
      {
        text: "Upc",
        align: "center",
        value: "upc",
      },
      {
        text: "Ean",
        align: "center",
        value: "ean",
      },
      {
        text: "Ver Proveed.",
        align: "center",
        value: "proveedores",
      },
      {
        text: "Ver Producto",
        align: "center",
        value: "accion",
      },
      {
        text: "Acciones Relac.",
        align: "center",
        value: "accion",
      },
    ],
    headers2: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
      },
      {
        text: "Dirección",
        align: "center",
        value: "direccion_comercial",
      },
    ],
    datos: [],
    datos2: [],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    upc: "",
    errors: [],
    rules: {
      numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
    },
  }),
  async beforeMount() {
    await API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.filter.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.filtrarDatos();
    this.listarProductos();
  },
   computed: {
    marcaSort() {
      return this.filter.marca.sort((a, b) => a.nombre.localeCompare(b.nombre));
    },
  },
  methods: {
    activarRelacionar() {
      this.$data.dialog2 = true;
    },
    listarProductos() {
      API.listarClientesProductos(this.$store.state.token, this.$props.id)
        .then((res) => {
          this.$data.prod = res.productos.map((prod) => prod.producto_info);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    relacionarProductoCliente() {
      API.relacionarProducto(
        this.$data.produc.id,
        this.$data.produc.mi_id,
        this.$store.state.token,
        this.$props.id
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log(res);
          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    restablecer() {
      this.$data.mi_id = "";
      this.$data.marca = "";
      this.$data.nombre = "";
      this.$data.ean = "";
      this.$data.upc = "";
      this.filtrarDatos2();
    },
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportarProductoAdmin(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(errr);
        });
    },
    verProveedores(proveedores) {
      this.$data.datos2 = proveedores;
      this.$data.dialog = true;
    },
    verProducto(id) {
      this.$router.push({
        path: "/admin/ver-producto/" + id,
      });
    },
    async relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(
            id_prod,
            login,
            this.$store.state.token,
            this.$props.id
          )
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
              });
              this.filtrarDatos();
            })
            .catch((err) => {
              let error = err.response.data;
              Swal.fire({
                icon: "error",
                title: `El id_interno ingresado ya tiene relación con otro producto`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$props.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      API.productosClientes(
        this.$props.id,
        this.$store.state.token,
        direction,
        order,
        this.$data.mi_id != undefined ? this.$data.mi_id : "",
        this.$data.nombre != undefined ? this.$data.nombre : "",
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$emit("recargaProducto", false);
          this.$data.loading = false;

          this.$data.datos = res.data.productos;
          this.$data.total = res.data.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];
          this.$emit("errorProd", "");
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.errors = [];
          let error = err.response.data;

          this.$data.errors.push(error.mensaje);
          this.$emit("errorProd", error.mensaje);
        });
    },
    filtrarDatos2() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;

API.productosClientes(
        this.$props.id,
        this.$store.state.token,
        direction,
        order,
        this.$data.mi_id != undefined ? this.$data.mi_id : "",
        this.$data.nombre != undefined ? this.$data.nombre : "",
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$emit("recargaProducto", false);
          this.$data.loading = false;

          this.$data.datos = res.data.productos;
          this.$data.total = res.data.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.mi_id != null
              ? this.$data.filter.mi_id.push(el.producto_info.mi_id)
              : "";
          });

          this.$data.filter.nombre = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.nombre != null
              ? this.$data.filter.nombre.push(el.producto_info.nombre)
              : "";
          });
          this.$data.filter.ean = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.ean != null
              ? this.$data.filter.ean.push(el.producto_info.ean)
              : "";
          });
          this.$data.filter.upc = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.upc != null
              ? this.$data.filter.upc.push(el.producto_info.upc)
              : "";
          });
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
          this.$data.errors = [];
          let error = err.response.data;
          Swal.fire({
            icon: "error",
            title: `${error.mensaje}`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    recargar(v) {
      if (v) {
        this.filtrarDatos();
      }
    },
    mi_id(v) {
      if (v == null) {
        this.$data.mi_id = "";
        this.filtrarDatos2();
      }
    },
    marca(v) {
      if (v == null) {
        this.$data.marca = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ean(v) {
      if (v == null) {
        this.$data.ean = "";
        this.filtrarDatos2();
      }
    },
    upc(v) {
      if (v == null) {
        this.$data.upc = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver
  },
};
</script>
<style lang="scss"></style>
