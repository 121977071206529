<template>
  <v-container fluid class="ma-0 px-4">
    <info-masivos-component
      iconAvatar="mdi-tag-multiple"
      titleCard="Edición Masiva de Productos"
      @onDownloadExcelBtn="exportarProducto"
      @onUploadExcelBtn="cargarExcel"
      :loading="loading"
      :responseApi="responseApi"
    />

    <!-- FIN DE CARDS INFOMASIVOSCOMPONENT-->

    <v-row v-if="errors.length > 0">
      <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
        <div v-if="errors.length > 0" class="">
          <v-alert
            dense
            style="padding: 8px"
            text
            type="error"
            dismissible
            v-for="(err, i) in errors"
            :key="i"
          >
            <strong>{{ err }}</strong>
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <div id="grid"></div>
  </v-container>
</template>

<script>
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
export default {
  name: "relacionar-masivamente",
  title: "Admin edición masiva producto - Onestock",
  data: () => ({
    excel: null,
    user: null,
    reverse: false,
    errors: [],
    cards: [],
    loading: true,
    responseApi: {},
  }),
  methods: {
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportarProductoAdmin(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
        });
    },
    cargarExcel(excel) {
      this.$data.excel = excel;
      this.$data.errors = [];
      let formData = new FormData();
      formData.append("archivo", this.$data.excel);
      API.editarMasivaProduct(formData, this.$store.state.token)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.excel = "";
          this.responseApi = res;
        })
        .catch((err) => {
          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    "info-masivos-component": InfoMasivosComponent,
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
