<template>
    <v-container>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-row>
                <v-col lg="12" class="d-flex">
               
                <div class="pl-3">
                    <div>
                    <h4>
                        {{ userData.plan_id != null ? userData.plan.nombre : "" }}
                    </h4>
                    </div>
                    <div>
                    <h4>
                        <span class="primary--text">
                        {{ userData.productos_relacionados }}
                        </span>
                        Productos relacionados
                    </h4>
                    </div>
                </div>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-card class="px-3 pb-0">
                <v-row>
                <v-col md="6" lg="6" sm="12" cols="12" class="text-center">
                    <base-material-card
                    icon="mdi-account"
                    title="Modificar datos de cliente"
                    class="px-5 py-3"
                    color="primary"
                    >
                    <v-form
                        ref="valid"
                        v-model="valid"
                        lazy-validation
                        autocomplete="false"
                    >
                        <v-container>
                        <v-row>
                            <v-col
                            md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                            <v-text-field
                                :rules="rules.notEmpty"
                                v-model.trim="userData.nombre"
                                label="Nombre"
                                dense
                                outlined
                                placeholder="Nombre"
                            ></v-text-field>
                            <v-text-field
                                :rules="rules.rut"
                                type="text"
                                label="Rut"
                                maxlength="12"
                                dense
                                outlined
                                placeholder="Rut"
                                v-model.trim="formatedRut"
                                id="rut"
                            ></v-text-field>
                            <v-text-field
                                :rules="rules.email"
                                v-model.trim="userData.email"
                                dense
                                outlined
                                placeholder="E-mail"
                                label="E-mail"
                                no-autocomplete
                            ></v-text-field>

                            <v-text-field
                                autocomplete="new-password"
                                v-model.trim="userData.password"
                                :type="showPassword ? 'text' : 'password'"
                                :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="showPassword = !showPassword"
                                label="Contraseña"
                                outlined
                                placeholder="Contraseña"
                                required
                                clearable
                            >
                            </v-text-field>
                            <v-switch
                                v-model="activos"
                                class="ma-2"
                                :label="activ"
                                hide-details=""
                            ></v-switch>
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-form>
                    </base-material-card>
                </v-col>
                <v-col
                    md="6"
                    lg="6"
                    sm="12"
                    cols="12"
                    offset-sm="0"
                    class="text-center padd"
                >
                    <base-material-card
                    icon="mdi-file-powerpoint-outline"
                    title="Mi Plan"
                    class="px-5 py-3"
                    color="primary"
                    >
                    <v-container>
                        <v-row>
                        <v-simple-table dense style="width: 100%">
                            <template>
                            <tbody>
                                <tr>
                                <td>Nombre</td>
                                <td>
                                    <v-autocomplete
                                    v-model="userData.plan_id"
                                    :items="filter.plan"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="id"
                                    placeholder="Planes"
                                    clearable
                                    outlined
                                    label="Planes"
                                    dense
                                    hide-details
                                    ></v-autocomplete>
                                </td>
                                </tr>
                                <tr>
                                <td>Descripción</td>
                                <td>
                                    {{
                                    userData.plan_id != null
                                        ? userData.plan.descripcion
                                        : ""
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <td>Precio x petición</td>
                                <td>
                                    {{
                                    userData.plan_id != null
                                        ? userData.plan.precio_x_producto
                                        : "" | currency
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <td>Límite diario</td>
                                <td>
                                    {{
                                    userData.plan_id != null
                                        ? userData.plan.limite_diario
                                        : ""
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <td>Límite diario</td>
                                <td>
                                    {{
                                    userData.plan_id != null
                                        ? userData.plan.limite_diario
                                        : ""
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <td>Plan ilimitado</td>
                                <td>
                                    {{
                                    userData.plan_id != null
                                        ? userData.plan.plan_ilimitado == false
                                        ? "No"
                                        : "Si"
                                        : ""
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <td>Precio x sobreconsumo</td>
                                <td v-if="userData.plan_id != null">
                                    {{
                                    userData.plan.precio_sobre_consumo | currency
                                    }}
                                </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        </v-row>
                    </v-container>
                    </base-material-card>
                </v-col>
                </v-row>
                <div v-if="errorProd" class="pb-2">
                <v-alert dense style="padding: 8px" text type="error" dismissible>
                    <strong>{{ errorProd }}</strong>
                </v-alert>
                </div>
            </v-card>
            </v-container>
        </v-col>
        </v-row>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-row>
                <v-col lg="12">
                <div class="d-flex">
                    <btn-volver />

                    <v-spacer />

                    <v-btn
                    color="secondary"
                    rounded
                    class="text-center"
                    :disabled="!valid"
                    @click="editarCliente()"
                    >
                    Guardar
                    </v-btn>
                </div>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import API from "@/api";
    import { isRutValid } from "@/utils";
    import Swal from "sweetalert2";
    import { EventBus } from "@/main.js";
    import BtnVolver from "@/components/BtnVolver.vue";
    var moment = require("moment");

    export default {
    name: "EditarClienteFormComponent",
    title: "Admin editar cliente - Onestock",
    props: ["id"],
    data: () => ({
        accion: {
        to: { name: "admin-crear-productos" },
        text: "Crear producto",
        },
        errorProd: null,
        errors: [],
        datos: [],
        avatar: null,
        no_row_data: "No hay datos",
        loading: true,
        recargaProducto: false,
        userData: {
        nombre: "",
        rut: "",
        email: "",
        password: "",
        avatar: null,
        activo: false,
        plan_id: null,
        },
        filter: {
        plan: null,
        },
        repeat: "",
        activos: false,
        valid: false,
        showPassword: false,
        showPassword2: false,
        error: [],
        rules: {
        notEmpty: [(v) => !!v || "La contraseña es requerida"],
        email: [
            (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "El e-mail debe ser válido",
        ],
        rut: [
            (v) => !!v || `El Rut es requerido`,
            (v) => isRutValid(v) || `Rut inválido`,
        ],
        },
        rut: "",
    }),
    async beforeMount() {
        EventBus.$emit("cargando", true);
        await API.verClienteAdmin(this.$store.state.token, this.$props.id)
        .then((res) => {
            this.$data.userData = res.data.respuesta;
            if (this.$data.userData.rut) {
            this.$data.rut = this.$data.userData.rut;
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await API.listarPlanesAdmin2(this.$store.state.token)
        .then((res) => {
            this.$data.filter.plan = res.data.respuesta.data;
        })
        .catch((err) => {
            console.log(err);
        });
        let err = this.$data.errorProd;
        if (err) {
        this.$data.errors.push(err);
        }
        EventBus.$emit("cargando", false);
    },

    computed: {
        activ() {
        return this.$data.activos == true ? "Activo" : "Inactivo";
        },
        formatedRut: {
        get() {
            return this.formatRut(this.rut);
        },
        set(modifiedValue) {
            this.rut = modifiedValue;
        },
        },
    },
    methods: {
        editarCliente() {
        EventBus.$emit("cargando", true);
        this.$data.error = [];

        if (this.$data.rut != undefined) {
            var r = this.$data.rut.split(".").join("");
            var r2 = r.split("-").join("");
            this.$data.userData.rut = r2;
        }
        this.$data.userData.avatar = null;
        if (this.$data.avatar != null) {
            this.$data.userData.avatar = this.$data.avatar;
        }
        if (this.$data.userData.password == null) {
            this.$data.userData.password = "";
        }
        if (this.$refs.valid.validate()) {
            API.editarClienteAdmin(
            this.$data.userData,
            this.$store.state.token,
            this.$props.id
            )
            .then((res) => {
                Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
                });
                EventBus.$emit("cargando", false);
                this.$data.errors = [];
                this.$data.recargaProducto = true;
                setTimeout(() => {
                this.$data.recargaProducto = false;
                }, 1000);

                API.verClienteAdmin(this.$store.state.token, this.$props.id)
                .then((res) => {
                    this.$data.userData = res.data.respuesta;
                })
                .catch((err) => {
                    console.log(err);
                });
            })
            .catch((err) => {
                EventBus.$emit("cargando", false);
                this.$data.errors = [];
                let error = err.response.data;
                Object.keys(error.mensaje).forEach((el) => {
                var texto_error = "";
                if (el == "email") {
                    this.$data.errors.push(error.mensaje.email[0]);
                    console.log(this.$data.errors);
                    texto_error =
                    texto_error + "- " + error.mensaje.email[0] + "<br/>";
                }
                if (el == "rut") {
                    this.$data.errors.push(error.mensaje.rut[0]);
                    texto_error = texto_error + "- " + error.mensaje.rut[0];
                }
                console.log(texto_error);
                Swal.fire({
                    icon: "error",
                    title: texto_error,
                    showConfirmButton: false,
                    timer: 2500,
                });
                });
            });
        }
        },
        goback() {
        this.$router.go(-1);
        },
        formatRut(rut) {
        rut = rut.replace(/[^k0-9]/gim, "").split("");
        return rut.length > 1
            ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
                rut[rut.length - 1]
            }`
            : rut.join("");
        },
    },
    created() {
    },
    watch: {
        recargaProducto(v) {
        if (v == true) {
            this.$data.errors = [];
            API.verClienteAdmin(this.$store.state.token, this.$props.id)
            .then((res) => {
                console.log("recargado");
                this.$data.userData = res.data.respuesta;
                if (this.$data.userData.rut) {
                this.$data.rut = this.$data.userData.rut;
                }
            })
            .catch((err) => {
                console.log(err);
            });
            console.log("this.$data.errorProd", this.$data.errorProd);
            let err = this.$data.errorProd;
            if (err) {
            this.$data.errors.push(err);
            }
        }
        },
        activos(v) {
        v == true
            ? (this.$data.userData.activo = 1)
            : (this.$data.userData.activo = 0);
        },
    },

    components: {
        BtnVolver,
    },
};
</script>
<style lang="scss" scoped>
.navbar {
    z-index: 3;
}
</style>
<style scoped>
.ocultar-icono >>> .v-input__prepend-outer {
    display: none;
}
</style>
