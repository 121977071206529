<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <v-form ref="valid" v-model="valid" autocomplete="false">
                <base-material-card
                  icon="mdi-truck-check"
                  title="Crear Proveedor"
                  color="primary"
                >
                  <v-container>
                    <v-row v-if="errors.length > 0">
                      <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                        <div v-if="errors.length > 0" class="">
                          <v-alert
                            dense
                            style="padding: 8px"
                            text
                            type="error"
                            dismissible
                            v-for="(err, i) in errors"
                            :key="i"
                          >
                            <strong>{{ err }}</strong>
                          </v-alert>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6" lg="6" sm="12" cols="12">
                        <v-text-field
                          v-model.trim="proveedor_info.nombre"
                          label="Nombre"
                          :rules="rules.notEmpty"
                          required
                          clearable
                          outlined
                          placeholder="Nombre"
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="proveedor_info.email"
                          label="E-mail"
                          :rules="rules.emailRules"
                          required
                          outlined
                          placeholder="E-mail"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="proveedor_info.password"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          label="Contraseña"
                          :rules="rules.passwordRules"
                          outlined
                          placeholder="Contraseña"
                          required
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="proveedor_info.direccion_comercial"
                          label="Dirección comercial"
                          :rules="rules.notEmpty"
                          required
                          outlined
                          placeholder="Dirección comercial"
                          clearable
                        ></v-text-field>
                        <v-switch
                          v-model="activos"
                          class="ma-2"
                          :label="activ"
                        ></v-switch>
                      </v-col>
                      <v-col md="6" lg="6" sm="12" cols="12">
                        <v-autocomplete
                          v-model.trim="proveedor_info.disponibilidad"
                          label="Disponibilidad"
                          item-text="text"
                          item-value="id"
                          clearable
                          :items="disponibilidades"
                          outlined
                          placeholder="Disponibilidad"
                        ></v-autocomplete>
                        <v-text-field
                          v-model="proveedor_info.ftp_host"
                          label="ftp_host (opcional)"
                          clearable
                          outlined
                          placeholder="ftp_host"
                        ></v-text-field>
                        <v-text-field
                          v-model="proveedor_info.ftp_usuario"
                          label="ftp_usuario (opcional)"
                          clearable
                          outlined
                          placeholder="ftp_usuario"
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="proveedor_info.ftp_password"
                          label="ftp_password (opcional)"
                          clearable
                          outlined
                          placeholder="ftp_password"
                        ></v-text-field>
                        <v-text-field
                          v-model="proveedor_info.ftp_puerto"
                          label="ftp_puerto (opcional)"
                          clearable
                          outlined
                          placeholder="ftp_puerto"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-space-around">
                      <btn-volver />
                      <v-hover v-slot:default="{ hover }" open-delay="200">
                        <v-btn
                          :elevation="hover ? 0 : 6"
                          :color="hover ? 'secondaryDark' : 'secondary'"
                          rounded
                          class="text-center"
                          :disabled="!valid"
                          @click="crearNuevoProveedor()"
                        >
                          Guardar
                        </v-btn>
                      </v-hover>
                    </div>
                  </v-container>
                </base-material-card>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "editar-proveedor",
  title: "Admin crear proveedor - Onestock",
  props: ["id"],
  data: () => ({
    errors: [],
    valid: false,
    showPassword: false,
    activos: false,
    disponibilidades: [
      {
        text: "Stock",
        id: "stock",
      },
      {
        text: "Binario",
        id: "binario",
      },
    ],
    proveedor_info: {
      email: "",
      nombre: "",
      password: "",
      direccion_comercial: "",
      logo: null,
      activo: 0,
      disponibilidad: "stock",
      ftp_host: "",
      ftp_usuario: "",
      ftp_password: "",
      ftp_puerto: "",
    },
    rules: {
      notEmpty: [(v) => {
        return (typeof v == "number") || !!v?.trim() || "Este campo es requerido"
        }],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Ingrese un e-mail válido"],
    },
  }),
  methods: {
    crearNuevoProveedor() {
      EventBus.$emit("cargando", true);
      this.$data.errors = [];

      this.$data.proveedor_info.activo = this.$data.activos == true ? 1 : 0;

      API.crearNuevoProveedor(
        this.$store.state.token,
        this.$data.proveedor_info
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
          setTimeout(() => {
            this.$router.push("/admin/proveedores");
          }, 2000);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          const mensajes = err.response.data.respuesta;
          for (const key in mensajes) {
            this.$data.errors.push(...mensajes[key]);
          }
        });
    },
  },
  computed: {
    activ() {
      return this.$data.activos == true ? "Activo" : "Inactivo";
    },
  },
  watch: {
    activos(v) {
      v == true ? (this.$data.marca.activo = 1) : (this.$data.marca.activo = 0);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.list-style {
  list-style: none;
}
</style>
