<template>
    <v-container>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-card class="px-3 pb-0">
                <v-card-title class="pl-0 pt-2">
                <v-icon>mdi-magnify</v-icon>
                Filtro
                </v-card-title>
                <v-row justify-center align-center>
                <v-col md="4" lg="4" sm="6" cols="12" class="text-center">
                    <v-text-field
                    label="Ingrese un ID"
                    dense
                    hide-details
                    v-model="id"
                    color="primary"
                    placeholder="Ingrese un ID"
                    clearable
                    :rules="rules.numeros"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col md="4" lg="4" sm="6" cols="12" class="text-center">
                    <v-text-field
                    dense
                    hide-details
                    v-model="nombre"
                    color="primary"
                    placeholder="Nombre"
                    clearable
                    outlined
                    label="Nombre"
                    ></v-text-field>
                </v-col>
                <v-col md="4" lg="4" sm="6" cols="12" class="text-center d-flex">
                    <v-container class="pa-0 ma-0" fluid>
                    <v-layout class="pa-0 ma-0">
                        <v-col cols="12" md="10" lg="10" sm="10" class="pa-0 ma-0">
                        <v-btn block color="primary" @click="filtrarDatos2">
                            Filtrar
                        </v-btn>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" sm="2" class="pa-0 ma-0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                color="green"
                                v-bind="attrs"
                                v-on="on"
                                @click="restablecer"
                            >
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            </template>
                            <span>Restablecer Filtro</span>
                        </v-tooltip>
                        </v-col>
                    </v-layout>
                    </v-container>
                </v-col>
                </v-row>
            </v-card>
            </v-container>
            <v-container fluid class="pa-1">
            <v-row>
                <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
                <base-material-card
                    icon="mdi-barcode"
                    title="Proveedores"
                    class="py-3"
                    color="primary"
                    :registros="total"
                >
                    <v-data-table
                    dense
                    no-data-text="No existen registros en tu cuenta"
                    :headers="headers"
                    :items="datos"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [5, 10],
                        showFirstLastPage: true,
                        disablePagination: loading,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text': 'Registros por página',
                    }"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td>{{ item.id }}</td>
                            <td>
                            <div v-if="item.logo != null">
                                <v-img :src="item.logo" width="30px"></v-img>
                            </div>
                            <div v-else>-</div>
                            </td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.direccion_comercial }}</td>
                            <td>
                            {{
                                item.disponibilidad != null
                                ? item.disponibilidad
                                : "-"
                            }}
                            </td>
                            <td>
                            <v-tooltip bottom v-if="item.clientes.length == 0">
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="green darken-2 ma-1"
                                    @click="verProveedores(item.id)"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-link</v-icon
                                >
                                </template>
                                <span>Relacionar</span>
                            </v-tooltip>
                            <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="red darken-2 ma-1"
                                    @click="quitarProveedores(item.id)"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-cancel</v-icon
                                >
                                </template>
                                <span>Quitar Relación</span>
                            </v-tooltip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </base-material-card>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import API from "@/api";
    import Swal from "sweetalert2";
    import ProductosVue from "./Productos.vue";
    import { EventBus } from "@/main.js";

    export default {
    name: "proveedores",
    title: "Proveedores - Onestock",
    props: ["ids_cliente"],
    data: () => ({
        dialog: true,
        total: 0,
        loading: false,
        totalDesserts: 0,
        desserts: [],
        options: {},
        headers: [
        {
            text: "ID",
            align: "Center",
            sortable: true,
            value: "id",
        },
        {
            text: "Logo",
            align: "Center",
            value: "logo",
            sortable: false,
        },
        {
            text: "Nombre",
            align: "Center",
            value: "nombre",
            sortable: true,
        },
        {
            text: "Dirección",
            align: "Center",
            value: "direccion_comercial",
            sortable: false,
        },
        {
            text: "Tipo de disponibilidad",
            align: "Center",
            value: "disponibilidad",
            sortable: false,
        },
        {
            text: "Acciones",
            align: "Center",
            value: "accion",
            sortable: false,
        },
        ],
        datos: [],
        id: "",
        nombre: "",
        tipo: "",
        filter: {
        id: [],
        nombre: [],
        tipo: [],
        },
        rules: {
        numeros: [(v) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"],
        },
    }),
    beforeMount() {
        this.filtrarDatos();
    },
    methods: {
        restablecer() {
        this.$data.id = "";
        this.$data.nombre = "";
        this.filtrarDatos2();
        },
        verProveedores(id_proveedor) {
        API.relacionarProveedor(
            id_proveedor,
            this.$store.state.token,
            this.$store.state.user.id
        )
            .then((res) => {
            Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
            });
            this.filtrarDatos();
            })
            .catch((err) => {
            console.log(err);
            });
        },
        quitarProveedores(id_proveedor) {
        API.quitarRelacionClienteProveedores(
            this.$store.state.user.id,
            this.$store.state.token,
            id_proveedor
        )
            .then((res) => {
            Swal.fire({
                icon: "success",
                title: "Datos actualizados",
                showConfirmButton: false,
                timer: 1500,
            });

            this.filtrarDatos();
            })
            .catch((err) => {
            console.log(err);
            });
        },
        filtrarDatos() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        let id_prod;
        this.$data.id != undefined ? (id_prod = this.$data.id) : (id_prod = "");

        let nombre;
        this.$data.nombre != undefined
            ? (nombre = this.$data.nombre)
            : (nombre = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";

        if (sortBy[0]) {
            order = sortBy;
        }

        API.listarProveedores(
            this.$store.state.user.id,
            this.$store.state.token,
            direction,
            order,
            id_prod,
            nombre,
            page,
            itemsPerPage
        )
            .then((res) => {
            this.$data.datos = res.data.data;
            this.$data.total = res.data.total;
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;

            res.data.respuesta.data.forEach((el, i) => {
                el.id != null ? this.$data.filter.id.push(el.id) : "";
            });
            res.data.respuesta.data.forEach((el, i) => {
                el.nombre != null ? this.$data.filter.nombre.push(el.nombre) : "";
            });
            res.data.respuesta.data.forEach((el, i) => {
                el.disponibilidad != null
                ? this.$data.filter.tipo.push(el.disponibilidad)
                : "";
            });
            })
            .catch((err) => {
            console.log(err);
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            });
        },
        filtrarDatos2() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        let id_prod;
        this.$data.id != undefined ? (id_prod = this.$data.id) : (id_prod = "");

        let nombre;
        this.$data.nombre != undefined
            ? (nombre = this.$data.nombre)
            : (nombre = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";
        if (sortBy[0]) {
            order = sortBy;
        }

        this.options.page = 1;

        API.listarProveedores(
            this.$store.state.user.id,
            this.$store.state.token,
            direction,
            order,
            id_prod,
            nombre,
            1,
            itemsPerPage
        )
            .then((res) => {
            this.$data.datos = res.data.data;
            this.$data.total = res.data.total;
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;

            res.data.respuesta.data.forEach((el, i) => {
                el.id != null ? this.$data.filter.id.push(el.id) : "";
            });
            res.data.respuesta.data.forEach((el, i) => {
                el.nombre != null ? this.$data.filter.nombre.push(el.nombre) : "";
            });
            res.data.respuesta.data.forEach((el, i) => {
                el.disponibilidad != null
                ? this.$data.filter.tipo.push(el.disponibilidad)
                : "";
            });
            })
            .catch((err) => {
            console.log(err);
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            });
        },
    },
    watch: {
        options: {
        handler() {
            this.filtrarDatos();
        },
        deep: true,
        },
        id(v) {
        if (v == null) {
            this.$data.id = "";
            this.filtrarDatos2();
        }
        },
        nombre(v) {
        if (v == null) {
            this.$data.nombre = "";
            this.filtrarDatos2();
        }
        },
    },
    };
    </script>
    <style lang="scss">
    .btnclose {
    position: absolute !important;
    right: 1rem;
    border-radius: 25px;
    padding: 11px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px !important;
    width: 36px;
}
</style>
