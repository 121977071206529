<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <h3>Configuraciones Globales</h3>
          <base-material-card
            icon="mdi-format-list-numbered"
            title="Opciones"
            class="px-5 py-3"
            color="primary"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Días de cobro</td>
                    <td>
                      <v-text-field
                        v-model="cobro"
                        dense
                        label="Días de cobro"
                        outlined
                        hide-details
                        class="ma-2"
                      >
                      </v-text-field>
                    </td>
                    <td>
                      <v-hover v-slot:default="{ hover }" open-delay="200">
                        <v-btn
                          :elevation="hover ? 0 : 6"
                          :color="hover ? 'secondaryDark' : 'secondary'"
                          small
                          rounded
                          @click="cambiarValorCobro"
                        >
                          Guardar
                        </v-btn>
                      </v-hover>
                    </td>
                  </tr>
                  <tr>
                    <td>Hora para recorrer FTP proveedor</td>
                    <td>
                      <v-text-field
                        v-model="hora"
                        dense
                        label="Hora para recorrer FTP proveedor"
                        outlined
                        hide-details
                        class="ma-2"
                      >
                      </v-text-field>
                    </td>
                    <td>
                      <v-hover v-slot:default="{ hover }" open-delay="200">
                        <v-btn
                          :elevation="hover ? 0 : 6"
                          :color="hover ? 'secondaryDark' : 'secondary'"
                          small
                          rounded
                          @click="cambiarValorHora"
                        >
                          Guardar
                        </v-btn>
                      </v-hover>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </base-material-card>
        </v-container>
      </v-col>
    </v-row>
    <div class="text-left">
      <btn-volver class="my-16" />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnVolver from "@/components/BtnVolver.vue";
var moment = require("moment");

export default {
  components: { BtnVolver },
  name: "configuracion",
  title: "Admin configuración - Onestock",
  data: () => ({
    datos2: [],
    dialog: false,
    no_row_data: "No hay datos",
    loading: true,
    cobro: null,
    hora: null,
    headers: [
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Valor",
        align: "Center",
        value: "valor",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "Center",
        value: "acciones",
        sortable: false,
      },
    ],
  }),
  async beforeMount() {
    this.$data.loading = false;
    await this.getConfiguracion();
  },
  methods: {
    getConfiguracion() {
      EventBus.$emit("cargando", true);
      let dia = "dia_cobros";
      let hora = "hora_stock_ftp";

      API.obtenerConfiguracion(this.$store.state.token, dia)
        .then((res) => {
          this.$data.cobro = res.data.data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });

      API.obtenerConfiguracion(this.$store.state.token, hora)
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.hora = res.data.data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    cambiarValorCobro() {
      EventBus.$emit("cargando", true);
      API.actualizarConfiguracionCobro(
        this.$store.state.token,
        this.$data.cobro
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
          this.getConfiguracion();
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    cambiarValorHora() {
      EventBus.$emit("cargando", true);
      API.actualizarConfiguracionHora(this.$store.state.token, this.$data.hora)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
          this.getConfiguracion();
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
};
</script>
<style lang="scss"></style>
