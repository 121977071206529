<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col md="8" offset-lg="2" offset-md="2" offset-sm="0" lg="8" sm="12">
        <base-material-card
          icon="mdi-tag-multiple"
          title="Crear Nuevo Producto"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row v-if="errors.length > 0">
              <v-col cols="12">
                <div v-if="errors.length > 0" class="">
                  <v-alert
                    dense
                    style="padding: 8px"
                    text
                    type="error"
                    dismissible
                    v-for="(err, i) in errors"
                    :key="i"
                  >
                    <strong>{{ err }}</strong>
                  </v-alert>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12">
                <v-form ref="valid" v-model="valid" autocomplete="false">
                  <v-text-field
                    v-model.trim="producto.nombre"
                    label="Nombre"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Nombre"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="producto.cod_interno"
                    label="Código interno"
                    :rules="rules.notEmpty"
                    clearable
                    placeholder="Código interno"
                    outlined
                  ></v-text-field>
                  <v-autocomplete
                    v-model="producto.marca"
                    :items="marcaSort"
                    color="primary"
                    item-text="nombre"
                    item-value="id"
                    placeholder="Marca"
                    :rules="rules.notEmpty"
                    clearable
                    outlined
                  ></v-autocomplete>
                  <v-text-field
                    v-model="producto.upc"
                    label="Upc: Código Universal de Produto (opcional)"
                    clearable
                    maxlength="12"
                    placeholder="Upc"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="producto.ean"
                    label="Ean: Número de Artículo Europeo (opcional)"
                    clearable
                    maxlength="13"
                    outlined
                    placeholder="Ean"
                  ></v-text-field>

                  <v-switch
                    v-model="activo"
                    class="ma-2"
                    :label="activ"
                  ></v-switch>
                  <div class="d-flex">
                    <btn-volver />

                    <v-spacer />

                    <v-hover v-slot:default="{ hover }" open-delay="200">
                      <v-btn
                        :color="hover ? 'secondaryDark' : 'secondary'"
                        rounded
                        class="text-center"
                        :elevation="hover ? 0 : 6"
                        :disabled="!valid"
                        @click="registrarProducto()"
                        >Guardar
                      </v-btn>
                    </v-hover>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "crear-producto",
  title: "Admin crear producto - Onestock",
  data: () => ({
    errors: [],
    valid: false,
    activo: false,
    producto: {
      mi_id: "",
      marca: "",
      nombre: "",
      ean: "",
      upc: "",
      cod_interno: "",
      logo: "",
      activo: 0,
    },
    filter: {
      marca: null,
    },
    rules: {
       notEmpty: [(v) => {
        return (typeof v == "number") || !!v?.trim() || "Este campo es requerido"
        }],
    },
  }),
  async beforeMount() {
    await API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.filter.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    registrarProducto() {
      EventBus.$emit("cargando", true);
      this.$data.errors = [];

      API.registrarProducto(this.$store.state.token, this.$data.producto)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
          setTimeout(() => {
            this.$router.push("/admin/productos");
          }, 2000);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log(err.response.data);
          const mensajes = err.response.data.respuesta;
          for (const key in mensajes) {
            this.$data.errors.push(...mensajes[key]);
          }
        });
    },
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {
    activ() {
      return this.$data.activo == true ? "Activo" : "Inactivo";
    },
    marcaSort() {
      return this.filter.marca?.sort((a, b) =>
        a.nombre.localeCompare(b.nombre)
      );
    },
  },
  watch: {
    activo(v) {
      v == true
        ? (this.$data.producto.activo = 1)
        : (this.$data.producto.activo = 0);
      console.log(this.$data.producto.activo);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.list-style {
  list-style: none;
}
</style>
