<template>
    <v-container>
        <v-row>
            <v-col
                md="12"
                lg="12"
                sm="12"
                cols="12"
                class="px-5 text-center mx-auto padd"
            >
             <v-card class="pa-3">
                 <h3> Edicion Masiva Stock Producto :</h3>
                     <v-timeline :reverse="reverse" dense >
                    <v-timeline-item
                        class="pa-0"
                        v-for="(n , i) in datos"
                        :key="i"
                    >
                        <template v-slot:icon>
                            <v-avatar>
                            <p class="white--text p-0 mb-0"> {{i+1}} </p>
                            </v-avatar>
                        </template>
                        <v-card class="elevation-0">
                        <!--v-card-title class="headline">Lorem ipsum</v-card-title-->
                        <v-card-text class="text-left">
                            {{ n.texto}}
                        </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    </v-timeline>
                   
                    <v-container>
                        <v-row>
                            <v-col
                                sm="12"
                                cols="12"
                                md="12"
                                lg="12">
                                <div class="pa-3 text-left">
                                    <v-btn
                                        color="primary"
                                        :disabled="bloqueo_boton==true"
                                         @click="exportarProducto()">
                                            DESCARGAR PRODUCTOS
                                        </v-btn>
                                </div>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                sm="12"
                                                cols="12"
                                                md="8"
                                                lg="8">
                                                
                                                <v-file-input
                                                class="ocultar-icono"
                                                prepend-inner-icon="mdi-paperclip"
                                                v-model="excel"
                                                id="file"
                                                label="Click en el recuado para cargar el Archivo"
                                                placeholder="Archivo de edicion stock productos"
                                                dense
                                                outlined>
                                                </v-file-input> 
                                            </v-col>
                                            <v-col
                                                sm="12"
                                                cols="12"
                                                md="4"
                                                lg="4">
                                               <v-btn
                                               v-if="excel == null"
                                                block
                                                disabled
                                                @click="cargarExcel()"
                                                color="primary">
                                                Cargar
                                               </v-btn>
                                               <v-btn
                                               v-else
                                                block
                                                :disabled="bloqueo_boton==true"
                                                @click="cargarExcel()"
                                                color="primary">
                                                Cargar
                                               </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-row v-if="errors.length > 0">
                            <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2"> 
                            <div v-if="errors.length > 0" class="">
                                <v-alert 
                                    dense
                                    style="padding:8px "
                                    text
                                    type="error"
                                    dismissible
                                        v-for="(err ,i) in errors" :key="i" 
                                    >
                                    <strong>{{err}}</strong>
                                </v-alert>
                            </div>
                     </v-col>
                        </v-row>
                    </v-container>
                    
             </v-card>
                

            </v-col>
        </v-row>
       <div v-if="Errores != null">
        <ErroresMasivos :Errores="Errores"/>
        </div>
    </v-container>
</template>


<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'
import ErroresMasivos from '@/views/dashboard/pages/admin/subUser/ErroresMasivos'
export default {
    name:'relacionar-masivamente',
    title: 'Admin edición masiva producto - Onestock',
    data:()=>({
        id:null,
        excel:null,
        user:null,
        bloqueo_boton: false,
        reverse: false,
        errors:[],
        cards:[],
        Errores:null,
        datos:[
            {
              texto: "Para editar masivamente usted debe descargar el excel de productos desde el botón de una más abajo 'Descargar Excel Productos'. Recuerda que debes permitir las ventanas emergentes de tu navegador. "
            },
            {
               texto: "En el excel encontrará todos los productos disponibles del perfil."
            },
            {
                texto: "Para editar el stock en los productos sólo debe modificar la linea que corresponda."
            },
            {
                texto: "Finalmente carge su nuevo archivo con los cambios en el campo 'Cargar archivos' y luego presiona el botón 'Cargar'."
            }
        ]
    }),
    methods: {
      exportarProducto () {
        this.$data.bloqueo_boton = true
        EventBus.$emit('cargando', true)
        API.exportarProdProvee(this.$store.state.user.id, this.$store.state.token)
          .then((res) => {
            window.open(res.respuesta)
            EventBus.$emit('cargando', false)
            this.$data.bloqueo_boton = false
          })
            .catch((err)=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Error al exportar productos',
                    showConfirmButton: false,
                    timer: 1500
                    })
                EventBus.$emit('cargando',false)
                this.$data.bloqueo_boton = false
            })
        },
        cargarExcel(){
            this.$data.bloqueo_boton = true
              EventBus.$emit('cargando', true)
            this.$data.errors=[]
            let formData = new FormData();
            formData.append("archivo", this.$data.excel); 
            API.editarMasivaProductStock(formData,this.$store.state.token,this.$store.state.user.id)
            .then((res)=>{
                 EventBus.$emit('cargando', false)
                   if(res.porcentaje_prod_actualizados == 100){
                Swal.fire({
                    icon: 'success',
                    title: 'Proceso finalizado con exito',
                    html:res.mensaje,
                    confirmButtonColor: '#771D97',
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    })
                    }else{
                Swal.fire({
                    icon: 'warning',
                    title: 'Actualización con advertencia',
                    html:res.mensaje,
                    confirmButtonColor: '#771D97',
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    })
                    }
                    this.$data.excel = ''
                    let errores =  Object.values(res.datos)
                  this.$data.bloqueo_boton=false
                  this.$data.Errores=errores
                  if (res.datos == "") {
                      this.$data.Errores=null
                  }
                  
                    // this.$router.push('/proveedor/editar-proveedor')
            }).catch((err)=>{
                let error = err.response.data
                var texto_error='El archivo contiene errores!'
                  this.$data.bloqueo_boton=false
                if(error){
                      if (err.response.data.estado == 409) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error de cabeceras',
                    html:err.response.data.mensaje,
                    confirmButtonColor: '#771D97',
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    })
                 }else{
                    texto_error = error.mensaje.extension[0]
                        Swal.fire({
                        icon: 'error',
                        title: texto_error,
                        showConfirmButton: false,
                        timer: 2000
                        })
        }
        }
                 EventBus.$emit('cargando', false)
                }
            )
        }
    },
    components: {
      ErroresMasivos,
    },
  }
</script>
<style scoped>
.text{
    word-break: break-word;
}
.ocultar-icono >>> .v-input__prepend-outer{
    display: none
}
</style>
