<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col md="8" offset-lg="2" offset-md="2" offset-sm="0" lg="8" sm="12">
        <base-material-card
          icon="mdi-align-vertical-bottom"
          title="Crear Plan"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12">
                <v-form
                  ref="valid"
                  v-model="valid"
                  lazy-validation
                  autocomplete="false"
                >
                  <v-text-field
                    v-model="plan.nombre"
                    label="Nombre"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Nombre"
                  ></v-text-field>
                  <v-switch
                    v-model="activo"
                    class="ma-2"
                    :label="activ"
                  ></v-switch>
                  <v-text-field
                    v-model="plan.descripcion"
                    label="Descripción"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Descripción"
                  ></v-text-field>
                  <v-text-field
                    v-model="plan.precio_peticion"
                    label="Precio Petición"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Precio Petición"
                  ></v-text-field>
                  <v-text-field
                    v-model="plan.limite_diario"
                    label="Límite diario"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Límite diario"
                  ></v-text-field>
                  <v-text-field
                    v-model="plan.precio_sobre_consumo"
                    label="Precio Sobre consumo"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Precio Sobre consumo"
                  ></v-text-field>

                  <v-switch
                    v-model="ilimitado"
                    class="ma-2"
                    :label="ilimit"
                  ></v-switch>
                  <v-row v-if="errors.length > 0">
                    <v-col cols="12">
                      <div v-if="errors.length > 0" class="">
                        <v-alert
                          dense
                          style="padding: 8px"
                          text
                          type="error"
                          dismissible
                          v-for="(err, i) in errors"
                          :key="i"
                        >
                          <strong>{{ err }}</strong>
                        </v-alert>
                      </div>
                    </v-col>
                  </v-row>
                  <v-btn
                    color="primary"
                    class="text-center"
                    :disabled="!valid"
                    @click="crearPlan()"
                  >
                    Crear plan
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "crear-plan",
  title: "Admin crear plan - Onestock",
  props: ["id"],
  data: () => ({
    errors: [],
    valid: false,
    activo: false,
    ilimitado: false,
    plan: {
      nombre: "",
      activo: 0,
      descripcion: "",
      precio_peticion: "",
      limite_diario: "",
      ilimitado: 0,
      precio_sobre_consumo: "",
    },
    filter: {
      plan: null,
    },
    rules: {
      notEmpty: [(v) => !!v || "Este campo es requerido"],
    },
  }),
  methods: {
    crearPlan() {
      this.$data.errors = [];
      if (this.$data.plan.nombre == "") {
        this.$data.errors.push("Debe escribir un nombre");
      }
      API.crearPlanAdmin(this.$store.state.token, this.$data.plan)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          console.log(err);
        });
      // }
    },
  },
  computed: {
    activ() {
      return this.$data.activo == true ? "Activo" : "Inactivo";
    },
    ilimit() {
      return this.$data.ilimitado == true ? "Ilimitado" : "limitado";
    },
  },
  watch: {
    activo(v) {
      v == true ? (this.$data.plan.activo = 1) : (this.$data.plan.activo = 0);
    },
    ilimitado(v) {
      v == true
        ? (this.$data.plan.ilimitado = 1)
        : (this.$data.plan.ilimitado = 0);
    },
  },
};
</script>
<style lang="scss">
.list-style {
  list-style: none;
}
</style>
