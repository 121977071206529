<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col md="8" offset-lg="2" offset-md="2" offset-sm="0" lg="8" sm="12">
        <base-material-card
          icon="mdi-factory"
          title="Crear Nueva Marca"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12">
                <v-form
                  ref="valid"
                  v-model="valid"
                  autocomplete="false"
                >
                  <v-text-field
                    v-model.trim="marca.nombre"
                    label="Nombre"
                    :rules="rules.notEmpty"
                    required
                    outlined
                    placeholder="Nombre"
                    clearable
                  ></v-text-field>
                  <v-switch
                    v-model="activo"
                    class="ma-2"
                    :label="activ"
                  ></v-switch>
                  <v-row v-if="errors.length > 0">
                    <v-col cols="12">
                      <div v-if="errors.length > 0" class="">
                        <v-alert
                          dense
                          style="padding: 8px"
                          text
                          type="error"
                          dismissible
                          v-for="(err, i) in errors"
                          :key="i"
                        >
                          <strong>{{ err }}</strong>
                        </v-alert>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <btn-volver />

                    <v-spacer />

                    <v-hover v-slot:default="{ hover }" open-delay="200">
                      <v-btn
                        :elevation="hover ? 0 : 6"
                        :color="hover ? 'secondaryDark' : 'secondary'"
                        rounded
                        class="text-center"
                        :disabled="!valid"
                        @click="registrarMarca()"
                      >
                        Guardar
                      </v-btn>
                    </v-hover>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "crear-marca",
  title: "Admin crear marca - Onestock",
  data: () => ({
    errors: [],
    valid: false,
    activo: false,
    marca: {
      marca: "",
      logo: "",
      activo: 0,
    },
    filter: {
      marca: null,
    },
    rules: {
      notEmpty: [(v) => !!v.trim() || "Este campo es requerido"],
    },
  }),
  methods: {
    registrarMarca() {
      EventBus.$emit("cargando", true);
      this.$data.errors = [];

      if (this.$data.marca.nombre == "") {
        this.$data.errors.push("Debe escribir un nombre");
      }
      if (this.$refs.valid.validate()) {
        API.crearMarcasAdmin(this.$store.state.token, this.$data.marca)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Acción exitosa",
              showConfirmButton: false,
              timer: 1500,
            });
            EventBus.$emit("cargando", false);
            setTimeout(() => {
              this.$router.push("/admin/marcas");
            }, 2000);
            //console.log(res)
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error al cargar, intente más tarde",
              showConfirmButton: false,
              timer: 1500,
            });
            EventBus.$emit("cargando", false);
            let error = err.response.data;
            if (error) {
              if (error.mensaje.logo) {
                this.$data.errors.push(error.mensaje.logo[1]);
              }
            }
          });
      }
      EventBus.$emit("cargando", false);
    },
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {
    activ() {
      return this.$data.activo == true ? "Activo" : "Inactivo";
    },
  },
  watch: {
    activo(v) {
      v == true ? (this.$data.marca.activo = 1) : (this.$data.marca.activo = 0);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style scoped>
.list-style {
  list-style: none;
}
.ocultar-icono >>> .v-input__prepend-outer {
  display: none;
}
</style>
