<template>
    <v-container>
        <v-row>
            <v-col lg="12" >
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col lg="12" class="d-flex">
                            <v-avatar>
                                <img
                                    :src="proveedor_info.logo"
                                    :alt="proveedor_info.nombre"
                                >
                            </v-avatar>
                            <div class="pl-3">
                                <div>
                                    <h4><span class="primary--text">Estado: </span> {{ proveedor_info.activo == 1 ? 'Activo' : 'Inactivo'}}</h4>
                                </div>
                                <div>
                                    <h4><span class="primary--text">  {{ proveedor_info.productos}} </span> Productos relacionados  </h4>         
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <SubEditarStockProveedores :id="id"/>
        </v-row>
      
    </v-container>
</template>

<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'
import SubEditarStockProveedores from '@/views/dashboard/pages/admin/subUser/EditarStockProveedores'
var moment = require('moment')

export default {
    name:'editar-proveedor',
    title: 'editar proveedor - Onestock',
    props:['id'],
    data:()=>({
        errors:[],
        valid:false,
        activos:false,
        logo:null,
        showPassword:false,
        proveedor_info:{
            email:"",
            nombre:"",
            password:"",
            direccion_comercial:"",
            logo:'',
            disponibilidad:null,
            activo:0,
            ftp_host:"",
            ftp_usuario:"",
            ftp_password:"",
            ftp_puerto:""
        },
        disponibilidades:[
            {
                text: 'Stock',
                id: 'stock'
            },
            {
                text: 'Binario',
                id: 'binario'
            }],

    }),
    async beforeMount(){
        await API.verProveedorAdmin(this.$store.state.token,this.$store.state.user.id)
            .then((res)=>{
                this.$data.proveedor_info = res.data.data
                this.$data.proveedor_info.created = moment(res.data.data.created).format('DD-MM-YYYY')
                this.$data.proveedor_info.modified = moment(res.data.data.modified).format('DD-MM-YYYY')
               // this.$data.proveedor_info.disponibilidad = res.data.data.disponibilidad == 'stock' ? 1 : 2
                this.$data.activos = res.data.data.activo == '1' ? true : false
            })
            .catch((err)=>{
                console.log(err)
            })
    },
    methods:{
    },
    computed: {
      activ () {
            return this.$data.activos == true ? 'Activo' : 'Inactivo'
        }
    },
    watch:{
        'activo'(v){
            v == true
            ? this.$data.marca.activo = 1
            : this.$data.marca.activo = 0
        }
    },
    components: {
      SubEditarStockProveedores,
    },
  }
</script>
<style lang="scss" >
.list-style{
    list-style: none;
}
</style>