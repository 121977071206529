import { render, staticRenderFns } from "./Relacionar-productos.vue?vue&type=template&id=195a5fdc&scoped=true&"
import script from "./Relacionar-productos.vue?vue&type=script&lang=js&"
export * from "./Relacionar-productos.vue?vue&type=script&lang=js&"
import style0 from "./Relacionar-productos.vue?vue&type=style&index=0&id=195a5fdc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195a5fdc",
  null
  
)

export default component.exports