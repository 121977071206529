<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <!--  la api de listado no tiene campo id -->
      <v-col lg="12">
        <v-container class="pa-1 ma-0" fluid>
          <v-card class="px-3 pb-0 py-4">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Productos
            </v-card-title>
            <v-row>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="id"
                  color="primary"
                  placeholder="ID"
                  label="ID"
                  clearable
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model.trim="marca"
                  :items="marcaSort"
                  color="primary"
                  item-text="nombre"
                  item-value="id"
                  placeholder="Marca"
                  label="Marca"
                  clearable
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="nombre"
                  color="primary"
                  placeholder="Nombre"
                  label="Nombre"
                  clearable
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="ac"
                  :items="filterAC"
                  color="primary"
                  item-text="text"
                  item-value="id"
                  placeholder="Activo"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Activo"
                ></v-autocomplete>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="ean"
                      color="primary"
                      placeholder="Ean"
                      clearable
                      maxlength="13"
                      :rules="rules.numeros"
                      label="Ean"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </template>
                  <span>Número de Artículo Europeo</span>
                </v-tooltip>
              </v-col>
              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="upc"
                      color="primary"
                      placeholder="Upc"
                      clearable
                      maxlength="12"
                      :rules="rules.numeros"
                      outlined
                      label="Upc"
                      dense
                      hide-details
                    ></v-text-field>
                  </template>
                  <span>Código Universal de Producto</span>
                </v-tooltip>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- TABLE TITLE ????-->
        <v-container fluid class="pa-1">
          <v-row>
            <!-- INICIO TABLA PRODUCTOS -->
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <v-container fluid class="pa-0">
                <base-material-card
                  icon="mdi-tag-multiple"
                  title="Productos"
                  class="py-3"
                  color="primary"
                  acciones="true"
                  :registros="total"
                  :enlaces="enlaces"
                  @onExportBtn="exportarProducto"
                  :showBtn="shortCutBtn"
                >
                  <v-data-table
                    calculate-widths
                    dense
                    no-data-text="No existen registros en tu cuenta"
                    :headers="headers"
                    :items="datos"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10], // -> Add this example
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                          <td>{{ item.id }}</td>
                          <td>
                            {{
                              item.marca.nombre != null
                                ? item.marca.nombre
                                : "-"
                            }}
                          </td>
                          <td>{{ item.nombre }}</td>
                          <td>{{ item.upc != null ? item.upc : "-" }}</td>
                          <td>{{ item.ean != null ? item.ean : "-" }}</td>
                          <td>
                            {{
                              item.activo != null
                                ? item.activo == 1
                                  ? "Sí"
                                  : "No"
                                : "-"
                            }}
                          </td>
                          <td>
                            {{ item.created != null ? item.created : "-" }}
                          </td>
                          <td>
                            {{
                              item.codigo_interno != null
                                ? item.codigo_interno
                                : "-"
                            }}
                          </td>

                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="secondary"
                                  medium
                                  block
                                  @click="verProducto(item.id)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-eye-arrow-right
                                </v-icon>
                              </template>
                              <span>Ver</span>
                            </v-tooltip>
                          </td>

                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="info"
                                  medium
                                  block
                                  @click="editarProducto(item.id)"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-lead-pencil</v-icon
                                >
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                          </td>

                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="red"
                                  medium
                                  block
                                  @click="eliminarProducto(item.id,index)"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-delete-alert</v-icon
                                >
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </base-material-card>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>
        <v-data-table
          dense
          no-data-text="No existe registros en tu cuenta"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
          :headers="headers2"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.pivot.stock }}</td>
                <td>{{ item.pivot.sku }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-card-title>
          <h3>Datos de clientes</h3>
        </v-card-title>
        <v-data-table
          dense
          :headers="headers3"
          :items="datos3"
          class="text-center pa-4"
          :no-data-text="no_row_data"
          no-results-text="no_row_data"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.pivot.id_interno }}</td>
                <td>{{ item.email }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <div class="my-10">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

var moment = require("moment");

export default {
  name: "productos",
  title: "Admin productos - Onestock",
  data: () => ({
    shortCutBtn: {
      export: true,
      exportMasive: {
        to: {
          name: "edicion-masiva-productos",
        },
      },
      addMasive: {
        to: {
          name: "creacion-masiva-productos",
        },
      },
    },
    exportar: false,
    enlaces: [
      {
        text: "Crear nuevo",
        to: {
          name: "crear-productos",
        },
      },
    ],
    total: 0,
    id: null,
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    ac: null,
    filterAC: [
      {
        id: 0,
        text: "No",
      },
      {
        id: 1,
        text: "Sí",
      },
    ],
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Marca",
        align: "center",
        value: "marca",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
        width: "25%",
      },
      {
        text: "Upc",
        align: "center",
        value: "upc",
        sortable: false,
      },
      {
        text: "Ean",
        align: "center",
        value: "ean",
        sortable: false,
      },
      {
        text: "Activo",
        align: "center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Fecha Creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "ID interno",
        align: "center",
        value: "id_interno",
        sortable: false,
      },
      {
        text: "Ver",
        align: "center",
        value: "accion",
        sortable: false,
      },
      {
        text: "Edit",
        align: "center",
        value: "accion",
        sortable: false,
      },
      {
        text: "Elim",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],
    headers2: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
      },
      {
        text: "Stock",
        align: "center",
        value: "pivot.stock",
      },
      {
        text: "Sku",
        align: "center",
        value: "pivot.sku",
      },
    ],
    headers3: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
      },
      {
        text: "Id interno",
        align: "center",
        value: "pivot.id_interno",
      },
      {
        text: "e-mail",
        align: "center",
        value: "email",
      },
    ],
    datos: [],
    datos2: [],
    datos3: [],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    upc: "",
    rules: {
      numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
    },
  }),
  async beforeMount() {
    await API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.filter.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.filtrarDatos();
  },
  computed: {
    marcaSort() {
      return this.filter.marca.sort((a, b) => a.nombre.localeCompare(b.nombre));
    },
  },
  methods: {
    restablecer() {
      this.$data.id = "";
      this.$data.marca = "";
      this.$data.nombre = "";
      this.$data.ean = "";
      this.$data.upc = "";
      this.$data.ac = "";
      this.filtrarDatos2();
    },
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportarProductoAdmin(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
        });
    },
    verRelacion(proveedores, clientes) {
      console.log("proveedores", proveedores, "clientes", clientes);
      this.$data.datos2 = proveedores;
      this.$data.datos3 = clientes;
      this.$data.dialog = true;
    },
    editarProducto(id) {
      this.$router.push("/admin/editar-producto/" + id);
    },
    async eliminarProducto(id, index) {
      const confirmacion = confirm("¿Seguro que deseas eliminar este producto?");
      if(!confirmacion) return;
      const datosRequest = {
        productos:[id],
        token:this.$store.state.token
      }
      const response = await API.eliminarProducto(datosRequest);
      const responseOk = response.estado==200
      if (responseOk){
        this.datos.splice(index,1)
      }
      Swal.fire({
            icon: `${responseOk? "success" : "error"}`,
            title: response.mensaje,
            showConfirmButton: false,
            timer: 1500,
          });
    },
    verProducto(id) {
      this.$router.push({
        path: "/admin/ver-producto/" + id,
      });
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              API.productosClientes(
                this.$store.state.user.id,
                this.$store.state.token,
                "",
                "",
                "",
                "",
                ""
              )
                .then((res) => {
                  this.$data.datos = res.data.productos;
                })
                .catch((err) => {
                  console.log(err);
                });
              Swal.fire({
                icon: "success",
                title: "Acción exitosa",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let ac;
      this.$data.ac != undefined ? (ac = this.$data.ac) : (ac = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      API.productosAdmin(
        this.$store.state.token,
        id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        ac,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          this.$data.total = res.data.total;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let ac;
      this.$data.ac != undefined ? (ac = this.$data.ac) : (ac = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;

      API.productosAdmin(
        this.$store.state.token,
        id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        ac,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          this.$data.total = res.data.total;
          const total = items.length;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    exportar(v) {
      if (v == true) {
        this.exportarProducto();
        this.$data.exportar = false;
      }
    },
    id(v) {
      if (v == null) {
        this.$data.id = "";
        this.filtrarDatos2();
      }
    },
    marca(v) {
      if (v == null) {
        this.$data.marca = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ean(v) {
      if (v == null) {
        this.$data.ean = "";
        this.filtrarDatos2();
      }
    },
    upc(v) {
      if (v == null) {
        this.$data.upc = "";
        this.filtrarDatos2();
      }
    },
    ac(v) {
      if (v == null) {
        this.$data.ac = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss"></style>
