var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-3 pb-0"},[_c('v-card-title',{staticClass:"pl-0 pt-2"},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Filtro Proveedores ")],1),_c('v-row',{attrs:{"justify-center":"","align-center":""}},[_c('v-col',{staticClass:"text-center",attrs:{"sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","color":"primary","placeholder":"Nombre","clearable":"","outlined":"","label":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Ingrese un ID","dense":"","hide-details":"","color":"primary","placeholder":"Ingrese un ID","clearable":"","outlined":""},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"ids"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"sm":"6","cols":"12"}},[_c('btns-filtro-component',{on:{"filterData":_vm.filtrarDatos2,"resetFilter":_vm.restablecer}})],1)],1)],1)],1),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"md":"12","lg":"12","sm":"12","cols":"12"}},[_c('base-material-card',{staticClass:"py-3",attrs:{"icon":"mdi-truck-check","title":"Proveedores","color":"primary","registros":_vm.total}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"calculate-widths":"","dense":"","no-data-text":"No existe registros en tu cuenta","headers":_vm.headers,"items":_vm.datos,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"footer-props":{
                  itemsPerPageOptions: [5, 10],
                  showFirstLastPage: true,
                  disablePagination: _vm.loading,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Registros por página',
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_vm._v(_vm._s(item.direccion_comercial))]),_c('td',[_vm._v(" "+_vm._s(item.disponibilidad != null ? item.disponibilidad : "-")+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary"},on:{"click":function($event){return _vm.verProveedores(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-arrow-right ")])]}}],null,true)},[_c('span',[_vm._v("Ver Proveedores")])])],1)])}),0)]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"text-center pa-3",staticStyle:{"position":"relative"}},[_c('v-btn',{staticClass:"btnclose",on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" x ")]),_c('v-card-title',{staticClass:"mb-3"},[_c('h3',[_vm._v("Proveedores")])]),_c('v-select',{attrs:{"items":_vm.prov,"label":"Proveedores","outline":"","item-text":"nombre","item-value":"id","outlined":"","dense":""},model:{value:(_vm.provee),callback:function ($$v) {_vm.provee=$$v},expression:"provee"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.relacionarProveedorCliente()}}},[_vm._v(" Relacionar Proveedores ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }