<template>
  <v-container fluid class="ma-0 px-4">
    <v-card class="mx-0 mt-6">
      <!-- title + icon encabezado -->
      <head-card-component
        :iconAvatar="`mdi-account-group`"
        :titleCard="`Editar Cliente`"
      />
      <!-- TABS -->
      <v-tabs color="primary" right>
        <v-tab>Editar Cliente</v-tab>
        <v-tab>Mis Cobros</v-tab>
        <v-tab>Productos</v-tab>
        <v-tab>Proveedores</v-tab>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 1 TABS -->
            <editar-cliente-form-component :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 2 TABS -->
            <cobros-edit :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 3 TABS -->
            <sub-productos :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 4 TABS -->
            <sub-proveedores :id="id" />
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import HeadCardComponent from "@/components/HeadCardComponent.vue";
import EditarClienteFormComponent from "@/views/dashboard/pages/admin/subUser/EditarClienteFormComponent.vue";
import CobrosEdit from "@/views/dashboard/pages/admin/subUser/MisCobrosEditar";
import SubProductos from "@/views/dashboard/pages/admin/subUser/Productos";
import SubProveedores from "@/views/dashboard/pages/admin/subUser/Proveedores";
var moment = require("moment");

export default {
  name: "editar-cliente",
  title: "Admin editar cliente - Onestock",
  props: ["id"],
  data: () => ({
    accion: {
      to: { name: "admin-crear-productos" },
      text: "Crear producto",
    },
    errorProd: null,
    errors: [],
    datos: [],
    no_row_data: "No hay datos",
    loading: true,
    recargaProducto: false,
    userData: {
      nombre: "",
      rut: "",
      email: "",
      password: "",
      activo: false,
      plan_id: null,
    },
    filter: {
      plan: null,
    },
    repeat: "",
    activos: false,
    valid: false,
    showPassword: false,
    showPassword2: false,
    error: [],
    rules: {
      notEmpty: [(v) => !!v || "La contraseña es requerida"],
      email: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El e-mail debe ser válido",
      ],
      rut: [
        (v) => !!v || `El Rut es requerido`,
        (v) => isRutValid(v) || `Rut inválido`,
      ],
    },
    rut: "",
  }),
  async beforeMount() {
    EventBus.$emit("cargando", true);
    await API.verClienteAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        this.$data.userData = res.data.respuesta;
        if (this.$data.userData.rut) {
          this.$data.rut = this.$data.userData.rut;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await API.listarPlanesAdmin2(this.$store.state.token)
      .then((res) => {
        this.$data.filter.plan = res.data.respuesta.data;
      })
      .catch((err) => {
        console.log(err);
      });
    let err = this.$data.errorProd;
    if (err) {
      this.$data.errors.push(err);
    }
    EventBus.$emit("cargando", false);
  },
  computed: {
    activ() {
      return this.$data.activos == true ? "Activo" : "Inactivo";
    },
   
    formatedRut: {
      get() {
        return this.formatRut(this.rut);
      },
      set(modifiedValue) {
        this.rut = modifiedValue;
      },
    },
  },
  methods: {
    editarCliente() {
      EventBus.$emit("cargando", true);
      this.$data.error = [];

      if (this.$data.rut != undefined) {
        var r = this.$data.rut.split(".").join("");
        var r2 = r.split("-").join("");
        this.$data.userData.rut = r2;
      }
      if (this.$data.userData.password == null) {
        this.$data.userData.password = "";
      }
      if (this.$refs.valid.validate()) {
        API.editarClienteAdmin(
          this.$data.userData,
          this.$store.state.token,
          this.$props.id
        )
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Acción exitosa",
              showConfirmButton: false,
              timer: 1500,
            });
            EventBus.$emit("cargando", false);
            this.$data.errors = [];
            this.$data.recargaProducto = true;
            setTimeout(() => {
              this.$data.recargaProducto = false;
            }, 1000);

            API.verClienteAdmin(this.$store.state.token, this.$props.id)
              .then((res) => {
                this.$data.userData = res.data.respuesta;
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            EventBus.$emit("cargando", false);
            this.$data.errors = [];
            let error = err.response.data;
            Object.keys(error.mensaje).forEach((el) => {
              var texto_error = "";
              if (el == "email") {
                this.$data.errors.push(error.mensaje.email[0]);
                console.log(this.$data.errors);
                texto_error =
                  texto_error + "- " + error.mensaje.email[0] + "<br/>";
              }
              if (el == "rut") {
                this.$data.errors.push(error.mensaje.rut[0]);
                texto_error = texto_error + "- " + error.mensaje.rut[0];
              }
              console.log(texto_error);
              Swal.fire({
                icon: "error",
                title: texto_error,
                showConfirmButton: false,
                timer: 2500,
              });
            });
          });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    formatRut(rut) {
      rut = rut.replace(/[^k0-9]/gim, "").split("");
      return rut.length > 1
        ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
            rut[rut.length - 1]
          }`
        : rut.join("");
    },
  },
  created() {
  },
  watch: {
    recargaProducto(v) {
      if (v == true) {
        this.$data.errors = [];
        API.verClienteAdmin(this.$store.state.token, this.$props.id)
          .then((res) => {
            console.log("recargado");
            this.$data.userData = res.data.respuesta;
            if (this.$data.userData.rut) {
              this.$data.rut = this.$data.userData.rut;
            }
          })
          .catch((err) => {
            console.log(err);
          });
        console.log("this.$data.errorProd", this.$data.errorProd);
        let err = this.$data.errorProd;
        if (err) {
          this.$data.errors.push(err);
        }
      }
    },
    activos(v) {
      v == true
        ? (this.$data.userData.activo = 1)
        : (this.$data.userData.activo = 0);
    },
  },

  components: {
    HeadCardComponent,
    EditarClienteFormComponent,
    CobrosEdit,
    SubProductos,
    SubProveedores,
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
}
</style>
<style scoped>
.ocultar-icono >>> .v-input__prepend-outer {
  display: none;
}
</style>
