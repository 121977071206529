<template>
    <v-container> 
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-card class="px-3 pb-0">
                <v-card-title class="pl-0 pt-2">
                <v-icon>mdi-magnify</v-icon>
                Filtro Cobros
                </v-card-title>
                <v-row>
                <v-col sm="6" cols="12" class="text-center">
                    <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="desde"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    label="Desde"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        dense
                        hide-details
                        v-model="desde"
                        label="Desde"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="desde"
                        type="month"
                        no-title
                        scrollable
                        locale="cl-es"
                        color="primary"
                    >
                        <v-btn text color="primary" @click="menu = false"
                        >Cancelar</v-btn
                        >
                        <v-btn text color="secondary" @click="$refs.menu.save(desde)"
                        >Aceptar</v-btn
                        >
                    </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col sm="6" cols="12" class="text-center">
                    <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="hasta"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    label="Hasta"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        dense
                        hide-details
                        v-model="hasta"
                        label="Hasta"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="hasta"
                        type="month"
                        no-title
                        scrollable
                        locale="cl-es"
                        color="primary"
                        label="Hasta"
                    >
                        <v-btn text color="primary" @click="menu2 = false"
                        >Cancelar</v-btn
                        >
                        <v-btn text color="secondary" @click="$refs.menu2.save(hasta)"
                        >Aceptar</v-btn
                        >
                    </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col sm="6" cols="12" class="text-center">
                    <v-autocomplete
                    label="Estado"
                    dense
                    hide-details
                    v-model="estado"
                    :items="filter.estado"
                    color="primary"
                    item-text="estado"
                    item-value="valor"
                    placeholder="Estado"
                    clearable
                    outlined
                    ></v-autocomplete>
                </v-col>
                <v-col sm="6" cols="12" class="text-center">
                    <!-- BOTONES FILTRAR COMPONENTE -->
                    <btns-filtro-component
                    @filterData="filtrarDatos2"
                    @resetFilter="restablecer"
                    ></btns-filtro-component>
                </v-col>
                </v-row>
            </v-card>
            </v-container>
            <v-container fluid class="pa-1">
            <v-row>
                <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
                <base-v-component
                    heading="Simple Tables"
                    link="components/simple-tables"
                    :enlaces="enlaces"
                />
                <base-material-card
                    icon="mdi-currency-usd"
                    title="Cobros"
                    class="py-3"
                    color="primary"
                    :registros="total"
                >
                    <v-data-table
                    calculate-widths
                    dense
                    no-data-text="No existen registros en tu cuenta"
                    :headers="headers"
                    :items="datos"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [5, 10], 
                        showFirstLastPage: true,
                        disablePagination: loading,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text': 'Registros por página',
                    }"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td>{{ item.id }}</td>
                            <td>{{ item.fecha_inicio_periodo }}</td>
                            <td>{{ item.total_peticiones }}</td>
                            <td>{{ item.total_cobro | currency }}</td>
                            <td>{{ item.created }}</td>
                            <td>{{ item.fecha_pagado }}</td>
                            <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="secondary"
                                    @click="verdatos(item.id)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-eye-arrow-right
                                </v-icon>
                                </template>
                                <span>Ver Cobros</span>
                            </v-tooltip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </base-material-card>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
        <div class="my-16">
            <btn-volver/>
        </div>
    </v-container>
</template>

<script>
    import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
    import API from "@/api";
    import { EventBus } from "@/main.js";
    import BtnVolver from '@/components/BtnVolver.vue';

    var moment = require("moment");
    var _ = require("underscore");
    export default {
    name: "SubMisCobros",
    props: ["id"],
    data: () => ({
        enlaces: [],
        total: 0,
        menu: false,
        menu2: false,
        reactive: true,
        readonly: true,
        loading: false,
        totalDesserts: 0,
        desserts: [],
        options: {},
        headers: [
        {
            text: "ID",
            align: "center",
            sortable: true,
            value: "id",
        },
        {
            text: "Periodo",
            align: "center",
            value: "fecha_inicio_periodo",
            sortable: false,
        },
        {
            text: "Total peticiones",
            align: "center",
            value: "total_peticiones",
            sortable: false,
        },
        {
            text: "Monto total",
            align: "center",
            value: "total_cobro",
            sortable: false,
        },
        {
            text: "Fecha creación",
            align: "center",
            value: "created",
            sortable: false,
        },
        {
            text: "Fecha pago",
            align: "center",
            value: "fecha_pagado",
            sortable: false,
        },
        {
            text: "Ver",
            align: "center",
            value: "accion",
            sortable: false,
        },
        ],
        datos: [],
        estado: "",
        desde: "",
        hasta: "",
        filter: {
        estado: [
            {
            id: 1,
            estado: "Pagado",
            valor: 1,
            },
            {
            id: 2,
            estado: "No pagado",
            valor: 0,
            },
        ],
        desde: [],
        hasta: [],
        },
    }),
    async beforeMount() {
        await this.filtrarDatos();
    },
    methods: {
        restablecer() {
        this.$data.desde = "";
        this.$data.hasta = "";
        this.$data.estado = "";
        this.filtrarDatos2();
        },
        verdatos(id) {
        this.$router.push({
            path: "/admin/ver-cobros/" + id,
        });
        },
        filtrarDatos() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        let desde;
        this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

        let hasta;
        this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

        let estado;
        this.$data.estado != undefined
            ? (estado = this.$data.estado)
            : (estado = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";

        if (sortBy[0]) {
            order = sortBy;
        }

        API.cobrosCliente(
            this.$store.state.token,
            this.$props.id,
            direction,
            order,
            desde,
            hasta,
            estado,
            page,
            itemsPerPage
        )
            .then((res) => {
            this.$data.total = res.data.respuesta.total;
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            this.$data.datos = res.data.respuesta.data;
            this.$data.datos.forEach((el) => {
                el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format(
                "DD-MM-YYYY"
                );
                el.created = moment(el.created).format("DD-MM-YYYY");
                el.fecha_pagado != null
                ? (el.fecha_pagado = moment(el.fecha_pagado).format("DD-MM-YYYY"))
                : "-";
            });
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts =
                res.data.respuesta.last_page * itemsPerPage;
            })
            .catch((err) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            console.log(err);
            });
        },
        filtrarDatos2() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);
        let desde;
        this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

        let hasta;
        this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

        let estado;
        this.$data.estado != undefined
            ? (estado = this.$data.estado)
            : (estado = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";

        if (sortBy[0]) {
            order = sortBy;
        }
        this.options.page = 1;

        API.cobrosCliente(
            this.$store.state.token,
            this.$props.id,
            direction,
            order,
            desde,
            hasta,
            estado,
            page,
            itemsPerPage
        )
            .then((res) => {
            this.$data.total = res.data.respuesta.total;
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            this.$data.datos = res.data.respuesta.data;
            this.$data.datos.forEach((el) => {
                el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format(
                "DD-MM-YYYY"
                );
                el.created = moment(el.created).format("DD-MM-YYYY");
                el.fecha_pagado != null
                ? (el.fecha_pagado = moment(el.fecha_pagado).format("DD-MM-YYYY"))
                : "-";
            });
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts =
                res.data.respuesta.last_page * itemsPerPage;
            })
            .catch((err) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            console.log(err);
            });
        },
    },
    watch: {
        options: {
        handler() {
            this.filtrarDatos();
        },
        deep: true,
        desde(v) {
            console.log("desde", v);
            if (v == null) {
            this.$data.desde = "";
            this.filtrarDatos2();
            }
        },
        hasta(v) {
            console.log("hasta", v);
            if (v == null) {
            this.$data.hasta = "";
            this.filtrarDatos2();
            }
        },
        estado(v) {
            console.log("estado", v);
            if (v == null) {
            this.$data.estado = "";
            this.filtrarDatos2();
            }
        },
        },
    },
    components: {
        BtnsFiltroComponent,
        BtnVolver,
    },
};
</script>

<style lang="css">
.v-btn {
    padding: 0px !important;
}
</style>
