import { render, staticRenderFns } from "./Mi-perfil.vue?vue&type=template&id=756f6a04&scoped=true&"
import script from "./Mi-perfil.vue?vue&type=script&lang=js&"
export * from "./Mi-perfil.vue?vue&type=script&lang=js&"
import style0 from "./Mi-perfil.vue?vue&type=style&index=0&id=756f6a04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756f6a04",
  null
  
)

export default component.exports