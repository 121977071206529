<template>
    <v-container>
        <v-row>
            <v-col lg="12">
                <v-container fluid class="pa-1">
                    <v-row>
                        <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
            <base-material-card
                    icon="mdi-align-vertical-bottom"
                    title="Editar proveedor"
                    class="px-5 py-3"
                    color="primary"
                    >
                <v-container>
                    <v-row>
                        <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            >
                            <v-form
                                ref="valid"
                                v-model="valid"
                                lazy-validation
                                autocomplete="false"
                            >
                                <v-text-field
                                v-model="proveedor_info.nombre"
                                label="Nombre"
                                :rules='rules.notEmpty'
                                required
                                clearable
                                outlined
                                placeholder="Nombre"
                                ></v-text-field>
                                <v-text-field
                                type="text"
                               
                                v-model="proveedor_info.email"
                                label="Email"
                                :rules='rules.notEmpty'
                                required
                                outlined
                                placeholder="Email"
                                clearable
                                ></v-text-field>
                                 <v-text-field
                                 autocomplete="new-password"
                                    v-model="proveedor_info.password"
                                    :type="showPassword ? 'text': 'password'"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    label="Contraseña"
                                    :rules="rules.passwordRules"
                                    outlined
                                    placeholder="Contraseña"
                                    required
                                    clearable
                                ></v-text-field>
                                <v-text-field
                                v-model="proveedor_info.direccion_comercial"
                                label="Dirección Comercial"
                                :rules='rules.notEmpty'
                                required
                                clearable
                                outlined
                                placeholder="Dirección Comercial"
                                ></v-text-field>
                                <v-file-input
                                class="ocultar-icono"
                                prepend-inner-icon="mdi-paperclip"
                                accept="image/png, image/jpeg, image/bmp , image/jpg"
                                label="Click en el recuadro para cargar el Archivo"
                                required
                                outlined
                                placeholder="Cargar Logo"
                                v-model="logo"
                                ></v-file-input>
                                <v-switch v-model="activos" class="ma-2" :label="activ"></v-switch>
                            </v-form>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="proveedor_info.disponibilidad"
                                label="Disponibilidad"
                                item-text="text"
                                item-value="id"
                                clearable
                                :items="disponibilidades"
                                outlined
                                placeholder="Disponibilidad"
                                ></v-autocomplete>
                                <v-autocomplete
                                v-model="proveedor_info.frecuencia_ftp"
                                label="frecuencia_ftp"
                                item-text="text"
                                item-value="id"
                                clearable
                                :items="frecuencias_ftp"
                                outlined
                                placeholder="Frecuencia de actualización de stock"
                                ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row v-if="errors.length > 0">
                            <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2"> 
                            <div v-if="errors.length > 0" class="">
                                <v-alert 
                                    dense
                                    style="padding:8px "
                                    text
                                    type="error"
                                    dismissible
                                        v-for="(err ,i) in errors" :key="i" 
                                    >
                                    <strong>{{err}}</strong>
                                </v-alert>
                            </div>
                            </v-col>
                        </v-row>
                                <v-btn
                                 v-if="bloqueo_boton==true"
                                color="primary"
                                class="text-center"
                                :disabled="true"
                                @click="EditarProveedor()"
                                ></v-btn>
                                <v-btn
                                v-else
                                color="primary"
                                class="text-center"
                                :disabled="!valid"
                                @click="EditarProveedor()"
                                >
                                    Editar Proveedor
                                </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'
var moment = require('moment')

export default {
    name:'editar-proveedor',
    title: 'Admin editar proveedor - Onestock',
    props:['id'],
    data:()=>({
        errors:[],
        valid:false,
        activos:false,
        logo:null,
        showPassword:false,
        bloqueo_boton:false,
        proveedor_info:{
            email:"",
            nombre:"",
            password:"",
            direccion_comercial:"",
            logo:'',
            disponibilidad:null,
            activo:0,
            ftp_host:"",
            ftp_usuario:"",
            ftp_password:"",
            ftp_puerto:"",
            frecuencia_ftp:null,
        },
        disponibilidades: [
            {
                text: 'Stock',
                id: 'stock',
            },
            {
                text: 'Binario',
                id: 'binario',
            }],
             frecuencias_ftp: [
            {
                text: 'Cada 1 hora',
                id: 1,
                
            },
            {
                text: 'Cada 2 horas',
                id: 2,
            },
            {
                text: 'Cada 3 horas',
                id: 3,
            },
            {
                text: 'Cada 4 horas',
                id: 4,
            },
            {
                text: 'Cada 6 horas',
                id: 6,
            },
            {
                text: 'Diariamente 9:00 AM',
                id: 7,
            },
            {
                text: 'Diariamente 9:00 AM y 2:00 PM',
                id: 8,
            },
            {
                text: 'Semanalmente Lunes 9:00 AM',
                id: 9,
            }],
        rules: {
             notEmpty:[
                v => !!v || 'Contraseña es requerida',
            ],
            image:[
                v => !!v || 'Avatar es requerido',
                v => (v && v.length > 0) || 'Avatar es requerido',
                value => !value.length || value.reduce((size, file) => size + file.size, 0), 2000000 || 'El tamaño no puede superar 2Mb'
            ]
        }

    }),
    async beforeMount(){
             EventBus.$emit('cargando',true)
        await API.verProveedorAdmin(this.$store.state.token,this.$props.id)
            .then((res)=>{
                this.$data.proveedor_info = res.data.data
                this.$data.proveedor_info.created = moment(res.data.data.created).format('DD-MM-YYYY')
                this.$data.proveedor_info.modified = moment(res.data.data.modified).format('DD-MM-YYYY')
               // this.$data.proveedor_info.disponibilidad = res.data.data.disponibilidad == 'stock' ? 1 : 2
                this.$data.activos = res.data.data.activo == '1' ? true : false
                     EventBus.$emit('cargando',false)
            })
            .catch((err)=>{
                     EventBus.$emit('cargando',false)
                console.log(err)
            })
    },
    methods:{
        EditarProveedor(){
             this.$data.bloqueo_boton = true
            EventBus.$emit('cargando',true)
            this.$data.errors=[]
            if(this.$data.proveedor_info.nombre == ""){
                this.$data.errors.push('Debe escribir un nombre')
            }
            this.$data.proveedor_info.logo= null
            if(this.$data.logo != null){
                this.$data.proveedor_info.logo = this.$data.logo
            }
            else{this.$data.proveedor_info.logo = ''}

            if(this.$data.proveedor_info.password == null){
                this.$data.proveedor_info.password = ""
            }

             //this.$data.proveedor_info.activo = this.$data.activos == true ? 1 : 0
            if (this.$refs.valid.validate()) {
               // console.log(this.$data.proveedor_info)
                API.editarProveedorAdmin(this.$store.state.token,this.$props.id,this.$data.proveedor_info)
                .then(()=>{
                     this.$data.bloqueo_boton = false
                    Swal.fire({
                        icon: 'success',
                        title: 'Proveedor Editado',
                        showConfirmButton: false,
                        timer: 1500
                        })
                        EventBus.$emit('cargando',false)
                     /*   setTimeout(()=>{
                            this.$router.push('/admin/proveedores')
                        },2000)*/
                })
                .catch((err)=>{
                     this.$data.bloqueo_boton = false
                    EventBus.$emit('cargando',false)
                    let error = err.response.data
                    var error_completo = ''
                    Object.keys(error.mensaje).forEach(el =>{
                       error_completo=error_completo+'-'+error.mensaje[el][0]+'</br>'
                    })
                    Swal.fire({
                        icon: 'error',
                        title: error_completo,
                        showConfirmButton: false,
                        timer: 1500
                        })
                })
            }  
        }
    },
    computed:{
        activ(){
            return this.$data.activos == true ? 'Activo' : 'Inactivo'
        }
    },
    watch:{
        'activo'(v){
            v == true
            ? this.$data.marca.activo = 1
            : this.$data.marca.activo = 0
        }
    },
  }
</script>
<style scoped>
.list-style{
    list-style: none;
}
.ocultar-icono >>> .v-input__prepend-outer{
    display: none
}
</style>
