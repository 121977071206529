<template>
    <v-row id="BtnsFiltroComponent" class="pa-0 ma-0 justify-space-around">
        <v-col class="pa-0 ma-0">
        <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-btn
            class="pa-2 ma-0"
            rounded
            :elevation="hover ? 0 : 6"
            :color="hover ? 'secondaryDark':'secondary'"
            @click="filterData">
            <v-icon>mdi-magnify</v-icon>
            Filtrar
        </v-btn>
        </v-hover>
        </v-col>
        <v-col class="pa-0 ma-0">
        <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-btn
            class="pa-0 ma-0"
            rounded
            :elevation="hover ? 0 : 6"
            :color="hover ? 'infoDark':'info'"
            @click="resetFilter">
            <v-icon>mdi-rotate-left</v-icon>
            Restablecer
        </v-btn>
        </v-hover>
        </v-col>
    </v-row>
</template>

<script>
    export default {
    name: "BtnsFiltroComponent",
    // props: {},
    data: function () {
        return {};
    },
    // computed: {},
    methods: {
        filterData(){
            this.$emit("filterData")
        },
        
        resetFilter(){
            this.$emit("resetFilter")
        }
    }
  // watch: {},
  // components: {},
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped></style>
