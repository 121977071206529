<template>
    <v-container  v-if="producto_info != 0" >
        <v-row>
            <v-col lg="12">
                 <v-container class="pa-1" fluid>
                      <v-card class="px-3 pb-0">
                    <v-row>
                        <v-col
                            md="4"
                            lg="4"
                            sm="12"
                            cols="12"
                            class="text-center elevation-0 d-block"
                            >
                            <img
                            :src="producto_info.imagen"
                            width="100%"
                            class="">
                        </v-col>
                        <v-col
                            md="8"
                            lg="8"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                            <base-material-card
                                icon="mdi-align-vertical-bottom"
                                title="Detalles Producto"
                                class="px-5 py-3"
                                color="primary"
                                >
                            <v-container>
                                <v-row>
                                    <v-simple-table
                                        dense
                                        style="width:100%">
                                        <template >
                                            <tbody>
                                                <tr>
                                                <td>Mi ID:</td>
                                                <td>{{ producto_info.id}}</td>
                                                </tr>
                                                <tr>
                                                <td>Marca:</td>
                                                <td>{{ producto_info.marca.nombre }}</td>
                                                </tr>
                                                <tr>
                                                <td>Upc:</td>
                                                <td>{{ producto_info.upc }}</td>
                                                </tr>
                                                <tr>
                                                <td>Ean:</td>
                                                <td>{{ producto_info.Ean != null ? producto_info.Ean : '-' }}</td>
                                                </tr>
                                                <tr>
                                                <td>Disponibilidad:</td>
                                                <td>{{ producto_info.disponible != null ? producto_info.disponible == false ? 'No': 'Si' : '-' }}</td>
                                                </tr>
                                                <tr>
                                                <td>Stock:</td>
                                                <td>
                                                    <div v-if="producto_info.proveedores.length > 0">
                                                        {{ producto_info.proveedores[1].pivot.stock }}
                                                    </div>
                                                    <div v-else>
                                                        -
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                     </v-simple-table>
                                </v-row>
                            </v-container>
                             </base-material-card>
                        </v-col>
                    </v-row>
                      </v-card>
                 </v-container>
            </v-col>
                    <v-col cols="12">
                        <v-container class="pa-1" fluid>
                      <v-row>
                          <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                       
                            </v-col>
                      </v-row>
                 </v-container>
                        </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600">
            <v-card class="text-center pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialog = false">
                    x
                </v-btn>
                <v-card-title>
                     <h3>Datos de proveedores</h3>
                </v-card-title>
               
            <v-data-table dense 
            no-data-text="No existe registros en tu cuenta"
            :headers="headers_proveedores"
            :items="datos2"
            class="text-center pa-4"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td>{{ item.producto_id }}</td>
                        <td>{{ item.proveedor_id }}</td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.stock }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
             </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" persistent max-width="600">
            <v-card class="text-center pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialog2 = false">
                    x
                </v-btn>
                <v-card-title>
                     <h3>Datos de proveedores</h3>
                </v-card-title>
               
            <v-data-table dense 
            no-data-text="No existe registros en tu cuenta"
            :headers="headers_clientes"
            :items="datos3"
            class="text-center pa-4"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td>{{ item.cliente_id }}</td>
                        <td>{{ item.id_interno }}</td>
                        <td>{{ item.producto_id }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
             </v-card>
        </v-dialog>
    </v-container>
    <v-container v-else>
        <v-row align-center justify-center my-3>
        <v-col
            cols="12"
            class="d-flex text-center flex-column justify-center "
            >
            <v-progress-circular
            size="60"
            width="7"
            indeterminate
            style="margin: auto"
            color="primary"
            ></v-progress-circular>
            <p>Cargando Datos</p>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import API from '@/api'
  import { EventBus } from '@/main.js'

  export default {
    name: 'proveedor-ver-producto',
    title: 'Proveedor ver producto - Onestock',
    props: ['id', 'id_proveedor'],
    data: () => ({
      producto_info: null,
      user: null,
      cards: [],
      datos2: [],
      dialog: false,
      datos3: [],
      dialog2: false,
      headers: [
        {
                text: 'Logo',
               // align: 'Center',
                sortable: true,
                value:'logo'
            },
            { 
                text: 'Nombre', 
                //align: 'Center',
                value: 'nombre', 
            },
            { 
                text: 'Dirección', 
                // align: 'Center',
                value: 'direccion_comercial', 
            },
            { 
                text: 'Disponibilidad', 
                // align: 'Center',
                value: 'disponibilidad', 
            },
            { 
                text: 'Stock', 
                // align: 'Center',
                value: 'pivot.stock', 
            },
             {
                text:'Acciones',
                 //align: 'Center',
                value:'accion'
            }
        
        ],
        headers_cliente:[
            {
                text: 'Id', 
                value: 'id',  
            },
            {
                text: 'Id Interno', 
                value: 'pivot.id_interno',  
            },
            {
                text: 'Rut', 
                value: 'rut',  
            },
            {
                text: 'Nombre', 
                value: 'nombre',  
            },
            {
                text: 'Acciones', 
                value: '',  
            },
        ],
    }),
    beforeMount () {
      EventBus.$emit('cargando', true)
      API.verProductoProveedor(this.$props.id, this.$store.state.token, this.$props.id_proveedor)
        .then((res) => {
          EventBus.$emit('cargando', false)
          this.$data.producto_info = res.data.respuesta
        })
        .catch((err) => {
          EventBus.$emit('cargando', false)
          console.log(err)
        })
    },
    methods:{
    },
    computed: {
      stock_productos () {
        return this.$data.producto_info.proveedores
          .map((el) => {
            return el.pivot.stock
          })
          .reduce((acc, val) => {
            return acc + val
          })
      },
    },
  }
</script>
<style lang="scss" >
.text{
    word-break: break-word;
}
td{
    text-align: left;
}
.centerheight{
  text-align: center !important
}
</style>