<template>
  <v-container>
    <v-row>
      <v-col
        md="12"
        lg="12"
        sm="12"
        cols="12"
        class="px-5 text-center mx-auto padd"
      >
        {{ $props.id }}
        <v-card class="pa-3">
          <h3>Para crear una relación, hay que seguir los siguientes pasos:</h3>
          <v-timeline :reverse="reverse" dense>
            <v-timeline-item class="pa-0" v-for="(n, i) in datos" :key="i">
              <template v-slot:icon>
                <v-avatar>
                  <p class="white--text p-0 mb-0">{{ i + 1 }}</p>
                </v-avatar>
              </template>
              <v-card class="elevation-0">
                <v-card-text class="text-left">
                  {{ n.texto }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <v-container>
            <v-row>
              <v-col sm="12" cols="12" md="12" lg="12">
                <div class="pa-3 text-left">
                  <v-btn color="primary" @click="obtenerarchivo()">
                    DESCARGAR PRODUCTOS
                  </v-btn>
                </div>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col sm="12" cols="12" md="8" lg="8">
                        <v-file-input
                          hide-details
                          dense
                          v-model="excel"
                          id="file"
                          label="Cargar Archivo"
                        >
                        </v-file-input>
                      </v-col>
                      <v-col sm="12" cols="12" md="4" lg="4">
                        <v-btn block @click="cargarExcel()" color="primary">
                          Cargar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div v-if="tabla_visible == true">
          <base-material-card
            icon="mdi-file-alert-outline"
            title="Errores"
            color="primary"
          >
            <v-container>
              <v-data-table
                no-data-text="No existe registros en tu cuenta"
                :headers="headers"
                :items="errors"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index" class="text-center">
                      <td>{{ item.fila }}</td>
                      <td>{{ item.columna }}</td>
                      <td>{{ item.error }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-container>
          </base-material-card>
        </div>
      </v-col>
    </v-row>
    <div id="grid"></div>
  </v-container>
</template>
<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "relacionar-masivamente",
  title: "Relacionar productos - Onestock",
  props: ["id"],
  data: () => ({
    excel: null,
    user: null,
    reverse: false,
    tabla_visible: false,
    cards: [],
    errors: [
      {
        errores: [],
        filas: [],
        columnas: [],
      },
    ],
    headers: [
      {
        text: "Fila",
        class: "text-center",
      },
      {
        text: "Columna",
        class: "text-center",
      },
      {
        text: "Error",
        class: "text-center",
      },
    ],
    datos: [
      {
        texto:
          "Para crear una relación masiva, hay que descargar el excel de productos desde el botón de abajo 'Descargar Productos' ",
      },
      {
        texto:
          "En el excel encontrará todos los productos disponibles según sus proveedores seleccionados.",
      },
      {
        texto:
          "Para relacionar los productos, complete la comumna 'MI-ID-PRODUCTO' con el identificador de producto de sus registros. Luego, pregunte por la disponibilidad del producto con su propio ID DE PRODUCTO",
      },
      {
        texto:
          "Al realizar una relación masiva de productos, se eliminan toda las otras relaciones de productos y se mantienen sólo las nuevas. Considere esto antes de continuar",
      },
    ],
  }),
  methods: {
    obtenerarchivo() {
      EventBus.$emit("cargando", true);
      API.obtenerArchivoExcel(
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          console.log(err);
        });
    },
    cargarExcel() {
      this.$data.errors = [];
      EventBus.$emit("cargando", true);
      let formData = new FormData();
      formData.append("archivo", this.$data.excel);
      API.relacionmasivaProductos(
        formData,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.excel = "";
          this.$data.tabla_visible = false;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          let error = err.response.data;
          var texto_error = "El archivo contiene errores!";

          if (error) {
            for (const element in error.datos) {
              this.$data.errors.push({
                error: error.datos[element].errores["0"],
                fila: error.datos[element].fila,
                columna: error.datos[element].columna,
              });
            }
            Swal.fire({
              icon: "error",
              title: texto_error,
              showConfirmButton: false,
              timer: 2000,
            });
            if (error.mensaje.extension == undefined) {
              this.$data.tabla_visible = true;
              EventBus.$emit("cargando", false);
            } else {
              this.$data.tabla_visible = false;
              EventBus.$emit("cargando", false);
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
