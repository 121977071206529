var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"md":"12","lg":"12","sm":"12","cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-align-vertical-bottom","title":"Errores","color":"primary"}},[(this.$props.Errores != null)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"text-align-last":"center"},attrs:{"no-data-text":"no_row_data","headers":_vm.headers,"items":this.$props.Errores,"footer-props":{
                                itemsPerPageOptions: [10, 25, 50], 
                                showFirstLastPage: true,
                                disablePagination:_vm.loading,
                                firstIcon: 'mdi-arrow-collapse-left',
                                lastIcon: 'mdi-arrow-collapse-right',
                                prevIcon: 'mdi-minus',
                                nextIcon: 'mdi-plus',
                                'items-per-page-text':'Registros por página'
                            }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.fila))]),_c('td',[_vm._v(_vm._s(item.columna))]),_c('td',[_vm._v(_vm._s(item.errores[0]))]),_c('td')])}),0)]}}],null,false,2448114844)})],1):_vm._e()])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }