<template>
  <!-- ENCABEZADO -->
  <v-card class="mx-4 mt-16">
    <div class="d-flex align-center mb-16">
      <v-avatar
        class="mt-n6 ml-3 pa-8 text-center"
        height="90"
        color="primary"
        tile
      >
        <v-icon size="32" dark x-large v-text="iconAvatar" />
      </v-avatar>
      <h3 class="ml-10 my-6 greyDark--text" v-text="titleCard" />
    </div>

    <!-- INICIO DE TABS -->

    <!-- VIÑETA 1er TABS CARGAR -->

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" color="secondary">
          DESCARGAR
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- VIÑETA 2do TABS DESCARGAR -->
        <v-stepper-step :complete="e1 > 2" step="2" color="secondary">
          CARGAR
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- VIÑETA 3er TABS CARGAR -->
        <v-stepper-step step="3" color="secondary"> RESULTADO </v-stepper-step>
      </v-stepper-header>

      <!-- CUERPO 1er TABS CARGAR -->
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12 py-16" color="greyLight" min-height="400px">
            <v-card-text class="subtitle-1 text-center greyDark--text">
              Descargue el archivo excel en el botón
              <span class="infoDark--text text-uppercase">
                ‘DESCARGAR ARCHIVO’.</span
              >
            </v-card-text>

            <v-card-text class="subtitle-1 text-center greyDark--text">
              Luego, seleccione el botón
              <span class="secondaryDark--text text-uppercase">
                ‘CONTINUAR’.</span
              >
            </v-card-text>

            <v-card-text class="text-center redDark--text">
              Recuerde permitir las ventanas emergentes en su navegador.
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
              <v-btn
                class="mt-6"
                rounded
                color="info"
                @click="onDownloadExcelBtn"
                v-text="`DESCARGAR ARCHIVO`"
              />
            </v-card-actions>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn color="red" rounded @click="$router.go(-1)">
              Cancelar
            </v-btn>
            <v-btn rounded color="secondary" @click="e1 = 2"> Continuar </v-btn>
          </div>
        </v-stepper-content>

        <!-- CUERPO 2do TABS DESCCARGAR -->
        <v-stepper-content step="2">
          <v-card class="mb-12 py-16" color="greyLight" min-height="400px">
            <v-card-text class="subtitle-1 text-center greyDark--text">
              Después de editar y guardar el archivo excel, súbalo en
              <span class="infoDark--text text-uppercase">
                ‘SELECCIONAR ARCHIVO’.</span
              >
            </v-card-text>

            <v-card-text class="subtitle-1 text-center greyDark--text">
              Luego, seleccione el botón
              <span class="secondaryDark--text text-uppercase">
                ‘CONTINUAR’.</span
              >
            </v-card-text>

            <v-card-text class="redDark--text text-center">
              IMPORTANTE
              <ul class="lista text-left">
                <li>
                  No modifique las cabeceras del excel, ya que generará error.
                </li>
                <li>
                  Son opcionales los campos: Upc, Ean, FTP (en caso de ser solicitado).
                </li>
              </ul>
            </v-card-text>

            <v-card-actions class="d-flex justify-center align-center px-16">
              <v-file-input
                v-model="excel"
                id="file"
                label="Archivo seleccionado"
                placeholder="Archivo seleccionado"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
              >
              </v-file-input>

              <v-btn @click="onBtnInputExcel" color="info" rounded>
                SELECCIONAR ARCHIVO
              </v-btn>
            </v-card-actions>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn rounded color="red" @click="e1 = 1"> Cancelar </v-btn>
            <v-btn
              rounded
              @click="onUploadExcelBtn"
              color="secondary"
              v-text="`CONTINUAR`"
              v-if="excel"
            />
          </div>
        </v-stepper-content>

        <!-- CUERPO 3er TABS CARGAR  true, response.ok-->
        <v-stepper-content step="3" v-if="loading"> </v-stepper-content>
        <v-stepper-content step="3" v-else-if="okApi">
          <v-card class="mb-12 py-16" color="greyLight" min-height="400px">
            <v-card-text
              class="display-1 text-center greyDark--text text-uppercase"
            >
              RESULTADO
            </v-card-text>
            <v-card-text
              class="title text-center secondaryDark--text pt-12 pb-6"
              v-text="messageApi"
            />
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="(error, index) in errorsApi"
                :key="index"
                color="redDark"
                icon-color="white"
                small
                fill-dot
                icon="mdi-close-thick"
              >
                {{ error }}
              </v-timeline-item>
            </v-timeline>
            <v-card-text
              class="title text-center text-center secondaryDark--text mb-10"
            >
              Para volver al inicio, seleccione el botón
              <span class="infoDark--text text-uppercase"> ‘FINALIZAR’</span>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-end">
            <v-btn rounded color="info" @click="$router.go(-1)"
              >Finalizar</v-btn
            >
          </div>
        </v-stepper-content>

        <!-- OPCION ERROR PARA 3er TABS -->
        <v-stepper-content step="3" v-else>
          <v-card class="mb-12 py-16" color="greyLight" min-height="400px">
            <v-card-text
              class="display-1 text-center greyDark--text"
            >
              RESULTADO
            </v-card-text>

            <v-card-text class="title text-center redDark--text pt-12 pb-6">
              El archivo NO se cargó
              <v-icon color="redDark">mdi-emoticon-confused</v-icon>
            </v-card-text>
            <v-card-text
              class="title text-center redDark--text pt-3 pb-0 pt-6 pb-6"
              v-text="messageApi"
            >
              <!-- RESPUESTA DE ERROR: -->
            </v-card-text>
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="(error, index) in errorsApi"
                :key="index"
                color="redDark"
                icon-color="white"
                small
                fill-dot
                icon="mdi-close-thick"
              >
                {{ error }}
              </v-timeline-item>
            </v-timeline>

            <v-card-text class="title secondaryDark--text text-center">
              Para intentarlo de nuevo, corrija los errores y seleccione el
              botón
              <span class="secondaryDark--text text-center text-uppercase">
                'VOLVER AL INICIO'.
              </span>
            </v-card-text>

            <v-card-text class="title secondaryDark--text text-center">
              Si quiere cerrar, seleccione el botón
              <span class="infoDark--text text-uppercase"> ‘FINALIZAR’ </span>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn rounded color="secondary" @click="$router.go()">
              Volver al Inicio
            </v-btn>
            <v-btn rounded color="info" @click="$router.go(-1)">
              Finalizar
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { EventBus } from "@/main.js";
import BtnVolver from "@/components/BtnVolver.vue";
export default {
  name: "InfoMasivosComponent",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    responseApi: {
      type: Object,
      default: () => ({
        estado: null,
        mensaje: null,
      }),
    },
    iconAvatar: {
      type: String,
      default: "mdi-lightbulb",
    },
    titleCard: {
      type: String,
      default: "Creación/Edición Masiva",
    },
  },
  data: function () {
    return {
      e1: 1,
      excel: null,
    };
  },
  computed: {
    okApi() {
      return this.responseApi.estado == 200;
    },
    messageApi() {
      return `${this.responseApi.mensaje} ${this.errorsApi.length ? ":" : ""}`;
    },
    errorsApi() {
      let allErrors = [];
      const respuesta = this.responseApi.respuesta;
      const datos = this.responseApi.datos;
      if (respuesta) {
        for (const item in respuesta) {
          const errores = respuesta[item].errores;
          errores && allErrors.push(...errores);
        }
      } else if (datos) {
        for (const item in datos) {
          const errores = datos[item].errores;
          errores && allErrors.push(...errores);
        }
      }
      return allErrors;
    },
  },
  methods: {
    onUploadExcelBtn() {
      this.$emit("onUploadExcelBtn", this.excel);
      this.e1 = 3;
      EventBus.$emit("cargando", true);
      this.excel = null;
    },
    onDownloadExcelBtn() {
      this.$emit("onDownloadExcelBtn");
    },
    onBtnInputExcel() {
      document.querySelector("#file").click();
    },
  },
  watch: {
    loading(newValor) {
      !newValor && EventBus.$emit("cargando", false);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped>
.lista {
  width: max-content;
  margin: 0 auto;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-bottom: 6px;
}
</style>
