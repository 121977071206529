<template>
    <v-container fluid>
        <v-row>
        <v-col lg="12">
            <v-container class="pa-1" fluid>
            <v-card class="px-3 pb-0">
                <v-card-title class="pl-0 pt-2">
                <v-icon>mdi-magnify</v-icon>
                Filtro
                </v-card-title>
                <v-row>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model="mi_id"
                    color="primary"
                    placeholder="Mi ID"
                    label="Mi ID"
                    clearable
                    outlined
                    hide-details
                    dense
                    ></v-text-field>
                </v-col>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-autocomplete
                    v-model="marca"
                    :items="filter.marca"
                    color="primary"
                    item-text="nombre"
                    item-value="id"
                    placeholder="Marca"
                    label="Marca"
                    clearable
                    outlined
                    hide-details
                    dense
                    ></v-autocomplete>
                </v-col>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model="nombre"
                    color="primary"
                    placeholder="Nombre"
                    label="Nombre"
                    clearable
                    outlined
                    hide-details
                    dense
                    ></v-text-field>
                </v-col>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model="ean"
                    color="primary"
                    placeholder="Ean"
                    clearable
                    hide-details
                    maxlength="13"
                    :rules="rules.numeros"
                    label="Ean"
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-text-field
                    v-model="upc"
                    color="primary"
                    placeholder="Upc"
                    clearable
                    maxlength="12"
                    :rules="rules.numeros"
                    outlined
                    label="Upc"
                    hide-details
                    dense
                    ></v-text-field>
                </v-col>
                <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                    <v-container class="pa-0 ma-0" fluid>
                    <v-layout class="pa-0 ma-0">
                        <v-col cols="12" md="10" lg="10" sm="10" class="pa-0 ma-0">
                        <v-btn block color="primary" @click="filtrarDatos2">
                            Filtrar
                        </v-btn>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" sm="2" class="pa-0 ma-0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                color="green"
                                v-bind="attrs"
                                v-on="on"
                                @click="restablecer"
                            >
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            </template>
                            <span>Restablecer Filtro</span>
                        </v-tooltip>
                        </v-col>
                    </v-layout>
                    </v-container>
                </v-col>
                </v-row>
            </v-card>
            </v-container>
            <v-container fluid class="pa-1">
            <v-row>
                <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
                <!--v-container
                                class="pa-0"-->
                <base-material-card
                    icon="mdi-tag-multiple"
                    title="Productos"
                    class="py-3"
                    color="primary"
                    :registros="total"
                    acciones="true"
                    relacionar="relacionar-masivamente"
                    :enlaces="enlaces"
                    @ExportarProductoStock="ExportarProductoStock = $event"
                >
                    <v-data-table
                    dense
                    no-data-text="No existe registros en tu cuenta"
                    :headers="headers"
                    :items="datos"
                    :options.sync="options"
                    :server-items-length="totalDesserts"
                    :loading="loading"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [5, 10],
                        showFirstLastPage: true,
                        disablePagination: loading,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text': 'Registros por página',
                    }"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td>{{ item.producto_info.mi_id }}</td>
                                                                -->
                            <td>{{ item.producto_info.nombre }}</td>
                            <td>
                            {{
                                item.producto_info.marca != null
                                ? item.producto_info.marca
                                : "-"
                            }}
                            </td>
                            <td>
                            {{
                                item.producto_info.upc != null
                                ? item.producto_info.upc
                                : "-"
                            }}
                            </td>
                            <td>
                            {{
                                item.producto_info.ean != null
                                ? item.producto_info.ean
                                : "-"
                            }}
                            </td>
                            <td>
                            {{
                                item.producto_info.codigo_interno != null
                                ? item.producto_info.codigo_interno
                                : "-"
                            }}
                            </td>
                            <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="secondary"
                                    @click="
                                    verProveedores(item.detalle_proveedores)
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-eye-arrow-right-outline
                                </v-icon>
                                </template>
                                <span>Ver Proveedores</span>
                            </v-tooltip>
                            </td>
                            <td>
                            <v-tooltip
                                bottom
                                v-if="item.producto_info.mi_id != null"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="secondaryDark"
                                    medium
                                    block
                                    @click="verProducto(item.producto_info.mi_id)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-eye-arrow-right
                                </v-icon>
                                </template>
                                <span>Ver Producto</span>
                            </v-tooltip>
                            </td>
                            <td>
                            <v-tooltip
                                bottom
                                v-if="item.producto_info.mi_id == null"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="info"
                                    @click="
                                    relacionarProducto(item.producto_info.id)
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-link</v-icon
                                >
                                </template>
                                <span>Relacionar</span>
                            </v-tooltip>

                            <v-tooltip
                                bottom
                                v-if="item.producto_info.mi_id != null"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="red"
                                    @click="
                                    quitarRelacionProducto(item.producto_info.id)
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-autorenew-off</v-icon
                                >
                                </template>
                                <span>Quitar Relación</span>
                            </v-tooltip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </base-material-card>
                </v-col>
            </v-row>
            </v-container>
        </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600">
        <v-card class="text-center pa-3" style="position: relative">
            <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
            <v-card-title>
            <h3>Datos de proveedores</h3>
            </v-card-title>

            <v-data-table
            dense
            no-data-text="No existen registros en tu cuenta"
            :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
            :headers="headers2"
            :items="datos2"
            class="text-center pa-4"
            >
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="item in items" :key="item.name">
                    <td>{{ item.id }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.direccion_comercial }}</td>
                </tr>
                </tbody>
            </template>
            </v-data-table>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import API from "@/api";
    import Swal from "sweetalert2";
    import { EventBus } from "@/main.js";

    export default {
    name: "productos",
    title: "Productos - Onestock",
    data: () => ({
        ExportarProductoStock: false,
        enlaces: [
        {
            text: "Relacionar Masivamente",
            to: {
            name: "relacionar-masivamente",
            },
        },

        {
            text: "Exportar productos con stock",
            to: {
            name: "ExportarProductoStock",
            export: true,
            },
            function: "exportarProductosclientesstockv2",
        },
        ],
        dialog: false,
        total: 0,
        totalDesserts: 0,
        desserts: [],
        options: {},
        loading: false,
        headers: [
        {
            text: "Mi ID",
            align: "Center",
            sortable: true,
            value: "mi_id",
        },
        {
            text: "Nombre",
            align: "Center",
            value: "nombre",
            sortable: false,
        },
        {
            text: "Marca",
            align: "Center",
            value: "marca",
        },
        {
            text: "Upc",
            align: "Center",
            value: "upc",
            sortable: false,
        },
        {
            text: "Ean",
            align: "Center",
            value: "ean",
            sortable: false,
        },
        {
            text: "Cód interno",
            align: "Center",
            value: "id_interno",
            sortable: false,
        },
        {
            text: "Ver Proveedor",
            align: "Center",
            value: "ver",
            sortable: false,
        },
        {
            text: "Ver Producto",
            align: "Center",
            value: "accion",
            sortable: false,
        },
        {
            text: "Acciones Relac.",
            align: "Center",
            value: "accion",
            sortable: false,
        },
        ],
        headers2: [
        {
            text: "ID",
            align: "Center",
            sortable: true,
            value: "id",
        },
        {
            text: "Nombre",
            align: "Center",
            value: "nombre",
        },
        {
            text: "Dirección",
            align: "Center",
            value: "direccion_comercial",
        },
        ],
        datos: [],
        datos2: [],
        filter: {
        mi_id: [],
        marca: [],
        nombre: [],
        ean: [],
        upc: [],
        },
        mi_id: "",
        marca: "",
        nombre: "",
        ean: "",
        upc: "",
        bloqueo_boton: false,
        rules: {
        numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
        },
    }),
    async beforeMount() {
        await API.listarMarcas(this.$store.state.token)
        .then((res) => {
            this.$data.filter.marca = res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
    },
    methods: {
        exportarProductosclientesstockv2() {
        this.$data.bloqueo_boton = true;
        EventBus.$emit("cargando", true);
        API.exportarProductosclientesstockv2(
            this.$store.state.user.id,
            this.$store.state.token
        )
            .then((res) => {
            window.open(res.data.respuesta);
            this.$data.bloqueo_boton = false;
            EventBus.$emit("cargando", false);
            })
            .catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
            });
            this.$data.bloqueo_boton = false;
            EventBus.$emit("cargando", false);
            });
        },
        restablecer() {
        this.$data.mi_id = "";
        this.$data.marca = "";
        this.$data.nombre = "";
        this.$data.ean = "";
        this.$data.upc = "";
        this.filtrarDatos2();
        },
        verProveedores(proveedores) {
        this.$data.datos2 = proveedores;
        this.$data.dialog = true;
        },
        verProducto(id) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton:
                "v-btn v-btn--contained v-size--default v-application primary bgsucces",
            cancelButton:
                "v-btn  theme--light v-size--default v-application primary bgcancel",
            title: "title-class",
            text: "title-class",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
            title: "Se pueden generar cobros",
            text: "¿Desea continuar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, continuar!",
            cancelButtonText: "No!",
            reverseButtons: true,
            })
            .then((result) => {
            let routeData = this.$router.resolve({
                path: "/clientes/productos/ver-producto/" + id,
            });
            window.open(routeData.href, "_blank");
            })
            .catch((err) => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
            });
            });
        },
        relacionarProducto(id_prod) {
        Swal.fire({
            customClass: {
            confirmButton:
                "v-btn v-btn--contained v-size--default v-application primary bgsucces",
            cancelButton:
                "v-btn  theme--light v-size--default v-application primary bgcancel",
            title: "title-class",
            input: "input-class",
            },
            title: "Ingrese su ID",
            input: "text",
            inputAttributes: {
            autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Registrar",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: (login) => {
            API.relacionarProducto(
                id_prod,
                login,
                this.$store.state.token,
                this.$store.state.user.id
            )
                .then((res) => {
                this.filtrarDatos();
                Swal.fire({
                    icon: "success",
                    title: "Datos Relacionados",
                    showConfirmButton: false,
                    timer: 1500,
                });
                })
                .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: `El id_interno ingresado ya tiene relación con otro producto`,
                    showConfirmButton: false,
                    timer: 1500,
                });
                });
            },
        });
        },
        quitarRelacionProducto(id_prod) {
        API.quitarRelacionProducto(
            id_prod,
            this.$store.state.user.id,
            this.$store.state.token
        )
            .then((res) => {
            Swal.fire({
                icon: "success",
                title: "Datos Actualizados",
                showConfirmButton: false,
                timer: 1500,
            });
            this.filtrarDatos();
            })
            .catch((err) => {
            console.log(err);
            });
        },
        filtrarDatos() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);

        let marca_id;
        this.$data.marca != undefined
            ? (marca_id = this.$data.marca)
            : (marca_id = "");

        let ean;
        this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

        let upc;
        this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";

        if (sortBy[0]) {
            order = sortBy;
        }
        API.productosClientes(
            this.$store.state.user.id,
            this.$store.state.token,
            direction,
            order,
            this.$data.mi_id != undefined ? this.$data.mi_id : "",
            this.$data.nombre != undefined ? this.$data.nombre : "",
            marca_id,
            ean,
            upc,
            page,
            itemsPerPage
        )
            .then((res) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;

            this.$data.datos = res.data.productos;
            this.$data.total = res.data.total;
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;
            this.$data.filter.mi_id = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.mi_id != null
                ? this.$data.filter.mi_id.push(el.producto_info.mi_id)
                : "";
            });
          
            this.$data.filter.nombre = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.nombre != null
                ? this.$data.filter.nombre.push(el.producto_info.nombre)
                : "";
            });
            this.$data.filter.ean = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.ean != null
                ? this.$data.filter.ean.push(el.producto_info.ean)
                : "";
            });
            this.$data.filter.upc = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.upc != null
                ? this.$data.filter.upc.push(el.producto_info.upc)
                : "";
            });
            })
            .catch((err) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            console.log(err);
            });
        },
        filtrarDatos2() {
        this.$data.loading = true;
        EventBus.$emit("cargando", true);

        let marca_id;
        this.$data.marca != undefined
            ? (marca_id = this.$data.marca)
            : (marca_id = "");

        let ean;
        this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

        let upc;
        this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let direction = "asc";
        sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

        let order = "id";

        if (sortBy[0]) {
            order = sortBy;
        }

        this.options.page = 1;
        API.productosClientes(
            this.$store.state.user.id,
            this.$store.state.token,
            direction,
            order,
            this.$data.mi_id != undefined ? this.$data.mi_id : "",
            this.$data.nombre != undefined ? this.$data.nombre : "",
            marca_id,
            ean,
            upc,
            1,
            itemsPerPage
        )
            .then((res) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;

            this.$data.datos = res.data.productos;
            this.$data.total = res.data.total;
            let items = this.$data.datos;
            const total = items.length;
            this.$data.totalDesserts = res.data.last_page * itemsPerPage;
            this.$data.filter.mi_id = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.mi_id != null
                ? this.$data.filter.mi_id.push(el.producto_info.mi_id)
                : "";
            });
           
            this.$data.filter.nombre = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.nombre != null
                ? this.$data.filter.nombre.push(el.producto_info.nombre)
                : "";
            });
            this.$data.filter.ean = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.ean != null
                ? this.$data.filter.ean.push(el.producto_info.ean)
                : "";
            });
            this.$data.filter.upc = [];
            res.data.productos.forEach((el, i) => {
                el.producto_info.upc != null
                ? this.$data.filter.upc.push(el.producto_info.upc)
                : "";
            });
            })
            .catch((err) => {
            EventBus.$emit("cargando", false);
            this.$data.loading = false;
            console.log(err);
            });
        },
    },
    watch: {
        options: {
        handler() {
            this.filtrarDatos();
        },
        deep: true,
        },
        ExportarProductoStock(v) {
        if (v == true) {
            this.exportarProductosclientesstockv2();
            this.$data.ExportarProductoStock = false;
        }
        },
        mi_id(v) {
        if (v == null) {
            this.$data.mi_id = "";
            this.filtrarDatos2();
        }
        },
        marca(v) {
        if (v == null) {
            this.$data.marca = "";
            this.filtrarDatos2();
        }
        },
        nombre(v) {
        if (v == null) {
            this.$data.nombre = "";
            this.filtrarDatos2();
        }
        },
        ean(v) {
        if (v == null) {
            this.$data.ean = "";
            this.filtrarDatos2();
        }
        },
        upc(v) {
        if (v == null) {
            this.$data.upc = "";
            this.filtrarDatos2();
        }
        },
    },
};
</script>
<style lang="scss"></style>
