<template>
    <v-container>
        <v-row>
            <v-col lg="12">               
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                           md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                           
                            >
                        <base-material-card
                            icon="mdi-clipboard-check"
                            title="Detalles"
                            class="px-5 py-3"
                            color="primary"
                            >
                         <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                               <tr>
                                   <td>
                                       Periodo
                                   </td>
                                   <td>
                                       {{datos.cobro.nombre | moment('DD-MM-YYYY')}}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Total Peticiones
                                   </td>
                                   <td>
                                       {{datos.cobro.total_peticiones}}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Monto Total
                                   </td>
                                   <td>
                                       {{datos.cobro.total_cobro | currency}}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Inicio Periodo
                                   </td>
                                   <td>
                                       {{datos.cobro.fecha_inicio_periodo | moment('DD-MM-YYYY')}}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Fin Periodo
                                   </td>
                                   <td>
                                       {{datos.cobro.fecha_termino_periodo | moment('DD-MM-YYYY') }}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Estado
                                   </td>
                                   <td>
                                       {{datos.cobro.pagado == 1 ? 'Pagado' : 'No Pagado'}}
                                   </td>
                               </tr>
                               <tr>
                                   <td>
                                       Comentario
                                   </td>
                                   <td>
                                       {{datos.cobro.observaciones }}
                                   </td>
                               </tr>
                            </tbody>
                            </template>
                        </v-simple-table> 
                        </base-material-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                             <base-material-card
                            icon="mdi-clipboard-check"
                            title="Peticiones"
                            class="px-5 py-3"
                            color="primary"
                            >
                            <v-data-table  
                            dense
                                no-data-text="No existe registros en tu cuenta"
                                :items-per-page="3"
                                :headers="headers"
                                :items="datos.peticiones"
                                class=" text-center"
                                :footer-props="{
                                        'items-per-page-text':'Registros por página'
                                    }"
                                >
                               <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.name">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.token }}</td>
                                        <td>{{ item.codigo_respuesta }}</td>
                                         <td>{{ item.ip_peticion }}</td>
                                        <td>{{ item.precio | currency}}</td>
                                         <td>{{ item.created | moment('DD-MM-YYYY')}}</td>
                                        <td> 
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="blue darken-2 ma-1" medium block @click="verjson(item.respuesta)"  v-bind="attrs" v-on="on">
                                                        mdi-code-json
                                                    </v-icon>
                                                </template>
                                                <span>Ver JSON</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                                </v-data-table>
                             </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
               
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600">
            <v-card class=" pa-3" style="position: relative;">
                <v-btn class="btnclose" @click="dialog = false">
                    x
                </v-btn>
                <v-card-title>
                     <h3>Datos JSON</h3>
                </v-card-title>
              <vue-json-pretty
               highlightMouseoverNode 
               collapsedOnClickBrackets 
                :data="json"
                @click="handleClick">
                </vue-json-pretty> 
             </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/api'
import VueJsonPretty from 'vue-json-pretty'
import {EventBus} from '@/main.js'
var moment = require('moment')


export default {
    name:'mis-cobros',
    title: 'Mis cobros - Onestock',
    props:['id'],
    data:()=>({
        json:null,
        dialog:false,
        headers: [
            {
                text: 'Producto',
                align: 'Center',
                sortable: false,
                value:'id'
            },
            { 
                text: 'Token', 
                 align: 'Center',
                value: 'token',
                sortable: false,
            },
            { 
                text: 'Cod respuesta', 
                 align: 'Center',
                value: 'total_peticiones',
                sortable: false,
            },
            { 
                text: 'IP petición', 
                 align: 'Center',
                value: 'total_cobro',
                sortable: false,
            },
            { 
                text: 'Precio', 
                 align: 'Center',
                value: 'created',
                sortable: false,
            },
            { 
                text: 'Fecha Creación', 
                 align: 'Center',
                value: 'fecha_pagado',
                sortable: false,
            },
            {
                text:'Json respuesta',
                align: 'Center',
                value:'accion',
                sortable: false,
            }
        
        ],
              desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
          },
          {
            name: 'Eclair',
            calories: 262,
          },
          {
            name: 'Cupcake',
            calories: 305,
          },
          {
            name: 'Gingerbread',
            calories: 356,
          },
          {
            name: 'Jelly bean',
            calories: 375,
          },
          {
            name: 'Lollipop',
            calories: 392,
          },
          {
            name: 'Honeycomb',
            calories: 408,
          },
          {
            name: 'Donut',
            calories: 452,
          },
          {
            name: 'KitKat',
            calories: 518,
          },
        ],
        datos:[]

    }),
  async  beforeMount(){
        EventBus.$emit('cargando',true)
    await API.verCobroproducto(this.$props.id,this.$store.state.token)
        .then((res)=>{
             EventBus.$emit('cargando',false)
            this.$data.datos= res.data.respuesta
         })
        .catch((err)=>{
            console.log(err)
        })
    },methods:{
        verjson(json){
            this.$data.json = json
            this.$data.dialog = true

             this.$data.json = JSON.parse(json) 
           
            console.log(json)
        }
    },components:{
        VueJsonPretty
    }
    
}
</script>
<style lang="scss" >

</style>