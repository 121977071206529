<template>
  <v-container fluid class="ma-0 px-4">
    <info-masivos-component
      iconAvatar="mdi-tag-multiple"
      titleCard="Relación Masiva de Productos"
      :loading="loading"
      @onDownloadExcelBtn="obtenerarchivo"
      @onUploadExcelBtn="cargarExcel"
      :responseApi="responseApi"
    />

    <div id="grid"></div>
  </v-container>
</template>

<script>
import InfoMasivosComponent from "@/components/InfoMasivosComponent.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "relacionar-masivamente",
  title: "Admin relacionar proveedores productos - Onestock",
  props: ["id"],
  data: () => ({
    excel: null,
    user: null,
    errors: [],
    error: null,
    loading: true,
    reverse: false,
    cards: [],
    responseApi: {},
    datos: [
      {
        texto:
          "Para crear una relación masiva, hay que descargar el excel desde el botón de abajo 'Descargar Formato'",
      },
      {
        texto:
          "En el excel encontrará las filas id-producto y sku que son necesarias para crear la relación",
      },
      {
        texto: "Todos los campos son obligatorios",
      },
    ],
  }),
  methods: {
    goback() {
      this.$router.go(-1);
    },
    obtenerarchivo() {
      EventBus.$emit("cargando", true);
      API.obtenerArchivoExcel(this.$props.id, this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          console.log(err);
        });
    },
    async cargarExcel(excel) {
      this.$data.excel = excel;
      this.$data.errors = [];
      EventBus.$emit("cargando", true);
      let formData = new FormData();
      formData.append("archivo", this.$data.excel);
      await API.relacionmasivaProductos(
        formData,
        this.$props.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: `${res.data.mensaje}`,
            showConfirmButton: false,
            timer: 2000,
          });
          this.$data.excel = "";
          this.responseApi = res.data;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          this.responseApi = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    "info-masivos-component": InfoMasivosComponent,
  },
  mounted(){
    console.log(this.$route)
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
