<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col md="8" offset-lg="2" offset-md="2" offset-sm="0" lg="8" sm="12">
        <base-material-card
          icon="mdi-file-powerpoint-outline"
          title="Editar Plan"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row>
              <v-col sm="12" cols="12">
                <v-form
                  ref="valid"
                  v-model="valid"
                  lazy-validation
                  autocomplete="false"
                >
                  <v-text-field
                    v-model.trim="plan.nombre"
                    label="Nombre"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Nombre"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="plan.descripcion"
                    label="Descripción"
                    :rules="rules.notEmpty"
                    required
                    outlined
                    placeholder="Descripción"
                    clearable
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="plan.precio_peticion"
                    label="Precio petición"
                    :rules="rules.notEmpty"
                    required
                    outlined
                    placeholder="Precio petición"
                    clearable
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="plan.limite_diario"
                    label="Límite diario"
                    :rules="rules.notEmpty"
                    required
                    placeholder="Límite diario"
                    outlined
                    clearable
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="plan.precio_sobre_consumo"
                    label="Precio sobreconsumo"
                    :rules="rules.notEmpty"
                    required
                    clearable
                    outlined
                    placeholder="Precio sobreconsumo"
                  ></v-text-field>

                  <div class="d-flex justify-space-between mb-16">
                    <v-switch
                      v-model="activo"
                      class="ma-2"
                      :label="activ"
                    ></v-switch>
                    <v-switch
                      v-model="ilimitado"
                      class="ma-2"
                      :label="ilimit"
                    ></v-switch>
                  </div>
                  <v-row v-if="errors.length > 0">
                    <v-col cols="12">
                      <div v-if="errors.length > 0" class="">
                        <v-alert
                          dense
                          style="padding: 8px"
                          text
                          type="error"
                          dismissible
                          v-for="(err, i) in errors"
                          :key="i"
                        >
                          <strong>{{ err }}</strong>
                        </v-alert>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-space-around mb-10">
                    <btn-volver />
                    <v-hover v-slot:default="{ hover }" open-delay="200">
                      <v-btn
                        :elevation="hover ? 0 : 6"
                        :color="hover ? 'secondaryDark' : 'secondary'"
                        rounded
                        class="text-center"
                        :disabled="!valid"
                        @click="Editarplan()"
                      >
                        Guardar
                      </v-btn>
                    </v-hover>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "editar-plan",
  title: "Admin editar plan - Onestock",
  props: ["id"],
  data: () => ({
    errors: [],
    valid: false,
    activo: false,
    ilimitado: false,
    plan: {
      nombre: "",
      activo: 0,
      descripcion: "",
      precio_peticion: "",
      limite_diario: "",
      ilimitado: 0,
      precio_sobre_consumo: "",
    },
    filter: {
      plan: null,
    },
    rules: {
      notEmpty: [(v) => !!v || "Este campo es requerido"],
    },
  }),
  async beforeMount() {
    await API.verPlanAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        this.$data.plan = res.respuesta;
        this.$data.activo = res.respuesta.activo == 0 ? false : true;
        this.$data.ilimitado = res.respuesta.ilimitado == 0 ? false : true;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    Editarplan() {
      this.$data.errors = [];
      if (this.$data.plan.nombre == "") {
        this.$data.errors.push("Debe escribir un nombre");
      }

      API.editarPlanAdmin(
        this.$store.state.token,
        this.$props.id,
        this.$data.plan
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });
          EventBus.$emit("cargando", false);
          setTimeout(() => {
            this.$router.push("/admin/planes");
          }, 2000);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.errors = [];
          Swal.fire({
            icon: "error",
            title: "Error al editar plan",
            showConfirmButton: false,
            timer: 1500,
          });
          let error = err.response.data;
          console.log(error);
          Object.keys(error.mensaje).forEach((el) => {
            if (el == "precio_peticion") {
              this.$data.errors.push(
                `Precio petición ${error.mensaje.precio_peticion[0]}`
              );
            }
            if (el == "limite_diario") {
              this.$data.errors.push(
                `Límite diario ${error.mensaje.limite_diario[0]}`
              );
            }
          });
        });
      // }
    },
  },
  computed: {
    activ() {
      return this.$data.activo == true ? "Activo" : "Inactivo";
    },
    ilimit() {
      return this.$data.ilimitado == true ? "Ilimitado" : "limitado";
    },
  },
  watch: {
    activo(v) {
      v == true ? (this.$data.plan.activo = 1) : (this.$data.plan.activo = 0);
    },
    ilimitado(v) {
      v == true
        ? (this.$data.plan.ilimitado = 1)
        : (this.$data.plan.ilimitado = 0);
    },
  },
  components: {
    BtnVolver,
  },
};
</script>
<style lang="scss">
.list-style {
  list-style: none;
}
</style>
