<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Productos
            </v-card-title>
            <v-row>
              <v-col cols="6" class="text-center">
                <v-autocomplete
                  hide-details
                  dense
                  outlined
                  label="Marca"
                  v-model="marca"
                  :items="marcaSort"
                  color="primary"
                  item-text="nombre"
                  item-value="id"
                  placeholder="Marca"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  outlined
                  label="Nombre"
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  label="Sku"
                  v-model="sku"
                  placeholder="Sku"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Upc"
                      hide-details
                      dense
                      outlined
                      v-model="upc"
                      color="primary"
                      placeholder="Upc"
                      clearable
                      maxlength="12"
                      :rules="rules.numeros"
                    ></v-text-field>
                  </template>
                  <span>Código Universal de Producto</span>
                </v-tooltip>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Ean"
                      hide-details
                      dense
                      outlined
                      v-model="ean"
                      color="primary"
                      placeholder="Ean"
                      clearable
                      maxlength="13"
                      :rules="rules.numeros"
                    ></v-text-field>
                  </template>
                  <span>Número de Artículo Europeo</span>
                </v-tooltip>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-container class="pa-0 ma-0" fluid>
                  <div class="d-flex">
                    <!-- BOTONES FILTRAR COMPONENTE -->
                    <btns-filtro-component
                      @filterData="filtrarDatos2"
                      @resetFilter="restablecer"
                    >
                    </btns-filtro-component>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-tag-multiple"
                title="Productos"
                class="py-3"
                color="primary"
                :registros="total"
                acciones="true"
                relacionar="relacionar-masivamente"
                :enlaces="enlaces"
                :showBtn="{ export: true, add: true }"
                @onExportBtn="exportarProducto"
                @onAddBtn="agregarProducto"
              >
                <v-data-table
                  dense
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    disablePagination: loading,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.producto_id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>
                          {{ item.marca != null ? item.marca.nombre : "-" }}
                        </td>
                        <td>{{ item.stock != null ? item.stock : "-" }}</td>
                        <td>{{ item.sku != null ? item.sku : "-" }}</td>
                        <td>{{ item.upc != null ? item.upc : "-" }}</td>
                        <td>{{ item.ean != null ? item.ean : "-" }}</td>

                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verProducto(item.producto_id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver Producto</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red"
                                @click="
                                  quitarRelacionProveedor(item.producto_id)
                                "
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-autorenew-off</v-icon
                              >
                            </template>
                            <span>Quitar Relación</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
              <v-row v-if="errors.length > 0">
                <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                  <div v-if="errors.length > 0" class="">
                    <v-alert
                      dense
                      style="padding: 8px"
                      text
                      type="error"
                      dismissible
                      v-for="(err, i) in errors"
                      :key="i"
                    >
                      <strong>{{ err }}</strong>
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
              <!--/v-container-->
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>

        <v-data-table
          dense
          no-data-text="No existen registros en tu cuenta"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
          :headers="headers2"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.direccion_comercial }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="verProducts" persistent max-width="800">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="verProducts = false"> x </v-btn>
        <v-card-title>
          <h3>Buscar Productos</h3>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="ID"
                v-model="buscar_id"
                placeholder="ID"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="Nombre"
                v-model="buscar_nom"
                placeholder="Nombre"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
              <v-text-field
                hide-details
                dense
                label="Código interno"
                v-model="buscar_cod_int"
                placeholder="Código interno"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
              <v-btn
                rounded
                dense
                block
                color="secondary"
                @click="buscarClientes"
                >Buscar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="datosClientes.length > 0">
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <v-data-table
                dense
                no-data-text="No existen registros en tu cuenta"
                :headers="headersbuscarClientes"
                :items="datosClientes"
                :options.sync="options2"
                :server-items-length="totalDesserts2"
                :loading="loading2"
                class="elevation-1"
                :footer-props="{
                  itemsPerPageOptions: [5, 10],
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <td>{{ item.id }}</td>

                      <td>
                        {{
                          item.marca.nombre != null ? item.marca.nombre : "-"
                        }}
                      </td>
                      <td>{{ item.nombre }}</td>

                      <td>
                        {{
                          item.codigo_interno != null
                            ? item.codigo_interno
                            : "-"
                        }}
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="info"
                              @click="desplegarProveedor(item.id)"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-link</v-icon
                            >
                          </template>
                          <span>Relacionar</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-row v-if="errors2.length > 0">
                <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                  <div v-if="errors2.length > 0" class="">
                    <v-alert
                      dense
                      style="padding: 8px"
                      text
                      type="error"
                      dismissible
                      v-for="(err, i) in errors2"
                      :key="i"
                    >
                      <strong>{{ err }}</strong>
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRegistrar" persistent max-width="600">
      <v-card class="pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialogRegistrar = false"> x </v-btn>
        <v-card-title class="py-5"> </v-card-title>
        <base-material-card
          icon="mdi-truck-check"
          title="Agregar nuevo proveedor"
          class="px-5 py-3"
          color="primary"
        >
          <v-container>
            <v-row>
              <v-col md="12" lg="12" sm="12" cols="12">
                <v-form
                  ref="valid"
                  v-model="valid"
                  lazy-validation
                  autocomplete="false"
                >
                  <v-text-field
                    v-model="prod.sku"
                    label="Sku"
                    :rules="rules.notEmpty"
                    required
                    clearable
                  ></v-text-field>
                  <v-text-field
                    v-model="prod.stock"
                    label="Stock"
                    :rules="rules.notEmpty"
                    required
                    clearable
                  ></v-text-field>

                  <v-btn
                    rounded
                    color="secondary"
                    class="text-center"
                    :disabled="!valid"
                    @click="registrarProveedor()"
                  >
                    Registrar Proveedor
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "SubEditarProductosProveedores",
  props: ["id"],
  data: () => ({
    valid: "",
    enlaces: [
      {
        text: "Relacionar masivamente",
        to: {
          name: "relacion-masiva-producto",
          params: { name: ["id"] },
        },
      },
      {
        text: "Quitar relación masiva",
        to: {
          name: "quitar-relacion-masiva-producto",
          params: { name: ["id"] },
        },
      },
    ],
    datosClientes: [],
    totalDesserts2: 0,
    desserts2: [],
    loading2: false,
    options2: {},
    totalDesserts3: 0,
    desserts3: [],
    errors2: [],
    errors: [],
    loading3: false,
    options3: {},
    datosCClientes: [],
    dialog: false,
    dialog2: false,
    buscar_id: null,
    buscar_nom: null,
    buscar_cod_int: null,
    buscarc_id: null,
    buscarc_nom: null,
    buscarc_rut: null,
    buscarc_email: null,
    verProducts: false,
    verclients: true,
    dialogRegistrarClientes: false,
    dialogRegistrar: false,
    prod: {
      id: null,
      sku: null,
      stock: 0,
    },
    total: 0,
    totalDesserts: 0,
    desserts: [],
    options: {},
    loading: false,
    headers: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
      },
      {
        text: "Marca",
        align: "Center",
        value: "marca",
        sortable: false,
      },
      {
        text: "Stock",
        align: "Center",
        value: "stock",
        sortable: false,
      },
      {
        text: "Sku",
        align: "Center",
        value: "sku",
        sortable: false,
      },
      {
        text: "Upc",
        align: "Center",
        value: "upc",
        sortable: false,
      },
      {
        text: "Ean",
        align: "Center",
        value: "ean",
        sortable: false,
      },
      {
        text: "Ver",
        align: "Center",
        value: "",
        sortable: false,
      },
      {
        text: "Quitar Rel",
        align: "Center",
        value: "",
        sortable: false,
      },
    ],
    headers2: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
      },
      {
        text: "Dirección",
        align: "Center",
        value: "direccion_comercial",
      },
    ],
    headersbuscarClientes: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Marca",
        align: "Center",
        value: "marca",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "ID interno",
        align: "Center",
        value: "id_interno",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "Center",
        value: "accion",
        sortable: false,
      },
    ],

    headersbuscarClientes: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Marca",
        align: "Center",
        value: "marca",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "ID interno",
        align: "Center",
        value: "id_interno",
        sortable: false,
      },
      {
        text: "Relacionar",
        align: "Center",
        value: "accion",
        sortable: false,
      },
    ],
    headersbuscarClientes: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "Center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Activo",
        align: "Center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "Center",
        value: "created",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "Center",
        value: "email",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "Center",
        value: "accion",
        sortable: false,
      },
    ],
    datos: [],
    datos2: [],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
      sku: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    upc: "",
    sku: "",
    rules: {
      numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
    },
  }),
  async beforeMount() {
    await API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.filter.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.filtrarDatos();
  },
  computed: {
    marcaSort() {
      return this.filter.marca?.sort((a, b) =>
        a.nombre.localeCompare(b.nombre)
      );
    },
  },
  methods: {
    restablecer() {
      this.$data.sku = "";
      this.$data.marca = "";
      this.$data.nombre = "";
      this.$data.ean = "";
      this.$data.upc = "";
      this.filtrarDatos2();
    },
    desplegarProveedor(id) {
      this.dialogRegistrar = true;
      this.$data.prod.id = id;
    },
    registrarProveedor() {
      API.relacionarNuevoProveedorProveedor(
        this.$store.state.token,
        this.$props.id,
        this.$data.prod
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Proveedor Registrado",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$data.dialog = false;
          this.$data.dialogRegistrar = false;
          this.$data.verProducts = false;
          this.filtrarDatos();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    buscarClientes() {
      this.$data.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options2;
      API.buscarClientesProveedor(
        this.$store.state.token,
        this.$data.buscar_id != undefined ? this.$data.buscar_id : "",
        this.$data.buscar_nom != undefined ? this.$data.buscar_nom : "",
        this.$data.buscar_cod_int != undefined ? this.$data.buscar_cod_int : "",
        page
      ).then((res) => {
        this.$data.errors2 = [];
        this.$data.loading = false;
        this.$data.datosClientes = res.data;
        this.$data.totalDesserts2 = res.last_page * itemsPerPage;
        if (this.$data.datosClientes.length <= 0) {
          this.$data.errors2.push("Producto no encontrado");
        }
      });
    },
    agregarProducto() {
      this.$data.verProducts = true;
    },
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportarProdProvee(this.$props.id, this.$store.state.token)
        .then((res) => {
          window.open(res.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          Swal.fire({
            icon: "error",
            title: "Error al exportar",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    verProveedores(proveedores) {
      this.$data.datos2 = proveedores;
      this.$data.dialog = true;
    },
    verProducto(id) {
      this.$router.push({
        path: "/admin/ver-producto/" + id,
      });
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bginfo",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              this.filtrarDatos();
              Swal.fire({
                icon: "success",
                title: "Datos Relacionados",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProveedor(id) {
      API.quitarRelacionProveedores(id, this.$store.state.token, this.$props.id)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.errors = [];
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let marca_id;
      //  marca_id = this.$data.marca
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let sku;
      this.$data.sku != undefined ? (sku = this.$data.sku) : (sku = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      API.listarProductosProveedores(
        this.$store.state.token,
        this.$props.id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        sku,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.datos = res.data.respuesta.data;

          this.$data.datos.forEach((el, i) => {
            var marca = this.$data.filter.marca.find(
              (marca) => marca.id == el.marca_id
            );
            this.$data.datos[i].marca = marca;
          });

          this.$data.total = res.data.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];

          res.data.respuesta.data.forEach((el, i) => {
            el.mi_id != null ? this.$data.filter.mi_id.push(el.mi_id) : "";
          });
          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Producto no encontrado");
          }
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let marca_id;
      //  marca_id = this.$data.marca
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let sku;
      this.$data.sku != undefined ? (sku = this.$data.sku) : (sku = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      this.options.page = 1;

      API.listarProductosProveedores(
        this.$store.state.token,
        this.$props.id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        sku,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.datos = res.data.respuesta.data;

          this.$data.datos.forEach((el, i) => {
            var marca = this.$data.filter.marca.find(
              (marca) => marca.id == el.marca_id
            );
            this.$data.datos[i].marca = marca;
          });

          this.$data.total = res.data.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];

          if (this.$data.datos.length <= 0) {
            this.$data.errors.push("Producto no encontrado");
          }
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    options2: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    options3: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    verProducts(v) {
      v == false ? (this.$data.datosClientes = []) : "";
    },
    sku(v) {
      if (v == null) {
        this.$data.sku = "";
        this.filtrarDatos2();
      }
    },
    marca(v) {
      if (v == null) {
        this.$data.marca = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ean(v) {
      if (v == null) {
        this.$data.ean = "";
        this.filtrarDatos2();
      }
    },
    upc(v) {
      if (v == null) {
        this.$data.upc = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
  },
};
</script>
<style lang="scss"></style>
