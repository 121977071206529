<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col lg="12" class="d-flex">
              <div class="pl-3">
                <div>
                  <h4>{{ userData.plan.nombre }}</h4>
                </div>
                <div>
                  <h4>
                    <span class="primary--text">
                      {{ userData.productos_relacionados }}
                    </span>
                    Productos relacionados
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-row>
              <v-col
                md="6"
                lg="6"
                sm="12"
                cols="12"
                offset-sm="0"
                class="text-center"
              >
                <base-material-card
                  icon="mdi-account"
                  title="Datos de cliente"
                  class="py-3"
                  color="primary"
                >
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    autocomplete="false"
                  >
                    <v-container>
                      <v-row>
                        <v-simple-table
                          calculate-widths
                          dense
                          style="width: 100%"
                        >
                          <template>
                            <tbody>
                              <tr>
                                <td>Id</td>
                                <td>{{ userData.id }}</td>
                              </tr>
                              <tr>
                                <td>Nombre</td>
                                <td>{{ userData.nombre }}</td>
                              </tr>
                              <tr>
                                <td>E-mail</td>
                                <td>{{ userData.email }}</td>
                              </tr>
                              <tr>
                                <td>rut</td>
                                <td>{{ userData.rut | rutFilter }}</td>
                              </tr>
                              <tr>
                                <td>Fecha creacion</td>
                                <td>
                                  {{
                                    userData.fecha_creacion
                                      | moment("DD-MM-YYYY")
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Ultima modificación</td>
                                <td>
                                  {{
                                    userData.ultima_modificacion
                                      | moment("DD-MM-YYYY")
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Productos relacionados</td>
                                <td>{{ userData.productos_relacionados }}</td>
                              </tr>
                              <tr>
                                <td>Activo</td>
                                <td>
                                  {{ userData.activo == 1 ? "Si" : "No" }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-row>
                    </v-container>
                  </v-form>
                </base-material-card>
              </v-col>
              <v-col md="6" lg="6" sm="12" cols="12" class="text-center padd">
                <base-material-card
                  icon="mdi-equalizer"
                  title="Consumo"
                  class="py-3"
                  color="primary"
                >
                  <v-container>
                    <v-row>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>Gastado en el mes</td>
                              <td>
                                {{
                                  userData.peticiones.gastado_este_mes
                                    | currency
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Gastado hoy</td>
                              <td>
                                {{ userData.peticiones.gastado_hoy | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Gastado mes anterior</td>
                              <td>
                                {{
                                  userData.peticiones.gastado_mes_anterior
                                    | currency
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Peticiones este mes</td>
                              <td>
                                {{
                                  userData.peticiones.peticiones_este_mes
                                    | currency
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Peticiones hoy</td>
                              <td>{{ userData.peticiones.peticiones_hoy }}</td>
                            </tr>
                            <tr>
                              <td>Peticiones mes anterior</td>
                              <td>
                                {{
                                  userData.peticiones.peticiones_mes_anterior
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Peticiones restantes hoy</td>
                              <td>
                                {{
                                  userData.peticiones.peticiones_restantes_hoy
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
              <v-col
                md="6"
                lg="6"
                sm="12"
                cols="12"
                offset-sm="0"
                class="text-center padd"
              >
                <base-material-card
                  icon="mdi-file-powerpoint-outline"
                  title="Mi Plan"
                  class="py-3"
                  color="primary"
                >
                  <v-container>
                    <v-row>
                      <v-simple-table
                        calculate-widths
                        dense
                        style="width: 100%"
                      >
                        <template>
                          <tbody>
                            <tr>
                              <td>Nombre</td>
                              <td>{{ userData.plan.nombre }}</td>
                            </tr>
                            <tr>
                              <td>Descripción</td>
                              <td>{{ userData.plan.descripcion }}</td>
                            </tr>
                            <tr>
                              <td>Precio x petición</td>
                              <td>
                                {{ userData.plan.precio_x_producto | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Límite diario</td>
                              <td>{{ userData.plan.limite_diario }}</td>
                            </tr>
                            <tr>
                              <td>Plan ilimitado</td>
                              <td>
                                {{
                                  userData.plan.plan_ilimitado == false
                                    ? "No"
                                    : "Si"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Precio x sobre consumo</td>
                              <td>
                                {{
                                  userData.plan.precio_sobre_consumo | currency
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
              <v-col
                md="6"
                lg="6"
                sm="12"
                cols="12"
                offset-sm="0"
                class="text-center padd"
              >
                <base-material-card
                  icon="mdi-currency-usd"
                  title="Cobros"
                  class="py-3"
                  color="primary"
                >
                  <v-container>
                    <v-row>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>Total adeudado</td>
                              <td>
                                {{ userData.cobros.total_adeudado | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Total pagado</td>
                              <td>
                                {{ userData.cobros.total_pagado | currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Último pago</td>
                              <td>
                                {{
                                  userData.cobros.ultimo_pago.length > 0
                                    ? userData.cobros.ultimo_pago[0].total_cobro
                                    : "0" | currency
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="12" class="text-left">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col lg="12" class="text-left">
              <btn-volver />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import { isRutValid } from "@/utils";
import { EventBus } from "@/main.js";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
var moment = require("moment");

export default {
  name: "VerClienteFormComponent",
  title: "Admin ver cliente - Onestock",
  props: ["id"],
  data: () => ({
    accion: {
      to: { name: null },
      text: "Exportar",
    },
    datos: [],
    datos2: [],
    userData: {
      nombre: "",
      rut: "",
      email: "",
      password: "",
      avatar: "",
    },
    totalDesserts: 0,
    desserts: [],
    no_row_data: "No hay datos",
    loading: true,
    options: {},
    totalDesserts2: 0,
    desserts2: [],
    no_row_data: "No hay datos",
    loading2: true,
    options2: {},
    cambiar_password: false,
    repeat: "",
    valid: true,
    showPassword: false,
    showPassword2: false,
    error: null,
    headers: [
      {
        text: "Cliente ID",
        align: "center",
        sortable: true,
        value: "cliente_id",
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "Inicio periodo",
        align: "center",
        value: "fecha_inicio_periodo",
        sortable: false,
      },
      {
        text: "Fecha pagado",
        align: "center",
        value: "fecha_pagado",
        sortable: false,
      },
      {
        text: "Termino periodo",
        align: "center",
        value: "fecha_termino_periodo",
        sortable: false,
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Observaciones",
        align: "center",
        value: "observaciones",
        sortable: false,
      },

      {
        text: "Pagado",
        align: "center",
        value: "pagado",
        sortable: false,
      },
      {
        text: "Razón",
        align: "center",
        value: "razon_reversa",
        sortable: false,
      },
      {
        text: "Cobros",
        align: "center",
        value: "total_cobro",
        sortable: false,
      },
      {
        text: "Consumo",
        align: "center",
        value: "total_peticiones",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    headers2: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },

      {
        text: "Marca",
        align: "center",
        value: "marca",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Upc",
        align: "center",
        value: "upc",
        sortable: false,
      },
      {
        text: "Ean",
        align: "center",
        value: "ean",
        sortable: false,
      },
      {
        text: "ID interno",
        align: "center",
        value: "id_interno",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    upc: "",
    rules: {
      notEmpty: [(v) => !!v || "Este campo es requerido"],
      email: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El e-mail debe ser válido",
      ],
      passwordRules: [(v) => !!v || "La contraseña es requerida"],
      rut: [
        (v) => !!v || `El Rut es requerido`,
        (v) => isRutValid(v) || `Rut inválido`,
      ],
    },
  }),
  async beforeMount() {
    await API.verClienteAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        this.$data.userData = res.data.respuesta;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ...mapActions(["RegisterUser"]),
    editarCliente() {
      API.editarCliente(this.$data.userData, this.$store.state.token)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs.form.reset();
          API.verClienteAdmin(this.$store.state.token)
            .then((res) => {
              this.RegisterUser(res.data.respuesta);
              this.$data.userData = res.data.respuesta;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let desde;
      this.$data.desde != undefined ? (desde = this.$data.desde) : (desde = "");

      let hasta;
      this.$data.hasta != undefined ? (hasta = this.$data.hasta) : (hasta = "");

      let pagado;
      this.$data.pagado != undefined
        ? (pagado = this.$data.pagado)
        : (pagado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "desc";
      sortDesc[0] != true ? (direction = "desc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }

      API.listarCobrosAdmin(
        this.$store.state.token,
        desde,
        hasta,
        pagado,
        order,
        direction,
        page,
        itemsPerPage
      ).then((res) => {
        this.$data.loading = false;
        EventBus.$emit("cargando", false);
        this.$data.datos = res.respuesta.data;
        this.$data.datos.forEach((el) => {
          el.created = moment(el.created).format("DD-MM-YYYY");
          el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format(
            "DD-MM-YYYY"
          );
          el.fecha_termino_periodo = moment(el.fecha_termino_periodo).format(
            "DD-MM-YYYY"
          );
          el.fecha_pagado != null
            ? (el.fecha_pagado = moment(el.fecha_pagado).format("DD-MM-YYYY"))
            : null;
        });
        let items = this.$data.datos;
        const total = items.length;
        this.$data.totalDesserts = res.respuesta.last_page * itemsPerPage;
      });
    },
    verCobros(id) {
      this.$router.push("/admin/ver-cobros/" + id);
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      API.productosClientes(
        this.$store.state.user.id,
        this.$store.state.token,
        direction,
        order,
        this.$data.mi_id != undefined ? this.$data.mi_id : "",
        this.$data.nombre != undefined ? this.$data.nombre : "",
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.datos2 = res.data.productos;
          this.$data.total = res.data.total;
          let items = this.$data.datos2;
          const total = items.length;
          this.$data.totalDesserts2 = res.data.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.mi_id != null
              ? this.$data.filter.mi_id.push(el.producto_info.mi_id)
              : "";
          });
          this.$data.filter.nombre = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.nombre != null
              ? this.$data.filter.nombre.push(el.producto_info.nombre)
              : "";
          });
          this.$data.filter.ean = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.ean != null
              ? this.$data.filter.ean.push(el.producto_info.ean)
              : "";
          });
          this.$data.filter.upc = [];
          res.data.productos.forEach((el, i) => {
            el.producto_info.upc != null
              ? this.$data.filter.upc.push(el.producto_info.upc)
              : "";
          });
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
        });
    },
    verCobros(id) {
      this.$router.push("/admin/ver-cobros/" + id);
    },
    goback() {
      this.$router.go(-1);
    },
  },
  watch: {
    repeat(v) {
      this.$data.userData.password != v
        ? (this.$data.error = "Las contraseñas no coinciden")
        : (this.$data.error = null);
    },
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    options2: {
      handler() {
        this.filtrarDatos2();
      },
      deep: true,
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
}
</style>
