<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col lg="12" class="d-flex">
              <div class="pl-3">
                <div>
                  <h4>
                    <span class="primary--text">Estado: </span>
                    {{ proveedor_info.activo == 1 ? "Activo" : "Inactivo" }}
                  </h4>
                </div>
                <div>
                  <h4>
                    <span class="primary--text">
                      {{ proveedor_info.productos }}
                    </span>
                    Productos relacionados
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <v-container>
                <v-row v-if="errors.length > 0">
                      <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2">
                        <div v-if="errors.length > 0" class="">
                          <v-alert
                            dense
                            style="padding: 8px"
                            text
                            type="error"
                            dismissible
                            v-for="(err, i) in errors"
                            :key="i"
                          >
                            <strong>{{ err }}</strong>
                          </v-alert>
                        </div>
                      </v-col>
                    </v-row>
                <v-row>
                  <v-col md="6" lg="6" sm="12" cols="12">
                    <v-form ref="valid" v-model="valid" autocomplete="false">
                      <v-text-field
                        v-model.trim="proveedor_info.nombre"
                        label="Nombre"
                        :rules="rules.notEmpty"
                        required
                        clearable
                        outlined
                        placeholder="Nombre"
                      ></v-text-field>
                      <v-text-field
                        v-model.trim="proveedor_info.email"
                        label="E-mail"
                        :rules="rules.email"
                        required
                        outlined
                        placeholder="E-mail"
                        clearable
                      ></v-text-field>
                      <v-text-field
                        v-model.trim="proveedor_info.password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        label="Contraseña"
                        outlined
                        placeholder="Contraseña"
                        required
                        clearable
                      ></v-text-field>
                      <v-text-field
                        class="pb-16 mb-6"
                        v-model.trim="proveedor_info.direccion_comercial"
                        label="Dirección comercial"
                        :rules="rules.notEmpty"
                        required
                        clearable
                        outlined
                        placeholder="Dirección comercial"
                      ></v-text-field>

                      <div class="d-flex flex-column align-start">
                        Estado del Proveedor
                        <v-switch
                          v-model="activos"
                          class="ma-2"
                          :label="activ"
                        ></v-switch>
                      </div>
                    </v-form>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="proveedor_info.disponibilidad"
                      label="Disponibilidad"
                      item-text="text"
                      item-value="id"
                      clearable
                      :items="disponibilidades"
                      outlined
                      placeholder="Disponibilidad"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="proveedor_info.ftp_host"
                      label="ftp_host (opcional)"
                      required
                      clearable
                      outlined
                      placeholder="ftp_host"
                    ></v-text-field>
                    <v-text-field
                      v-model="proveedor_info.ftp_usuario"
                      label="ftp_usuario (opcional)"
                      required
                      clearable
                      outlined
                      placeholder="ftp_usuario"
                    ></v-text-field>
                    <v-text-field
                      v-model="proveedor_info.ftp_password"
                      label="ftp_password (opcional)"
                      required
                      outlined
                      placeholder="ftp_password"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      v-model="proveedor_info.ftp_puerto"
                      label="ftp_puerto (opcional)"
                      required
                      outlined
                      placeholder="ftp_puerto"
                      clearable
                    ></v-text-field>

                    <div class="d-flex flex-column align-end">
                      Permitido Actualizar Stock
                      <v-switch
                        color="primary"
                        v-model="permitir_actualizar_stock"
                        class="ma-2"
                        :label="editStock"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    

                    <div class="d-flex my-16 justify-space-around">
                      <btn-volver />
                      <v-hover v-slot:default="{ hover }" open-delay="200">
                        <v-btn
                          :elevation="hover ? 0 : 6"
                          :color="hover ? 'secondaryDark' : 'secondary'"
                          rounded
                          class="text-center"
                          :disabled="!valid"
                          @click="EditarProveedor()"
                        >
                          Guardar
                        </v-btn>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnVolver from "@/components/BtnVolver.vue";
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
var moment = require("moment");

export default {
  name: "EditProveedorFormComponent",
  title: "Admin editar proveedor - Onestock",
  props: ["id"],
  data: () => ({
    permitir_actualizar_stock: null,
    errors: [],
    valid: false,
    activos: false,
    logo: null,
    showPassword: false,
    proveedor_info: {
      email: "",
      nombre: "",
      password: "",
      direccion_comercial: "",
      logo: "",
      disponibilidad: null,
      activo: 0,
      ftp_host: "",
      ftp_usuario: "",
      ftp_password: "",
      ftp_puerto: "",
      frecuencia_ftp: null,
    },
    disponibilidades: [
      {
        text: "Stock",
        id: "stock",
      },
      {
        text: "Binario",
        id: "binario",
      },
    ],
    rules: {
      notEmpty: [(v) => {
        return (typeof v == "number") || !!v?.trim() || "Este campo es requerido"
        }],
      email: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El e-mail debe ser válido",
      ],
    },
  }),
  async beforeMount() {
    EventBus.$emit("cargando", true);
    await API.verProveedorAdmin(this.$store.state.token, this.$props.id)
      .then((res) => {
        this.$data.proveedor_info = res.data.data;
        this.$data.proveedor_info.created = moment(
          res.data.data.created
        ).format("DD-MM-YYYY");
        this.$data.proveedor_info.modified = moment(
          res.data.data.modified
        ).format("DD-MM-YYYY");
        this.$data.activos = res.data.data.activo == "1" ? true : false;

        this.permitir_actualizar_stock =
          res.data.data.permitir_actualizar_stock == "1" ? true : false;
      })
      .catch((err) => {
        console.log(err);
      });
      EventBus.$emit("cargando", false);
  },
  methods: {
    EditarProveedor() {
      this.proveedor_info.frecuencia_ftp ??= "";
      EventBus.$emit("cargando", true);
      this.$data.errors = [];
      if (this.$data.proveedor_info.nombre == "") {
        this.$data.errors.push("Debe escribir un nombre");
      }

      if (this.$data.proveedor_info.password == null) {
        this.$data.proveedor_info.password = "";
      }

      this.$data.proveedor_info.activo = this.$data.activos ? 1 : 0;

      this.$data.proveedor_info.permitir_actualizar_stock = Number(
        this.permitir_actualizar_stock
      );

      if (this.$refs.valid.validate()) {
        API.editarProveedorAdmin(
          this.$store.state.token,
          this.$props.id,
          this.$data.proveedor_info
        )
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Acción exitosa",
              showConfirmButton: false,
              timer: 1500,
            });
            EventBus.$emit("cargando", false);
          })
          .catch((err) => {
            EventBus.$emit("cargando", false);
            let error = err.response.data;
            console.log(error);
            if (error.mensaje.email) {
              this.$data.errors.push("Debe cambiar el e-mail");
            }
            if (error.mensaje.logo) {
              this.$data.errors.push(error.mensaje.logo[1]);
              this.$data.proveedor_info.logo = "";
            }
          });
      }
    },
  },
  computed: {
    activ() {
      return this.$data.activos == true ? "Activo" : "Inactivo";
    },
    editStock() {
      return Boolean(this.proveedor_info.permitir_actualizar_stock)
        ? "Sí"
        : "No";
    },
  },
  watch: {
    activo(v) {
      v == true ? (this.$data.marca.activo = 1) : (this.$data.marca.activo = 0);
    },
  },
  components: {
    "btn-volver": BtnVolver,
  },
};
</script>
<style lang="scss">
.list-style {
  list-style: none;
}
</style>
