<template>
  <v-container fluid>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col
              md="3"
              lg="3"
              sm="12"
              cols="12"
              class="text-center mx-auto padd"
              v-for="card in cards"
              :key="card.id"
            >
              <base-material-stats-card
                v-if="
                  card.id != 8 && card.id != 6 && card.id != 4 && card.id != 2
                "
                color="secondary"
                icon="mdi-align-vertical-bottom"
                title=""
                :value="card.peticiones"
                sub-icon="mdi-clock"
                :sub-text="card.title"
              />
              <base-material-stats-card
                v-else
                color="secondary"
                icon="mdi-align-vertical-bottom"
                title=""
                :value="card.peticiones | currency"
                sub-icon="mdi-clock"
                :sub-text="card.title"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "dashboard",
  title: "Dashboard - Onestock",
  data: () => ({
    alert: false,
    user: null,
    cards: [
      {
        id: 1,
        title: "Total peticiones hoy",
        peticiones: null,
      },
      {
        id: 2,
        title: "Monto gastado hoy",
        peticiones: null,
      },
      {
        id: 3,
        title: "Total peticiones periodo actual",
        peticiones: null,
      },
      {
        id: 4,
        title: "Monto gastado periodo actual",
        peticiones: null,
      },
      {
        id: 5,
        title: "Total peticiones periodo anterior",
        peticiones: null,
      },
      {
        id: 6,
        title: "Monto gastado periodo anterior",
        peticiones: null,
      },
      {
        id: 7,
        title: "Peticiones restantes hoy",
        peticiones: null,
      },
      {
        id: 8,
        title: "Total adeudado hasta la fecha",
        peticiones: null,
      },
    ],
    peticiones: [],
  }),
  beforeMount() {
    EventBus.$emit("cargando", true);
    API.verCliente(this.$store.state.token, this.$store.state.user.id)
      .then((res) => {
        this.$data.peticiones = res.data.respuesta;
        this.$data.cards.forEach((el) => {
          el.id == 1
            ? (el.peticiones = this.$data.peticiones.peticiones.peticiones_hoy)
            : "";
          el.id == 2
            ? (el.peticiones = `${new Intl.NumberFormat(["ban", "id"]).format(
                this.$data.peticiones.peticiones.gastado_hoy
              )} `)
            : "";
          el.id == 3
            ? (el.peticiones =
                this.$data.peticiones.peticiones.peticiones_este_mes)
            : "";
          el.id == 4
            ? (el.peticiones = `${new Intl.NumberFormat(["ban", "id"]).format(
                this.$data.peticiones.peticiones.gastado_este_mes
              )} `)
            : "";
          el.id == 5
            ? (el.peticiones =
                this.$data.peticiones.peticiones.peticiones_mes_anterior)
            : "";
          el.id == 6
            ? (el.peticiones = `${new Intl.NumberFormat(["ban", "id"]).format(
                this.$data.peticiones.peticiones.gastado_mes_anterior
              )} `)
            : "";
          el.id == 7
            ? (el.peticiones =
                this.$data.peticiones.peticiones.peticiones_restantes_hoy)
            : "";
          el.id == 8
            ? (el.peticiones = this.$data.peticiones.cobros.total_adeudado)
            : "";
        });
        let n = parseInt(this.$data.peticiones.peticiones.gastado_mes_anterior);
        console.log(new Intl.NumberFormat(["ban", "id"]).format(n));
        EventBus.$emit("cargando", false);
      })
      .catch((err) => {
        console.log(err);
        EventBus.$emit("cargando", false);
        Swal.fire({
          icon: "error",
          title: "Error, intente de nuevo",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  },
};
</script>
<style lang="scss" scoped>
.text {
  word-break: break-word;
}
</style>
