<template>
  <v-container fluid class="ma-0 px-4">
    <v-card class="mx-16 mt-16">
      <!-- title + icon encabezado -->
      <head-card-component
        :iconAvatar="`mdi-truck-check`"
        :titleCard="`Ver Proveedor`"
      />
      <!-- TABS -->
      <v-tabs color="primary" right>
        <v-tab>Ver Proveedor</v-tab>
        <v-tab>Productos / Provdr.</v-tab>
        <v-tab>Clientes / Provdr.</v-tab>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 1 TABS -->
            <ver-proveedor-form-component :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 2 TABS -->
            <productos-proveedores :id="id" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <!-- CONTENIDO 3 TABS -->
            <clientes-proveedores :id="id" />
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import HeadCardComponent from "@/components/HeadCardComponent.vue";
import VerProveedorFormComponent from "@/views/dashboard/pages/admin/subUser/VerProveedorFormComponent.vue";
import ProductosProveedores from "@/views/dashboard/pages/admin/subUser/ProductosProveedores";
import ClientesProveedores from "@/views/dashboard/pages/admin/subUser/ClientesProveedores";

export default {
  name: "ver-proveedor",
  props: ["id"],
  data: function () {
    return {};
  },
  // computed: {},
  //methods: {}
  // watch: {},
  components: {
    HeadCardComponent,
    VerProveedorFormComponent,
    ProductosProveedores,
    ClientesProveedores,
  },
  // mixins: [],
  // filters: {},
  // -- Lifecycle Methods
  // -- End Lifecycle Methods
};
</script>

<style scoped></style>
