<template>
  <v-container>
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Productos
            </v-card-title>

            <v-row>
              <v-col sm="6" cols="12" class="text-center">
                <v-autocomplete
                  hide-details
                  dense
                  outlined
                  label="Marca"
                  v-model="marca"
                  :items="filter.marca"
                  color="primary"
                  item-text="nombre"
                  item-value="id"
                  placeholder="Marca"
                  clearable
                  flat
                ></v-autocomplete>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  outlined
                  label="Nombre"
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-text-field
                  hide-details
                  dense
                  label="Sku"
                  v-model="sku"
                  placeholder="Sku"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Upc"
                      hide-details
                      dense
                      outlined
                      v-model="upc"
                      color="primary"
                      placeholder="Upc"
                      clearable
                      maxlength="12"
                    ></v-text-field>
                  </template>
                  <span>Código Universal de Producto</span>
                </v-tooltip>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      label="Ean"
                      hide-details
                      dense
                      outlined
                      v-model="ean"
                      color="primary"
                      placeholder="Ean"
                      clearable
                      maxlength="13"
                    ></v-text-field>
                  </template>
                  <span>Número de Artículo Europeo</span>
                </v-tooltip>
              </v-col>
              <v-col sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
                <!-- FIN BOTONES FILTRAR COMPONENTE -->
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-tag-multiple"
                title="Productos"
                class="py-3"
                color="primary"
                :registros="total"
                :acciones="false"
                relacionar="relacionar-masivamente"
                :enlaces="enlaces"
                :showBtn="{ export: true }"
                @onExportBtn="exportarProducto"
              >
                <v-data-table
                  dense
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    disablePagination: loading,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.producto_id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>
                          {{ item.marca != null ? item.marca.nombre : "-" }}
                        </td>
                        <td>{{ item.sku != null ? item.sku : "-" }}</td>
                        <td>{{ item.upc != null ? item.upc : "-" }}</td>
                        <td>{{ item.ean != null ? item.ean : "-" }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verProducto(item.producto_id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver Producto</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>

        <v-data-table
          dense
          no-data-text="No existe registros en tu cuenta"
          :rows-per-page-items="[10, 25, 50, { text: 'Todos', value: -1 }]"
          :headers="headers2"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.direccion_comercial }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";

import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";

export default {
  name: "SubProductosProveedores",
  props: ["id"],
  data: () => ({
    enlaces: [
      {
        text: "Relacionar Masivamente",
        to: {
          name: "relacionar-masivamente",
        },
      },
    ],
    dialog: false,
    total: 0,
    totalDesserts: 0,
    desserts: [],
    options: {},
    loading: false,
    headers: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
      },
      {
        text: "Marca",
        align: "Center",
        value: "marca",
      },
      {
        text: "Sku",
        align: "Center",
        value: "sku",
      },
      {
        text: "Upc",
        align: "Center",
        value: "upc",
      },
      {
        text: "Ean",
        align: "Center",
        value: "ean",
      },
      {
        text: "Ver",
        align: "Center",
        value: "producto",
      },
    ],
    headers2: [
      {
        text: "ID",
        align: "Center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "Center",
        value: "nombre",
      },
      {
        text: "Dirección",
        align: "Center",
        value: "direccion_comercial",
      },
    ],
    datos: [],
    datos2: [],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    mi_id: "",
    marca: "",
    nombre: "",
    ean: "",
    sku: "",
    upc: "",
    rules: {
      numeros: [(v) => !/[^\d]/gim.test(v) || "Tienen que ser sólo números"],
    },
  }),
  async beforeMount() {
    await API.listarMarcas(this.$store.state.token)
      .then((res) => {
        this.$data.filter.marca = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.filtrarDatos();
  },
  methods: {
    restablecer() {
      this.$data.sku = "";
      this.$data.marca = "";
      this.$data.nombre = "";
      this.$data.ean = "";
      this.$data.upc = "";
      this.filtrarDatos2();
    },
    exportarProducto() {
      EventBus.$emit("cargando", true);
      API.exportarProdProvee(this.$props.id, this.$store.state.token)
        .then((res) => {
          window.open(res.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          console.log(err);
        });
    },
    verProveedores(proveedores) {
      this.$data.datos2 = proveedores;
      this.$data.dialog = true;
    },
    verProducto(id) {
      this.$router.push("/admin/ver-producto/" + id);
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              this.filtrarDatos();
              Swal.fire({
                icon: "success",
                title: "Datos relacionados",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
      //   }
      //  })
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log(res);
          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let marca_id;
      //  marca_id = this.$data.marca
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let sku;
      this.$data.sku != undefined ? (sku = this.$data.sku) : (sku = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      API.listarProductosProveedores(
        this.$store.state.token,
        this.$props.id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        sku,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.datos = res.data.respuesta.data;

          this.$data.datos.forEach((el, i) => {
            var marca = this.$data.filter.marca.find(
              (marca) => marca.id == el.marca_id
            );
            this.$data.datos[i].marca = marca;
          });

          this.$data.total = res.data.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];

          res.data.respuesta.data.forEach((el, i) => {
            el.mi_id != null ? this.$data.filter.mi_id.push(el.mi_id) : "";
          });
          this.$data.filter.nombre = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.nombre != null ? this.$data.filter.nombre.push(el.nombre) : "";
          });
          this.$data.filter.ean = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.ean != null ? this.$data.filter.ean.push(el.ean) : "";
          });
          this.$data.filter.upc = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.upc != null ? this.$data.filter.upc.push(el.upc) : "";
          });
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let marca_id;
      this.$data.marca != undefined
        ? (marca_id = this.$data.marca)
        : (marca_id = "");

      let ean;
      this.$data.ean != undefined ? (ean = this.$data.ean) : (ean = "");

      let upc;
      this.$data.upc != undefined ? (upc = this.$data.upc) : (upc = "");

      let sku;
      this.$data.sku != undefined ? (sku = this.$data.sku) : (sku = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      this.options.page = 1;

      API.listarProductosProveedores(
        this.$store.state.token,
        this.$props.id,
        direction,
        order,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        sku,
        marca_id,
        ean,
        upc,
        page,
        itemsPerPage
      )
        .then((res) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;

          this.$data.datos = res.data.respuesta.data;

          this.$data.datos.forEach((el, i) => {
            var marca = this.$data.filter.marca.find(
              (marca) => marca.id == el.marca_id
            );
            this.$data.datos[i].marca = marca;
          });

          this.$data.total = res.data.respuesta.total;
          let items = this.$data.datos;
          const total = items.length;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          this.$data.filter.mi_id = [];

          res.data.respuesta.data.forEach((el, i) => {
            el.mi_id != null ? this.$data.filter.mi_id.push(el.mi_id) : "";
          });

          this.$data.filter.nombre = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.nombre != null ? this.$data.filter.nombre.push(el.nombre) : "";
          });
          this.$data.filter.ean = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.ean != null ? this.$data.filter.ean.push(el.ean) : "";
          });
          this.$data.filter.upc = [];
          res.data.respuesta.data.forEach((el, i) => {
            el.upc != null ? this.$data.filter.upc.push(el.upc) : "";
          });
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          console.log(err);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    sku(v) {
      if (v == null) {
        this.$data.sku = "";
        this.filtrarDatos2();
      }
    },
    marca(v) {
      if (v == null) {
        this.$data.marca = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    ean(v) {
      if (v == null) {
        this.$data.ean = "";
        this.filtrarDatos2();
      }
    },
    upc(v) {
      if (v == null) {
        this.$data.upc = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
  },
};
</script>
<style lang="scss"></style>
