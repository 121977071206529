<template>
    <v-container>
        <v-row>
              <v-col lg="12">
                 <v-container class="pa-1" fluid>
                     <v-card class="px-3 pb-0">
                    <v-row >
                        <v-col
                            md="4"
                            lg="4"
                            sm="12"
                            cols="12"
                            class="text-center elevation-0 d-flex flex-column align-center justify-center"
                            >
                            <img
                            :src="producto_info.producto_info.imagen"
                            width="80%" />
                        </v-col>
                        <v-col
                            md="8"
                            lg="8"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                            <base-material-card
                                icon="mdi-align-vertical-bottom"
                                title="Detalles Producto"
                                class="px-5 py-3"
                                color="primary"
                                >
                            <v-container>
                                <v-row>
                                     <v-simple-table
                                    dense
                                        style="width:100%">
                                        <template >
                                            <tbody>
                                                <tr>
                                                <td>Mi ID:</td>
                                                <td>{{ producto_info.producto_info.mi_id != null ? producto_info.producto_info.mi_id : '-'}}</td>
                                                </tr>
                                                <tr>
                                                <td>Marca:</td>
                                                <td>{{ producto_info.producto_info.marca }}</td>
                                                </tr>
                                                <tr>
                                                <td>Upc:</td>
                                                <td>{{ producto_info.producto_info.upc != null ? producto_info.producto_info.upc : '-' }}</td>
                                                </tr>
                                                <tr>
                                                <td>Ean:</td>
                                                <td>{{ producto_info.producto_info.Ean != null ? producto_info.producto_info.Ean : '-' }}</td>
                                                </tr>
                                                <tr>
                                                <td>Disponibilidad:</td>
                                                <td>{{ producto_info.disponible != null ? producto_info.disponible == false ? 'No': 'Si' : '-'}}</td>
                                                </tr>
                                                <tr>
                                                <td>Stock:</td>
                                                <td>{{ producto_info.detalle_proveedores[0].stock != null ? producto_info.detalle_proveedores[0].stock : '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                     </v-simple-table>
                                </v-row>
                            </v-container>
                            </base-material-card>
                        </v-col>
                    </v-row>
                     </v-card>
                 </v-container>
            </v-col>
            <v-col cols="12">
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                            md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                <base-material-card
                icon="mdi-barcode"
                title="Detalles Proveedor"
                color="primary"
                >
                <v-data-table  
                    no-data-text="No existe registros en tu cuenta"
                    :headers="headers"
                    :items="producto_info.detalle_proveedores"
                    class="text-center centerheight"
                    :footer-props="{
                            'items-per-page-text':'Registros por página'
                        }"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td><v-img
                            :src="item.logo" width="30px"></v-img> </td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.direccion_comercial }}</td>
                            <td>{{  item.tipo_stock }}</td>
                            <td>{{ item.stock }}</td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </base-material-card>
                          </v-col>
                      </v-row>
                 </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/api'

export default {
    name:'ver-producto',
    title: 'Ver producto - Onestock',
    props:['id'],
    data:()=>({
        producto_info:null,
        user:null,
        cards:[],
        headers: [
            {
                text: 'Logo',
               // align: 'Center',
                sortable: true,
                value:'logo'
            },
            { 
                text: 'Nombre', 
                //align: 'Center',
                value: 'nombre', 
            },
            { 
                text: 'Dirección', 
                // align: 'Center',
                value: 'direccion_comercial', 
            },
            { 
                text: 'Tipo de stock', 
                // align: 'Center',
                value: 'tipo_stock', 
            },
            { 
                text: 'Stock', 
                // align: 'Center',
                value: 'stock', 
            }
        
        ],
    }),
    beforeMount(){
        API.verProducto(this.$store.state.user.id, this.$props.id,this.$store.state.token)
        .then((res)=>{
            this.$data.producto_info = res.data.respuesta
        })
        .catch((err)=>{
            console.log(err)
        })
       
    }
    
}
</script>
<style lang="scss" >
.text{
    word-break: break-word;
}
td{
    text-align: left;
}
.centerheight{
  text-align: center !important
}
</style>
