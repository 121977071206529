<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Marcas
            </v-card-title>
            <v-row justify-center align-center>
              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                  outlined
                  hide-details
                  dense
                  label="Nombre"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="id"
                  color="primary"
                  placeholder="ID"
                  clearable
                  outlined
                  dense
                  hide-details
                  label="ID"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-autocomplete
                  v-model="activo"
                  color="primary"
                  placeholder="Activo"
                  clearable
                  outlined
                  dense
                  item-text="text"
                  item-value="text"
                  :items="activos"
                  hide-details
                  label="Activo"
                ></v-autocomplete>
              </v-col>

              <v-col md="6" lg="6" sm="6" cols="12" class="">
                <!-- BOTONES FILTRAR COMPONENTE -->

                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- INICIO TABLA -->
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-factory"
                title="Marca"
                class="py-3"
                color="primary"
                :registros="total"
                acciones="true"
                :enlaces="enlaces"
                @exportar="exportar = $event"
                :showBtn="shortCutBtn"
                @onExportBtn="exportarMarcas"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>
                          {{
                            item.activo != null
                              ? item.activo == 1
                                ? "Sí"
                                : "No"
                              : "-"
                          }}
                        </td>
                        <td>{{ item.created }}</td>
                        <td>{{ item.modified }}</td>

                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verMarca(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="editarMarca(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-lead-pencil</v-icon
                              >
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <div class="my-10">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";
var moment = require("moment");

export default {
  name: "marca",
  title: "Admin marcas - Onestock",
  data: () => ({
    shortCutBtn: {
      export: true,
      exportMasive: {
        to: {
          name: "editar-masiva-marca",
        },
      },
      addMasive: {
        to: {
          name: "crear-masiva-marca",
        },
      },
    },
    exportar: false,
    enlaces: [
      {
        text: "Crear nuevo",
        to: {
          name: "crear-marca",
        },
      },
    ],
    total: 0,
    datos: [],
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Activo",
        align: "center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "Fecha modificación",
        align: "center",
        value: "ean",
        sortable: false,
      },
      {
        text: "Ver",
        align: "center",
        value: "accion",
        sortable: false,
      },
      {
        text: "Editar",
        align: "center",
        value: "accion",
        sortable: false,
      },
    ],
    id: "",
    nombre: "",
    proveedor: "",
    activo: "",
    activos: [
      {
        id: 1,
        text: "Sí",
      },
      {
        id: 0,
        text: "No",
      },
    ],
    proveedores: [],
  }),
  async beforeMount() {
    await this.listarproveedores();
    await this.filtrarDatos();
  },
  methods: {
    restablecer() {
      this.$data.id = "";
      this.$data.activo = "";
      this.$data.nombre = "";
      this.$data.proveedor = "";
      this.filtrarDatos2();
    },
    listarproveedores() {
      API.listarProveedoresAdmin(this.$store.state.token).then((res) => {
        this.$data.proveedores = res.data.data;
      });
    },
    editarMarca(id) {
      this.$router.push("/admin/editar-marca/" + id);
    },
    verMarca(id) {
      this.$router.push("/admin/ver-marca/" + id);
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              API.productosClientes(
                this.$store.state.user.id,
                this.$store.state.token,
                "",
                "",
                "",
                "",
                ""
              )
                .then((res) => {
                  this.$data.datos = res.data.productos;
                })
                .catch((err) => {
                  console.log(err);
                });
              Swal.fire({
                icon: "success",
                title: "Acción exitosa",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let proveedor;
      this.$data.proveedor != undefined
        ? (proveedor = this.$data.proveedor)
        : "";

      let activo;
      this.$data.activo != undefined
        ? this.$data.activo == "Sí"
          ? (activo = 1)
          : this.$data.activo == "No"
          ? (activo = 0)
          : (activo = "")
        : "";

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : "";

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      API.listarMarcasAdmin(
        this.$store.state.token,
        id,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        activo,
        order,
        direction,
        page,
        itemsPerPage,
        proveedor
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.total;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let proveedor;
      this.$data.proveedor != undefined
        ? (proveedor = this.$data.proveedor)
        : "";

      let activo;
      this.$data.activo != undefined
        ? this.$data.activo == "Sí"
          ? (activo = 1)
          : this.$data.activo == "No"
          ? (activo = 0)
          : (activo = "")
        : "";

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : "";

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;
      API.listarMarcasAdmin(
        this.$store.state.token,
        id,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        activo,
        order,
        direction,
        page,
        itemsPerPage,
        proveedor
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.total;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    exportarMarcas() {
      EventBus.$emit("cargando", true);
      API.exportarMarcasAdmin(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    id(v) {
      if (v == null) {
        this.$data.id = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    activo(v) {
      if (v == null) {
        this.$data.activo = "";
        this.filtrarDatos2();
      }
    },
    proveedor(v) {
      if (v == null) {
        this.$data.proveedor = "";
        this.filtrarDatos2();
      }
    },
    exportar(v) {
      if (v == true) {
        this.exportarMarcas();
        this.$data.exportar = false;
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss"></style>
