<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1">
          <v-card class="px-3 pb-0">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Proveedores
            </v-card-title>
            <v-row>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="nombre"
                  color="primary"
                  hide-no-data
                  placeholder="Nombre"
                  return-object
                  clearable
                  dense
                  outlined
                  label="Nombre"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="email"
                  color="primary"
                  placeholder="E-mail"
                  clearable
                  dense
                  outlined
                  label="E-mail"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="id"
                  color="primary"
                  placeholder="ID"
                  return-object
                  clearable
                  dense
                  outlined
                  hide-details
                  label="ID"
                ></v-text-field>
              </v-col>

              <v-col md="3" lg="3" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="estado"
                  color="primary"
                  hide-no-data
                  placeholder="Estado"
                  item-text="text"
                  item-value="id"
                  :items="estadoac"
                  clearable
                  dense
                  outlined
                  label="Estado"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- INICIO TABLA -->
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-truck-check"
                title="Proveedores"
                class="py-3"
                color="primary"
                :registros="total"
                acciones="true"
                :enlaces="enlaces"
                :showBtn="showBtn"
                @onExportBtn="exportarProveedores"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="No existe registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10], 
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.direccion_comercial }}</td>
                        <td>
                          {{
                            item.activo != null
                              ? item.activo == 1
                                ? "Sí"
                                : "No"
                              : "-"
                          }}
                        </td>
                        <td>{{ item.created != null ? item.created : "-" }}</td>
                        <td>
                          {{ item.modified != null ? item.modified : "-" }}
                        </td>
                        <td>
                          {{ item.disponibilidad }}

                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verProveedor(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="editarProveedor(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-lead-pencil</v-icon
                              >
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-switch
                                  v-model="item.permitir_actualizar_stock"
                                  dense
                                  @change="allowStock(item, $event)"
                                ></v-switch>
                              </div>
                            </template>
                            <span
                              v-text="
                                `Permitido Actualizar Stock: ${
                                  item.permitir_actualizar_stock ? 'Sí' : 'No'
                                } `
                              "
                            />
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" rounded @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Actualizar Stock</h3>
        </v-card-title>
        <v-row>
          <v-col sm="12" cols="12">
            <v-file-input
              v-model="excel"
              id="file"
              label="Cargar Archivo"
              dense
              placeholder="Cargar Archivo"
              outlined
              hide-details
            >
            </v-file-input>
          </v-col>
          <v-col lg="8" offset-lg="2" md="8" offset-md="2" sm="12" cols="12">
            <v-btn block @click="cargarExcel()" rounded color="secondary">
              Cargar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <div class="my-10">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
import BtnVolver from "@/components/BtnVolver.vue";

var moment = require("moment");

export default {
  name: "proveedores",
  title: "Admin proveedores - Onestock",
  data: () => ({
    loadingPas: {},
    showBtn: {
      export: true,
      exportMasive: {
        to: {
          name: "edicion-masiva-proveedor",
        },
      },
      addMasive: {
        to: {
          name: "crear-masiva-proveedor",
        },
      },
    },
    excel: null,
    dialog: false,
    exportar: false,
    enlaces: [
      {
        text: "Crear nuevo",
        to: {
          name: "crear-proveedor",
          export: false,
        },
      },
     
      {
        text: "Actualizar stock masivo y Descargar CSV formato Stock",
        to: {
          name: "ProveedorActualizarStockMasivo",
          export: false,
        },
        function: "exportarProducto",
      },
    ],
    total: 0,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
        width: "1%",
      },

      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
        width: "19%",
      },
      {
        text: "E-mail",
        align: "center",
        value: "email",
        sortable: false,
        width: "16%",
      },
      {
        text: "Dirección",
        align: "center",
        value: "direccion_comercial",
        sortable: false,
        width: "15%",
      },
      {
        text: "Activo",
        align: "center",
        value: "activo",
        sortable: false,
        width: "8%",
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
        width: "11%",
      },
      {
        text: "Fecha modificac.",
        align: "center",
        value: "modified",
        sortable: false,
        width: "11%",
      },
      {
        text: "Disponibilidad",
        align: "center",
        value: "disponibilidad",
        sortable: false,
        width: "7%",
      },
      {
        text: "Ver",
        align: "Center",
        value: "accion",
        sortable: false,
        width: "2%",
      },
      {
        text: "Edit",
        align: "Center",
        value: "accion",
        sortable: false,
        width: "3",
      },
      {
        text: "PAS",
        align: "Center",
        value: "accion",
        sortable: false,
        width: "7%",
      },
    ],
    datos: [],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    estadoac: [
      {
        id: 0,
        text: "No",
      },
      {
        id: 1,
        text: "Sí",
      },
    ],
    id: "",
    email: "",
    nombre: "",
    estado: "",
  }),
  async beforeMount() {
    await this.filtrarDatos();
  },
  methods: {
    async allowStock(item, event) {
      EventBus.$emit("cargando", true);
      const proveedor = {};

      proveedor.permitir_actualizar_stock = Number(event);
      await API.editarProveedorAdmin(this.$store.state.token, item.id, proveedor)
        .then(() => {
        
        })
        .catch((error) => {
          console.log(error);
        });
      EventBus.$emit("cargando", false);
    },

    restablecer() {
      this.$data.email = "";
      this.$data.id = "";
      this.$data.nombre = "";
      this.$data.estado = "";
      this.filtrarDatos2();
    },
    exportarProveedores() {
      EventBus.$emit("cargando", true);
      API.exportarformatoProveedores(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
   
    verRelacion(proveedores, clientes) {},
    editarProveedor(id) {
      this.$router.push("/admin/editar-proveedor/" + id);
    },
    verProveedor(id) {
      this.$router.push("/admin/ver-proveedor/" + id);
    },

    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      let estado;
      this.$data.estado != undefined
        ? (estado = this.$data.estado)
        : (estado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }

      API.listarProveedoresAdmin2(
        this.$store.state.token,
        direction,
        order,
        id,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        email,
        estado,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.total;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      let estado;
      this.$data.estado != undefined
        ? (estado = this.$data.estado)
        : (estado = "");

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");

      let order = "id";

      if (sortBy[0]) {
        order = sortBy;
      }
      this.options.page = 1;
      API.listarProveedoresAdmin2(
        this.$store.state.token,
        direction,
        order,
        id,
        this.$data.nombre != undefined ? this.$data.nombre : "",
        email,
        estado,
        page,
        itemsPerPage
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.total;
          this.$data.totalDesserts = res.data.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          EventBus.$emit("cargando", false);
          this.$data.loading = false;
          Swal.fire({
            icon: "error",
            title: "Error, intente de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    exportar(v) {
      if (v == true) {
        this.exportarProveedores();
        this.$data.exportar = false;
      }
    },
  
    email(v) {
      if (v == null) {
        this.$data.email = "";
        this.filtrarDatos2();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos2();
      }
    },
    id(v) {
      if (v == null) {
        this.$data.id = "";
        this.filtrarDatos2();
      }
    },
    estado(v) {
      if (v == null) {
        this.$data.estado = "";
        this.filtrarDatos2();
      }
    },
  },
  components: {
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss">
.v-input--switch.v-input--dense .v-input--switch__track {
  height: 7px;
  width: 21px;
}
.v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 12px;
  height: 12px;
}
</style>
