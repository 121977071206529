<template>
    <v-container>
        <v-row>
            <v-col lg="12">
                 <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                           <base-material-card
                            icon="mdi-align-vertical-bottom"
                            title="Errores"
                            class="px-5 py-3"
                            color="primary"
                            >
                 <div v-if="this.$props.Errores != null">
                            <v-data-table
                                no-data-text="no_row_data"
                                :headers="headers"
                                :items="this.$props.Errores"
                                class="elevation-1"
                                style="text-align-last: center;"
                                 :footer-props="{
                                    itemsPerPageOptions: [10, 25, 50], 
                                    showFirstLastPage: true,
                                    disablePagination:loading,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    prevIcon: 'mdi-minus',
                                    nextIcon: 'mdi-plus',
                                    'items-per-page-text':'Registros por página'
                                }"
                                >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.name">
                                        <td>{{item.fila}}</td>
                                        <td>{{item.columna}}</td>
                                        <td>{{item.errores[0]}}</td>
                                        <td>      </td>
                                    </tr>
                                    </tbody>
                                </template>
                                </v-data-table>
                                </div>
                                </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/api'
import Swal from 'sweetalert2'
import {EventBus} from '@/main.js'
var moment = require('moment')

export default {
    name:'Errores',
    props: ['Errores'],
    data:()=>({
        dialog:false,
        no_row_data:"No hay datos",
        options: {},
        headers: [
            {
                text: 'FILA',
                align: 'Center',
                sortable: true,
                value:'fila'
            },
              {
                text: 'COLUMNA',
                align: 'Center',
                sortable: true,
                value:'columna'
            },
            { 
                text: 'ERROR', 
                align: 'Center',
                value: 'error', 
                sortable: false,
            },
           
        ],
    }),

    methods: {

    },

  }
</script>
<style lang="scss" >

</style>

