<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0 py-4">
            <v-card-title class="pl-0 pt-2">
              <v-icon>mdi-magnify</v-icon>
              Filtro Clientes
            </v-card-title>
            <v-row>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="nombre"
                  color="primary"
                  placeholder="Nombre"
                  clearable
                  outlined
                  label="Nombre"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="email"
                  color="primary"
                  placeholder="E-mail"
                  clearable
                  outlined
                  label="E-mail"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="id"
                  color="primary"
                  placeholder="ID"
                  return-object
                  clearable
                  outlined
                  label="ID"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-text-field
                  v-model.trim="formatedRut"
                  color="primary"
                  placeholder="Rut"
                  clearable
                  outlined
                  label="Rut"
                  id="rut"
                  dense
                  hide-details
                  maxlength="12"
                ></v-text-field>
              </v-col>
              <v-col md="2" lg="2" sm="6" cols="12" class="text-center">
                <v-autocomplete
                  v-model="plan_id"
                  :items="filter.plan"
                  color="primary"
                  item-text="nombre"
                  item-value="id"
                  placeholder="Planes"
                  clearable
                  outlined
                  label="Planes"
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col md="6" lg="6" sm="6" cols="12" class="text-center">
                <!-- BOTONES FILTRAR COMPONENTE -->
                <btns-filtro-component
                  @filterData="filtrarDatos2"
                  @resetFilter="restablecer"
                ></btns-filtro-component>
                <!-- FIN BOTONES FILTRAR COMPONENTE -->
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- FIN SECCION FILTRO -->

        <!-- INICIO TABLA -->
        <v-container fluid class="pa-1">
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-account-group"
                title="Clientes"
                class="py-3"
                color="primary"
                :registros="total"
                acciones="true"
                :enlaces="enlaces"
                :showBtn="{ export: true }"
                @onExportBtn="exportarClientes"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="datos"
                  :options.sync="options"
                  :server-items-length="totalDesserts"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.rut | rutFilter }}</td>
                        <td>
                          {{
                            item.activo != null
                              ? item.activo == 1
                                ? "Si"
                                : "No"
                              : "-"
                          }}
                        </td>
                        <td>{{ item.created }}</td>
                        <td>{{ item.email }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verCliente(item.id)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="info"
                                @click="editarCliente(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-lead-pencil</v-icon
                              >
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <div class="my-10">
      <btn-volver />
    </div>
  </v-container>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnsFiltroComponent from "@/components/filter/BtnsFiltroComponent.vue";
var moment = require("moment");
import { ValidationProvider, extend } from "vee-validate";
import { rutValidator } from "vue-dni";
import BtnVolver from "@/components/BtnVolver.vue";

export default {
  name: "clientes",
  title: "Admin clientes - Onestock",
  data: () => ({
    exportar: false,
    enlaces: [
      {
        text: "Crear nuevo",
        to: {
          name: "crear-clientes",
        },
      },
    ],
    datos: [],
    total: 0,
    dialog: false,
    no_row_data: "No hay datos",
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Rut",
        align: "center",
        value: "rut",
        sortable: false,
      },
      {
        text: "Activo",
        align: "center",
        value: "activo",
        sortable: false,
      },
      {
        text: "Fecha creación",
        align: "center",
        value: "created",
        sortable: false,
      },
      {
        text: "E-mail",
        align: "center",
        value: "email",
        sortable: false,
      },
      {
        text: "Ver",
        align: "center",
        value: "accion",
        sortable: false,
      },
      {
        text: "Editar",
        align: "Center",
        value: "accion",
        sortable: false,
      },
    ],
    filter: {
      mi_id: [],
      marca: [],
      nombre: [],
      ean: [],
      upc: [],
    },
    id: "",
    plan_id: "",
    nombre: "",
    rut: "",
    email: "",
    order: undefined,
  }),

  async beforeMount() {
    await API.listarPlanesAdmin2(this.$store.state.token)
      .then((res) => {
        this.$data.filter.plan = res.data.respuesta.data;
      })
      .catch((err) => {
        console.log(err);
      });
    await this.filtrarDatos();
  },
  created() {
    extend("rut", rutValidator);
  },
  computed: {
    formatedRut: {
      get() {
        return this.formatRut(this.rut);
      },
      set(modifiedValue) {
        this.rut = modifiedValue;
        console.log("modifiedValue", modifiedValue);
      },
    },
  },

  methods: {
    editarCliente(id) {
      console.log(id);
      this.$router.push("/admin/editar-cliente/" + id);
    },
    verCliente(id) {
      this.$router.push("/admin/ver-cliente/" + id);
    },
    relacionarProducto(id_prod) {
      Swal.fire({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
          input: "input-class",
        },
        title: "Ingrese su ID",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Registrar",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (login) => {
          API.relacionarProducto(id_prod, login, this.$store.state.token)
            .then((res) => {
              API.productosClientes(
                this.$store.state.user.id,
                this.$store.state.token,
                "",
                "",
                "",
                "",
                ""
              )
                .then((res) => {
                  this.$data.datos = res.data.productos;
                })
                .catch((err) => {
                  console.log(err);
                });
              Swal.fire({
                icon: "success",
                title: "Acción exitosa",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error, intente de nuevo",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
      });
    },
    quitarRelacionProducto(id_prod) {
      API.quitarRelacionProducto(
        id_prod,
        this.$store.state.user.id,
        this.$store.state.token
      )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Acción exitosa",
            showConfirmButton: false,
            timer: 1500,
          });

          this.filtrarDatos();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtrarDatos() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let plan_id;
      this.$data.plan_id != undefined
        ? (plan_id = this.$data.plan_id)
        : (plan_id = "");

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      var rut;
      if (this.$data.rut != undefined) {
        var r = this.$data.rut.split(".").join("");
        var r2 = r.split("-").join("");
        rut = r2;
      } else {
        rut = "";
      }

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let activo;
      this.$data.activo != undefined
        ? (activo = this.$data.activo)
        : (activo = "");

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      API.listarClientesAdmin(
        this.$store.state.token,
        id,
        plan_id,
        email,
        rut,
        nombre,
        activo,
        order,
        direction,
        itemsPerPage,
        page
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.respuesta.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.respuesta.total;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    filtrarDatos2() {
      this.$data.loading = true;
      EventBus.$emit("cargando", true);
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let id;
      this.$data.id != undefined ? (id = this.$data.id) : (id = "");

      let plan_id;
      this.$data.plan_id != undefined
        ? (plan_id = this.$data.plan_id)
        : (plan_id = "");

      let email;
      this.$data.email != undefined ? (email = this.$data.email) : (email = "");

      var rut;
      if (this.$data.rut != undefined) {
        var r = this.$data.rut.split(".").join("");
        var r2 = r.split("-").join("");
        rut = r2;
      } else {
        rut = "";
      }

      let nombre;
      this.$data.nombre != undefined
        ? (nombre = this.$data.nombre)
        : (nombre = "");

      let activo;
      this.$data.activo != undefined
        ? (activo = this.$data.activo)
        : (activo = "");

      let order = "id";

      this.$data.order != undefined
        ? (order = this.$data.order)
        : (order = "id");

      let direction = "asc";
      sortDesc[0] != true ? (direction = "asc") : (direction = "desc");
      this.options.page = 1;
      API.listarClientesAdmin(
        this.$store.state.token,
        id,
        plan_id,
        email,
        rut,
        nombre,
        activo,
        order,
        direction,
        itemsPerPage,
        page
      )
        .then((res) => {
          this.$data.loading = false;
          this.$data.datos = res.data.respuesta.data;
          this.$data.datos.forEach((el) => {
            el.created = moment(el.created).format("DD-MM-YYYY");
            el.modified = moment(el.modified).format("DD-MM-YYYY");
          });
          let items = this.$data.datos;
          const total = items.length;
          this.$data.total = res.data.respuesta.total;
          this.$data.totalDesserts =
            res.data.respuesta.last_page * itemsPerPage;
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          this.$data.loading = false;
          EventBus.$emit("cargando", false);
        });
    },
    exportarClientes() {
      EventBus.$emit("cargando", true);
      API.exportarClientes(this.$store.state.token)
        .then((res) => {
          window.open(res.data.respuesta);
          EventBus.$emit("cargando", false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit("cargando", false);
        });
    },
    restablecer() {
      this.$data.id = "";
      this.$data.plan_id = "";
      this.$data.nombre = "";
      this.$data.rut = "";
      this.$data.email = "";
      this.filtrarDatos2();
    },
    formatRut(rut) {
      rut = rut.replace(/[^k0-9]/gim, "").split("");
      return rut.length > 1
        ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
            rut[rut.length - 1]
          }`
        : rut.join("");
    },
  },
  watch: {
    options: {
      handler() {
        this.filtrarDatos();
      },
      deep: true,
    },
    exportar(v) {
      if (v == true) {
        this.exportarClientes();
        this.$data.exportar = false;
      }
    },
    id(v) {
      if (v == null) {
        this.$data.id = "";
        this.filtrarDatos();
      }
    },
    nombre(v) {
      if (v == null) {
        this.$data.nombre = "";
        this.filtrarDatos();
      }
    },
    rut(v) {
      if (v == null) {
        this.$data.rut = "";
        this.filtrarDatos();
      }
    },
    email(v) {
      if (v == null) {
        this.$data.email = "";
        this.filtrarDatos();
      }
    },
    plan_id(v) {
      if (v == null) {
        this.$data.plan_id = "";
        this.filtrarDatos();
      }
    },
  },
  components: {
    ValidationProvider,
    BtnsFiltroComponent,
    BtnVolver,
  },
};
</script>
<style lang="scss"></style>
