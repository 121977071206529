<template>
    <v-container>
        <v-row>
            <v-col lg="12" >
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                            md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center mx-auto "
                        >
                        <base-material-card
                        icon="mdi-account"
                        title="Modificar datos de usuario"
                        class="pa-5 text-center mx-auto padd"
                        color="secondary"
                        >
                            <v-form  ref="form"
                            v-model="valid"
                            lazy-validation
                            autocomplete="false"
                        >
                            <v-container>
                                <v-row>
                                    <v-col
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="text-center py-0"
                                        >
                                        <v-text-field
                                            small
                                            :rules="rules.notEmpty"
                                            v-model="userData.nombre"
                                            label="Nombre"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="py-0"
                                        >
                                    <v-text-field
                                            type="text"
                                            v-model="formatedRut" 
                                            label="Rut"
                                            id="rut"
                                            maxlength="12"
                                            :rules="rules.rut"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="text-center py-0"
                                        >
                                        <v-text-field
                                            :rules="rules.email"
                                            v-model="userData.email"
                                            label="Email"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="text-center py-0"
                                        >
                                            <v-file-input 
                                                show-size 
                                                accept="*" 
                                                v-model="avatar" 
                                                label="Cargar avatar (opcional)"
                                                :rules="rules.notEmpty"></v-file-input>
                                    </v-col>
                                    <v-col v-if="cambiar_password"
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="text-center py-0"
                                        >
                                        <v-text-field
                                            v-model="userData.password"
                                            :type="showPassword ? 'text': 'password'"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="showPassword = !showPassword"
                                            label="Contraseña"
                                            :rules="rules.passwordRules"
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col v-if="cambiar_password"
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class="text-center py-0"
                                        >
                                        <v-text-field
                                            v-model="repeat"
                                            :type="showPassword2 ? 'text': 'password'"
                                            :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="showPassword2 = !showPassword2"
                                            label="Repeta la contraseña"
                                            required>
                                        </v-text-field>
                                        <h5 v-if="error != null" class="error--text">{{ error }}</h5>
                                    </v-col>
                                </v-row>
                                <v-row v-if="errors.length > 0">
                                        <v-col cols="8" offset-lg="2" offset-md="2" offset-sm="2"> 
                                            <div v-if="errors.length > 0" class="">
                                                <v-alert 
                                                    dense
                                                    style="padding:8px "
                                                    text
                                                    type="error"
                                                    dismissible
                                                        v-for="(err ,i) in errors" :key="i" 
                                                    >
                                                    <strong>{{err}}</strong>
                                                </v-alert>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col 
                                        md="6"
                                        lg="6"
                                        sm="12"
                                        cols="12"
                                        class=" text-center"
                                        >
                                        <v-btn
                                            block
                                            color="info"
                                            @click="cambiar_password = !cambiar_password">
                                            Cambie la contraseña
                                        </v-btn>
                                    </v-col>                        
                                    <v-col>
                                        <v-btn
                                        block
                                            color="secondary"
                                            class="text-center"
                                            :disabled="!valid"
                                            @click="editarCliente()">
                                                Actualice  los datos
                                            </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                            </v-form>
                        </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="pa-1"  fluid>
                    <v-row>
                        <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="text-center"
                        >
                            <v-col
                                md="12"
                                lg="12"
                                sm="12"
                                cols="12"
                                class="text-center d-flex flex-column justify-center pa-0"
                            >
                                <base-material-card
                                    icon="mdi-clipboard-text"
                                    title="Mi Plan"
                                    class=""
                                    color="secondary"
                                    >
                                    <v-container>
                                        <v-row>
                                            <v-simple-table
                                            dense
                                            style="width:100%">
                                                <template >
                                                    <tbody>
                                                    <tr>
                                                    <td>Nombre</td>
                                                    <td>{{ userData.plan.nombre }}</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Descripción</td>
                                                    <td>{{ userData.plan.descripcion }}</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Precio x petición</td>
                                                    <td>{{ userData.plan.precio_x_producto | currency}}</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Límite diario</td>
                                                    <td>{{ userData.plan.limite_diario }}</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Precio sobre consumo</td>
                                                    <td>{{ userData.plan.precio_sobre_consumo | currency}}</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Plan ilimitado</td>
                                                    <td>{{  userData.plan.plan_ilimitado == false ? 'No' : 'Si' }}</td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-row>
                                    </v-container>
                                </base-material-card>
                            </v-col>
                        </v-col>
                        <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                        class=" text-center d-flex  flex-column justify-center"
                        >
                        <base-material-card
                            icon="mdi-clipboard-text"
                            title="Peticiones"
                            class=""
                            color="secondary"
                            >
                        <v-container>
                            <v-row>
                                <v-simple-table
                                dense
                                    style="width:100%">
                                    <template >
                                        <tbody>
                                        <tr>
                                        <td>Gastado en el mes</td>
                                        <td>{{ userData.peticiones.gastado_este_mes | currency }}</td>
                                        </tr>
                                        <tr>
                                        <td>Gastado hoy</td>
                                        <td>{{ userData.peticiones.gastado_hoy | currency }}</td>
                                        </tr>
                                        <tr>
                                        <td>Gastado mes anterior</td>
                                        <td>{{ userData.peticiones.gastado_mes_anterior | currency }}</td>
                                        </tr>
                                        <tr>
                                        <td>Peticiones este mes</td>
                                        <td>{{  userData.peticiones.peticiones_este_mes }}</td>
                                        </tr>
                                        <tr>
                                            <td>Peticiones hoy</td>
                                        <td>{{ userData.peticiones.peticiones_hoy }}</td>
                                        </tr>
                                        <tr>
                                        <td>Peticiones mes anterior</td>
                                        <td>{{ userData.peticiones.peticiones_mes_anterior }}</td>
                                        </tr>
                                        <tr>
                                        <td>Peticiones restantes hoy</td>
                                        <td>{{ userData.peticiones.peticiones_restantes_hoy }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>
                        </v-container>
                        </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/api'
import { isRutValid } from '@/utils'
import Swal from 'sweetalert2'
import {mapActions} from 'vuex'
import {EventBus} from '@/main.js'

export default {
    name:'mi-perfil',
    title: 'Mi perfil - Onestock',
    data:()=>({
        userData:{
            nombre:'',
            rut:'',
            email:'',
            password:'',
        },
        rut:'',
        errors:[],
        avatar:null,
        cambiar_password:false,
        repeat:'',
        valid:true,
        showPassword: false,
        showPassword2:false,
        error:null,
        rules:{
            notEmpty: [(v) => !!v || "Este campo es requerido"],
            email: [
            (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "El mail debe ser válido"
            ],
            passwordRules:[
                (v) => !!v || 'Contraseña es requerida',
            ],
            rut: [
				(v) => !!v || `El Rut es requerido`,
				(v) => isRutValid(v) || `Rut inválido`,
			]
        }
    }),
   async beforeMount(){
        EventBus.$emit('cargando',true)
        await API.verCliente(this.$store.state.token,this.$store.state.user.id)
        .then((res)=>{
              EventBus.$emit('cargando',false)
            this.$data.userData = res.data.respuesta
            if(this.$data.userData.rut){
                this.$data.rut = this.$data.userData.rut
            }
        })
        .catch((err)=>{
            console.log(err)
            EventBus.$emit('cargando',false)
        })
    },methods:{
        ...mapActions(['RegisterUser']),
        async resetUserData() {
            let temp = {};
            Object.assign(temp, this.$store.state.user);
            this.$data.userData = temp;
        },
        editarCliente(){
            EventBus.$emit('cargando',true)
            this.$data.userData.avatar=null;
            if(this.$data.avatar != null){
                this.$data.userData.avatar = this.$data.avatar
            }
      
           
            if(this.$data.rut != undefined){
                 var r = this.$data.rut.split('.').join('');
                 var r2 = r.split('-').join('');
                 this.$data.userData.rut = r2
            }
            API.editarCliente(this.$data.userData,this.$store.state.token)
            .then((res)=>{
                EventBus.$emit('cargando',false)
                Swal.fire({
                    icon: 'success',
                    title: 'Acción Exitosa',
                    showConfirmButton: false,
                    timer: 1500
                    })
                API.verCliente(this.$store.state.token,this.$store.state.user.id)
                    .then((res)=>{
                        this.$data.userData = res.data.respuesta
                        this.$store.commit('addUser',this.$data.userData)
                    })
                    .finally(() => {
                        this.resetUserData();
                        })
                    .catch((err)=>{
                        console.log(err)
                    })
            })
            .catch((err)=>{
                Swal.fire({
                    icon: 'error',
                    title: 'Error, intente de nuevo',
                    showConfirmButton: false,
                    timer: 1500
                    })
                EventBus.$emit('cargando',false)
                let error = err.response.data
                if(error){
                    if(error.mensaje){
                        this.$data.errors.push(error.mensaje.rut[0])
                    }
                }
            })
        },
        formatRut(rut){
        rut = rut.replace(/[^k0-9]/gim, "").split("");
        return rut.length > 1
            ? `${parseInt(rut.slice(0, -1).join("")).toLocaleString("es-ES")}-${
                rut[rut.length - 1]
            }`
            : rut.join("");
        }
    },
    computed: {
        formatedRut: {
            get() {
                return this.formatRut(this.rut);
            },
            set(modifiedValue) {
                this.rut = modifiedValue;
                console.log('modifiedValue', modifiedValue);
            },
        },
    },
    watch:{
        repeat(v){
            this.$data.userData.password != v 
            ? this.$data.error = 'Las contraseñas no coinciden' 
            : this.$data.error= null
        }
    },
    components:{
      //  DniField
    }
}
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
}


</style>
