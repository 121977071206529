<template>
  <v-container fluid class="ma-0 px-4" v-if="producto_info">
    <v-row>
      <v-col lg="12">
        <v-container class="pa-1" fluid>
          <v-card class="px-3 pb-0">
            <v-row class="justify-center">
              <v-col sm="8" cols="12" class="text-center">
                <base-material-card
                  icon="mdi-tag-multiple"
                  title="Detalles Producto"
                  class="px-16 py-3"
                  color="primary"
                >
                  <v-container>
                    <v-row>
                      <v-simple-table dense style="width: 100%">
                        <template>
                          <tbody>
                            <tr>
                              <td>Mi ID:</td>
                              <td>{{ producto_info.id }}</td>
                            </tr>
                            <tr>
                              <td>Marca:</td>
                              <td>{{ producto_info.marca.nombre }}</td>
                            </tr>
                            <tr>
                              <td>Upc:</td>
                              <td>{{ producto_info.upc }}</td>
                            </tr>
                            <tr>
                              <td>Ean:</td>
                              <td>
                                {{
                                  producto_info.Ean != null
                                    ? producto_info.Ean
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Disponible:</td>
                              <td>
                                {{
                                  producto_info.disponible != null
                                    ? producto_info.disponible == true
                                      ? "Si"
                                      : "No"
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Stock:</td>
                              <td v-text="sumaStockProveedores"></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </base-material-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-truck-check"
                title="Detalles Proveedor"
                class="px-5 py-3"
                color="primary"
              >
                <v-data-table
                  calculate-widths
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers"
                  :items="producto_info.proveedores"
                  class="text-center centerheight"
                  :footer-props="{
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.direccion_comercial }}</td>
                        <td>{{ item.disponibilidad }}</td>
                        <td>{{ item.pivot.stock }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                medium
                                block
                                @click="verProveedor(item.pivot)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver Proveedor</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-container class="pa-1" fluid>
          <v-row>
            <v-col md="12" lg="12" sm="12" cols="12" class="text-center">
              <base-material-card
                icon="mdi-account-group"
                title="Detalles Clientes"
                class="px-5 py-3"
                color="primary"
              >
                <v-data-table
                  no-data-text="No existen registros en tu cuenta"
                  :headers="headers_cliente"
                  :items="producto_info.clientes"
                  class="text-center centerheight"
                  :footer-props="{
                    'items-per-page-text': 'Registros por página',
                  }"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.name">
                        <td>{{ item.id }}</td>
                        <td>{{ item.pivot.id_interno }}</td>
                        <td>{{ item.rut == true ? "Si" : "No" }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                v-bind="attrs"
                                @click="verClientes(item.pivot)"
                                v-on="on"
                                >eye-arrow-right
                              </v-icon>
                            </template>
                            <span>Ver Cliente</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="my-10">
          <btn-volver />
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>

        <v-data-table
          dense
          no-data-text="No existen registros en tu cuenta"
          :headers="headers_proveedores"
          :items="datos2"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.producto_id }}</td>
                <td>{{ item.proveedor_id }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.stock }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="600">
      <v-card class="text-center pa-3" style="position: relative">
        <v-btn class="btnclose" @click="dialog2 = false"> x </v-btn>
        <v-card-title>
          <h3>Datos de proveedores</h3>
        </v-card-title>

        <v-data-table
          dense
          no-data-text="No existen registros en tu cuenta"
          :headers="headers_clientes"
          :items="datos3"
          class="text-center pa-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.cliente_id }}</td>
                <td>{{ item.id_interno }}</td>
                <td>{{ item.producto_id }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-row align-center justify-center my-3>
      <v-col cols="12" class="d-flex text-center flex-column justify-center">
        <v-progress-circular
          size="60"
          width="7"
          indeterminate
          style="margin: auto"
          color="primary"
        ></v-progress-circular>
        <p>Cargando Datos</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/api";
import BtnVolver from "@/components/BtnVolver.vue";

export default {
  name: "admin-ver-producto",
  title: "Admin ver producto - Onestock",
  props: ["id"],
  data: () => ({
    producto_info: null,
    user: null,
    cards: [],
    datos2: [],
    dialog: false,
    datos3: [],
    dialog2: false,
    headers: [
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
      },
      {
        text: "Dirección",
        align: "center",
        value: "direccion_comercial",
      },
      {
        text: "Disponibilidad",
        align: "center",
        value: "disponibilidad",
      },
      {
        text: "Stock",
        align: "center",
        value: "pivot.stock",
      },
      {
        text: "Ver",
        align: "center",
        value: "accion",
      },
    ],
    headers_cliente: [
      {
        text: "Id",
        align: "center",
        value: "id",
      },
      {
        text: "Id interno",
        align: "center",
        value: "pivot.id_interno",
      },
      {
        text: "Rut",
        align: "center",
        value: "rut",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
      },
      {
        text: "Ver",
        align: "center",
        value: "",
      },
    ],
    headers_proveedores: [
      {
        text: "Producto ID",
        align: "center",
        value: "producto_id",
      },
      {
        text: "Proveedor ID",
        align: "center",
        value: "proveedor_id",
      },
      {
        text: "Sku",
        align: "center",
        value: "sku",
      },
      {
        text: "Stock",
        align: "center",
        value: "stock",
      },
    ],
    headers_clientes: [
      {
        text: "Cliente ID",
        align: "center",
        value: "cliente_id",
      },
      {
        text: "ID interno",
        align: "center",
        value: "id_interno",
      },
      {
        text: "Producto ID",
        align: "center",
        value: "producto_id",
      },
    ],
  }),
  beforeMount() {
    API.verProductoAdmin(this.$props.id, this.$store.state.token)
      .then((res) => {
        this.$data.producto_info = res.data.respuesta;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    verProveedor(proveedores) {
      this.$data.datos2 = [];
      this.$data.datos2.push(proveedores);
      this.$data.dialog = true;
    },
    verClientes(clientes) {
      this.$data.datos3 = [];
      this.$data.datos3.push(clientes);
      this.$data.dialog2 = true;
    },
  },
  computed: {
    sumaStockProveedores() {
      const proveedores = this.producto_info.proveedores;
      if (Array.isArray(proveedores)) {
        return proveedores.reduce(
          (total, proveedor) => total + proveedor.pivot.stock,
          0
        );
      }
      return 0;
    },
  },
  components: {
    BtnVolver,
  },
};
</script>
<style lang="scss">
.text {
  word-break: break-word;
}
td {
  text-align: left;
}
.centerheight {
  text-align: center !important;
}
</style>
